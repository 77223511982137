<div class="card shadow-sm rounded view-team">
        <div class="card-title bg-blue">
            <h4 class="m-0">Team Challenge Leaderboard 
            </h4>
         
            <ul class="list-unstyled card-title-details mr-xl-5"*ngIf="isTeamList==false">
                <li><span> <a [ngClass]="{'active': showDropdown==false}" (click)="showDropdown=false">All</a></span></li>
                <li><span><a [ngClass]="{'active': showDropdown==true}" (click)="showDropdown=true;getTeamList(this.races[0].RaceResult.raceId)">Sort By Race</a></span>
                </li>
            </ul>
        </div>
    <div class="card-body">
        <div class="table-responsive">
            <span class="pull-right" *ngIf="showDropdown==true">
                    <ul class="list-unstyled card-title-details select-with-no-border" *ngIf="races.length>0">
                            <li><select (change)="getTeamList($event.target.value)"  class="form-control"  placeholder="Enter race name">
                                    <option *ngFor="let race of races" value={{race.RaceResult.raceId}}>
                                        {{race.RaceResult.name}}</option>
                                </select></li>
                          </ul>
                
            </span>
            <table class="table table-dark table-hover" *ngIf="isTeamList==true">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Team</th>
                        <th scope="col">Points</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let team of teams;let i=index">
                        <td scope="row">{{i+1}}</td>
                        <td>{{team.name}}</td>
                        <td>-</td>
                    </tr>

                </tbody>
            </table>
            <table class="table table-dark table-hover" *ngIf="isTeamList==false">
                <thead>
                    <tr>
                        <th scope="col">Rank</th>
                        <th [hidden]="expand==true" scope="col">Team Picture</th>
                        <th scope="col">Team Name</th>
                        <th [hidden]="expand==true"scope="col">Team Member 1 </th>
                        <th [hidden]="expand==true"scope="col">Team Member 2 </th>
                        <th [hidden]="expand==true"scope="col">Team Member 3 </th>
                        <th scope="col">Points</th>
                    </tr>
                </thead>
                <tbody *ngIf="showDropdown==false else sortedList">
                    <tr *ngFor="let team of all;let i=index">
                        <td scope="row">#{{team.rank}}</td>
                        <td [hidden]="expand==true">
                            <div class="user-name-pic">
                               <span class="user-pic" [ngStyle]="{backgroundImage: 'url('+team?.profileImage+')'}">
                               </span>
                             </div>
                     </td>
                        <td>{{team.teamName}}</td>
                        <td [hidden]="expand==true">
                               <div class="user-name-pic">
                                  <span class="user-pic" [ngStyle]="{backgroundImage: 'url('+ team?.users[0]?.profileImage+ ')'}">
                                  </span>
                                  <span class="user-name">{{team?.users[0]?.userName}}</span>
                                </div>
                        </td>
                        <td [hidden]="expand==true">
                                <div class="user-name-pic">
                                   <span class="user-pic" [ngStyle]="{backgroundImage: 'url('+team?.users[1]?.profileImage+')'}">
                                   </span>
                                   <span class="user-name">{{team?.users[1]?.userName}}</span>
                                 </div>
                         </td>
                         <td [hidden]="expand==true">
                                <div class="user-name-pic">
                                   <span class="user-pic" [ngStyle]="{backgroundImage: 'url('+team?.users[2]?.profileImage+')'}">
                                   </span>
                                   <span class="user-name">{{team?.users[2]?.userName}}</span>
                                 </div>
                         </td>
                        <td>{{team.totalPoints}}</td>
                    </tr>

                </tbody>
                <ng-template #sortedList>
                    <tbody>
                        <tr *ngFor="let team of teamResultList;let i=index">
                            <td scope="row">#{{team.rank}}</td>
                            <td [hidden]="expand==true">
                                <div class="user-name-pic">
                                   <span class="user-pic" [ngStyle]="{backgroundImage: 'url('+team?.profileImage+')'}">
                                   </span>
                                 </div>
                         </td>
                            <td>{{team.teamName}}</td>
                            <td [hidden]="expand==true">
                                   <div class="user-name-pic">
                                      <span class="user-pic" [ngStyle]="{backgroundImage: 'url('+ team.users[0]?.profileImage+ ')'}">
                                      </span>
                                      <span class="user-name">{{team.users[0].userName}}</span>
                                    </div>
                            </td>
                            <td [hidden]="expand==true">
                                    <div class="user-name-pic">
                                       <span class="user-pic" [ngStyle]="{backgroundImage: 'url('+team.users[1]?.profileImage+')'}">
                                       </span>
                                       <span class="user-name">{{team.users[1].userName}}</span>
                                     </div>
                             </td>
                             <td [hidden]="expand==true">
                                    <div class="user-name-pic">
                                       <span class="user-pic" [ngStyle]="{backgroundImage: 'url('+team.users[2]?.profileImage+')'}">
                                       </span>
                                       <span class="user-name">{{team.users[2].userName}}</span>
                                     </div>
                             </td>
                            <td>{{team.totalPoints}}</td>
                        </tr>
    
                    </tbody>
                </ng-template>
            </table>
        </div>
        <div class="row align-items-center  text-center" *ngIf="noTeamForAll==true && showDropdown==false">
            <div class="col-lg-12">
                <p>No records found</p>
            </div>
        </div>
        <div class="row align-items-center  text-center" *ngIf="noTeam==true && showDropdown==true">
            <div class="col-lg-12">
                <p>No records found</p>
            </div>
        </div>
        <div class="box-loader" *ngIf="PreLoader">
            <img src="assets/img/progress.svg" alt="Progress">
        </div>
        <div class="box-loader" *ngIf="PreLoader1">
            <img src="assets/img/progress.svg" alt="Progress">
        </div>
    </div>
</div>