import { Component, OnInit, Input, SimpleChanges ,} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { UrlConstants } from 'src/app/constants/url-constants';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-single-user-detail',
  templateUrl: './single-user-detail.component.html',
  styleUrls: ['./single-user-detail.component.scss']
})
export class SingleUserDetailComponent implements OnInit {
  public userDetailUrl = UrlConstants.userDetailUrl;
  public userId;
  public PreLoader;
  public userDetail;
  public userProfileId;
  @Input() individualUserDetail;

  constructor(public route: ActivatedRoute, public genericService: GenericAPIService, public encryptDecryptService: EncryptDecryptService) {
    
  }
  ngOnChanges(changes: SimpleChanges) {
    this.userProfileId = +this.individualUserDetail.userId;
    this.getUserDetail();

}
  ngOnInit() {

    this.route.params.subscribe((routeParam) => {
      this.userId = routeParam['userId'];
    });
    // if (this.individualUserDetail != undefined) {
    //   this.userProfileId = +this.individualUserDetail.userId;
    //   this.getUserDetail();
    // }
  }

  getUserDetail() {
    this.PreLoader = true;
    var raceId = +this.encryptDecryptService.getLocalStorage("previousRaceId");
    this.genericService.getAll(this.userDetailUrl + "?userId=" + this.userProfileId + "&raceId=" + raceId).subscribe(response => {
      if (response.status.success == "Success") {
        this.userDetail = response.data;
        this.PreLoader = false;
      }
      else {
        this.PreLoader = false;
      }

    }, error => {
      this.PreLoader = false;
    })
  }

  

}
