import { Component, OnInit } from '@angular/core';
import { UrlConstants } from 'src/app/constants/url-constants';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { NotificationConstants } from 'src/app/constants/notification-constants';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localStorage.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IMyDpOptions } from 'mydatepicker';
import {NgbTimepickerConfig} from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
  selector: 'app-race-result',
  templateUrl: './race-engine.component.html',
  styleUrls: ['./race-result.component.scss']
})
export class RaceResultComponent implements OnInit {
  public getRaceResultUrl = UrlConstants.getRaceResult;
  public currentYearRaceInfo = UrlConstants.racesUrl;
  public getCircuitListUrl = UrlConstants.getCircuitList;
  public saveRaceUrl = UrlConstants.saveRace;
  public comminSoonMsg = NotificationConstants.comminSoonMsg;
  public saveRaceScheduleUrl = UrlConstants.saveRaceSchedule;
  public updateCircuitUrl = UrlConstants.updateCircuit;
  public addRaceForm: FormGroup;
  public myDatePickerOptions: IMyDpOptions;
  public raceResultList = [];
  public races = [];
  public circuitList = [];
  public message = '';
  public PreLoader: boolean = false;
  public upcomingRaceId: any;
  public entryClosed: boolean = false;
  public endDate;
  public today;
  // public successMessage: any;
  // public failureMessage: any;
  public showAddRaceEngineButton: boolean = false;
  public editCircuitForm: FormGroup;
  PreLoaderList: boolean = false;
 
  constructor(public genericService: GenericAPIService, public encryptDecryptLocalStorageService: EncryptDecryptLocalStorageService, 
    public encryptDecryptService: EncryptDecryptService,
    config: NgbTimepickerConfig) {
    this.today = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
    this.createAddRaceForm();
    this.today.setFullYear(this.today.getFullYear());
    this.myDatePickerOptions = {
      dateFormat: 'yyyy-mm-dd',
      disableSince: { year: this.today.getFullYear(), month: 12, day: 31 },
      disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 },
      showClearDateBtn: false,
      editableDateField: false,
      openSelectorOnInputClick: true
    };
    config.readonlyInputs = true;
  }
  ngOnInit() {
    this.getRacesInfo();
    this.createEditCircuitForm();
  }
  createAddRaceForm() {
    this.addRaceForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      circuitId: new FormControl('', [Validators.required]),
      round: new FormControl('', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]),
      year: new FormControl('', [Validators.required]),
      time: new FormControl('', [Validators.required]),
      isSprintEntry:new FormControl(false),
    })
    this.onChanges();
  }
  onChanges(): void {
    this.addRaceForm.get('time').valueChanges.subscribe(val => {
    });
  }
  createEditCircuitForm() {
    this.editCircuitForm = new FormGroup({
      circuitId: new FormControl('', [Validators.required]),
      raceId: new FormControl('', [Validators.required]),
    })
  }
  //append with position like st,nd,rd,th
  getposition(position) {
    if (position == 1) {
      return position + "st";
    }
    else if (position == 2) {
      return position + "nd";
    }
    else if (position == 3) {
      return position + "rd";
    }
    return position + "th";
  }

  getRaceMode(race) {
    var raceDate = new Date(race.RaceResult.date);
    var nextRaceDate = race.nextRaceDate ? new Date(race.nextRaceDate):race.nextRaceDate;
    //var nextDayOfRace = new Date(raceDate);
    //nextDayOfRace.setDate(nextDayOfRace.getDate() + 1);
    if (this.today >= raceDate && (this.today < nextRaceDate || nextRaceDate==null )) {
      return "update";
    }
    else if (raceDate < this.today && race.hasRaceResult == true) {
      return "updated";
    }
    else {
      return "notUpdated";
    }
  }

  addRaceEngine() {
    this.PreLoaderList = true;
    this.genericService.post(this.saveRaceScheduleUrl, "").subscribe(response => {
      if (response.status.success == "Success") {
        this.getRacesInfo();
        this.PreLoaderList = false;
      } else if (response.status.success == "Failure") {
        this.PreLoaderList = false;
      } else {
        this.PreLoaderList = false;
      }
    }, error => {
      this.PreLoaderList = false;
    })
  }

  getRacesInfo() {
    this.PreLoaderList = true;
    this.genericService.getAll(this.currentYearRaceInfo).subscribe(response => {
      if(response.status.success=="Success"){
      this.races = response.data;
      this.PreLoaderList = false;
      if (this.races.length > 0) {
        this.showAddRaceEngineButton = false;
        for (let race of this.races) {
          race.mode = this.getRaceMode(race);
          race.modeForDriver = this.getDriverMode(race);
        }
      }
      //  else {
      //   this.showAddRaceEngineButton = true;
      // }
    }
    else{
      // this.races=[];
      this.showAddRaceEngineButton = true;
      this.PreLoaderList = false;
    }
  })
    
  }

  getDriverMode(race) {
    var RaceStartDate = new Date(race.RaceStartDate);
    if (RaceStartDate > this.today) {
      return "update";
    }
    else {
      return "updated";
    }
  }

  getCircuitListInfo() {
    this.genericService.getAll(this.getCircuitListUrl).subscribe(response => {
      this.circuitList = response.data;
    })
  }

  showNotification() {
    this.message = this.comminSoonMsg;
    setTimeout(() => {
      this.message = ''
    }, 4000);
  }
  getRaceResult() {
    this.genericService.getAll(this.getRaceResultUrl).subscribe(response => {
      this.raceResultList = response.data;
    })
  }

  saveRace() {
    this.PreLoader = true;
    console.log(this.addRaceForm);
    if (this.addRaceForm.valid) {
      var payload = {
        "year": `${this.addRaceForm.value.year.date.year}`,
        "round": this.addRaceForm.value.round,
        "name": this.addRaceForm.value.name,
        "date": this.addRaceForm.value.year.formatted,
        "time": `${this.addRaceForm.value.time.hour}:${this.addRaceForm.value.time.minute}:00`,
        "isSprintEntry":this.addRaceForm.value.isSprintEntry,
        "circuits": {
          "circuitId": this.addRaceForm.value.circuitId
        }
      }
      this.PreLoader = true;
      this.genericService.post(this.saveRaceUrl, payload).subscribe(response => {
        if (response.status.success == "Success" || response.status.success == "SUCCESS") {
          this.getRacesInfo();
          this.PreLoaderList = true;
          $("#addRace").modal("hide");
          //  this.successMessage = response.status.message;
          this.PreLoader = false
          this.addRaceForm.reset();
        }
        else {
          //   this.failureMessage = response.status.message;
          this.PreLoader = false
        }
        // setTimeout(() => {
        //   this.successMessage = undefined;
        //   this.failureMessage = undefined;
        // }, 2000);
      })
    }
  }

  closeRaceModal() {
    $("#addRace").modal("hide");
    this.addRaceForm.reset();
  }

  editCircuit(raceobj) {
    this.getCircuitListInfo();
    $("#editCircuit").modal("show");
    this.editCircuitForm.controls['circuitId'].setValue(raceobj.circuits.circuitId);
    this.editCircuitForm.controls['raceId'].setValue(raceobj.raceId);
  }

  closeEditCircuitModal() {
    $("#editCircuit").modal("hide");
    this.editCircuitForm.reset();
  }

  saveCircuit() {
    if (this.editCircuitForm.valid) {
      this.PreLoader = true;
      this.genericService.post(this.updateCircuitUrl + "?raceId=" + this.editCircuitForm.value.raceId + "&circuitId=" + this.editCircuitForm.value.circuitId, "").subscribe(response => {
        if (response.status.success == "Success" || response.status.success == "Sucess") {
          //   this.successMessage = response.status.message;
          $("#editCircuit").modal("hide");
          this.editCircuitForm.reset();
          this.getRacesInfo();
          this.PreLoaderList = true;
          this.PreLoader = false;
        } else if (response.status.success == "Fail") {
          // this.failureMessage = response.status.message;
          this.PreLoader = false;
        } else {
          //  this.failureMessage = response.status.message;
          this.PreLoader = false
        }
        // setTimeout(() => {
        //   this.successMessage = undefined;
        //   this.failureMessage = undefined;
        // }, 2000);

      }, error => {
        this.PreLoader = false;
      })
    }
  }
  addingSprintEntery(race:any){
    this.encryptDecryptService.setToLocalStorage("isSprintEntry", race.RaceResult.isSprintEntry);
  }

}
