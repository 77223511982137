
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class NotificationConstants {
  static errormsg='SomeThing Went wrong Please Try Again';
  static forgetPasswordSuccess='Please check  your mail to reset your password';
  static forgetPasswordFailure='Mail Id does not exist ';
  static resetPasswordSuccess='Password changed successfully, Please login with new password';
  static updateUser='User Updated Successfully';
  static wrongOldPasswordMsg='New password should not be the same as the old password';
  //admin
  static raceResultUpdatedSuccess='Race Result updated Successfully';
  static raceResultSaveSuccess='Race Result updated Successfully';
  static saveUser='User created successfully';
  static comminSoonMsg='Coming soon...........Stay tuned for updates';
  static saveTeamMsg='Team created successfully';
  static updateTeamMsg='Team updated successfully';

  //race entry
  static noDriverMsg='Drives not yet updated for this race, please contact support team';
  static noMostplaceGainedDriverMsg='Drives for most places gained not yet updated for this race, please contact support team';
  static noConstructorMsg='Constructor not yet updated for this race, please contact support team';
  static noPolePostionDriverMsg='Drives for pole postion not yet updated for this race, please contact support team';
  static noFastestLapDriverMsg='Drives for fastest lap not yet updated for this race, please contact support team';
  static duplicateMembers="Duplicating members in a team is not allowed please select unique members.";
  static mandotoryMsg="Please fill all mandatory details ";
  static wrongOldPswdMsg='Old password is wrong';
  static correctFileTypeMsg='Please upload only jpeg or png or jpeg file format';
  static emailExists='Email already exists';
  static userExists='User name already exists';
  
  static sessionExpiredNotification='Your session has expired,Please login again';
  static sessionInvalidNotification='Your session is expired,Please login again';
 
  static getLinkPicture(url) {
    var timeStamp = (new Date()).getTime();
    return url + '?' + timeStamp;
  } 
}

