<!-- Alert Messages -->
<div *ngIf="successMessage!=undefined||successMessage!=null"
  class="alert alert-icon alert-success fade show slideInRight animated faster" role="alert">
  <div class="gif-icon">
    <img src="../../../../assets/img/success.gif" alt="success-icon" />
  </div>
  {{successMessage}}
</div>
<div *ngIf="failureMessage!=undefined||failureMessage!=null"
  class="alert alert-icon alert-danger fade show slideInRight animated faster" role="alert">
  <div class="gif-icon">
    <img src="../../../../assets/img/failure.gif" alt="success-icon" />
  </div>
  {{failureMessage}}
</div>

<div class="card shadow-sm rounded view-player">
  <div class="card-title bg-blue">
    <h4 class="m-0">Player Leaderboard </h4>
    <ul class="list-unstyled card-title-details mr-xl-5"  *ngIf="!isUserList">
      <li><span><a [ngClass]="{'active': isAll==true}" (click)="AllList()"> All</a></span></li>
      <li><span><a [ngClass]="{'active': isSortByLastRace==true}" (click)="LastRaceList()"> Last Race</a></span></li>
    </ul>
  </div>

  <div class="box-loader" *ngIf="PreLoader">
    <img src="assets/img/progress.svg" alt="Progress">
  </div>
  <div class="card-body">
    <div class="table-responsive playerladder">
      <table class="table table-dark table-hover" *ngIf="isUserList==true">
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Name</th>
            <th scope="col">Team</th>
            <th scope="col">Points</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users;let i=index">
            <td scope="row">{{i+1}}</td>
            <td>
              <div class="user-name-pic">
                <span class="user-pic" [ngStyle]="{backgroundImage: 'url(' +user.profileImage+ ')'}">
                </span>
                <span class="user-name">{{user.userName!=undefined?user.userName:'-'}}</span>
              </div>
            </td>
            <td>{{user.team!=undefined?user.team.name:'-'}}</td>
            <td>-</td>
          </tr>
        </tbody>
      </table>
      <table class="table table-dark table-hover" *ngIf="isUserList==false">
        <thead>
          <tr>
            <th *ngIf="!isSortByLastRace && isAll"  scope="col">Rank</th>
            <th *ngIf="isSortByLastRace && !isAll" scope="col">S.No</th>
            <th scope="col">Name</th>
            <th [hidden]="expand==false || isSortByLastRace && !isAll" scope="col">Challenge</th>
            <th [hidden]="expand==false" scope="col">Team Picture</th>
            <th [hidden]="expand==false" scope="col">Team Name</th>
            <th scope="col" [hidden]="expand==true">Team</th>
            <th  scope="col">Points</th>
          </tr>
        </thead>
        <!-- routerLink='/user/userDetails/individualUserDetail/{{user.userId}}' -->
        <tbody>
          <tr *ngFor="let user of userResultList;let i=index" [class.active]="user.userId == userId">
            <td *ngIf="!isSortByLastRace && isAll" scope="row">#{{user.rank}}</td>
            <td  *ngIf="isSortByLastRace && !isAll" scope="row">{{i+1}}</td>
            <td class="cursor-pointer" (click)="showIndividualProfile(user.userId)">
              <div class="user-name-pic">
                <span class="user-pic" [ngStyle]="{backgroundImage: 'url(' + user.userImage + ')'}">
                </span>
                <!-- <span class="user-pic">
                  <img src={{user.userImage}} alt="user" />
              </span> -->
                <span class="user-name">{{user.userName}}</span>
              </div>
            </td>
            <td [hidden]="expand==false || isSortByLastRace && !isAll"  *ngIf='user.userId!=revokeUserId'>
              <a class="btn btn-primary btn-challenge" [hidden]="user.userId==userId"
                [ngClass]="{'disabled': user.isDisabled}" (click)="challengePerson(user)">Challenge</a>
             </td>
            <td [hidden]="expand==false || isSortByLastRace && !isAll" *ngIf='user.userId==revokeUserId'><a
                class="btn btn-primary btn-challenge" (click)="openRevokeChallengeModal(user)">Revoke</a></td>
            <td [hidden]="expand==false" class="user-name-pic"><span class="user-pic"
                [ngStyle]="{backgroundImage: 'url(' +user.teamImage+ ')'}">
              </span></td>
            <td [hidden]="expand==false">
               <span class="user-name">{{user.teamName}}</span>
            </td>
            <td [hidden]="expand==true">{{user.teamName}}</td>
            <td *ngIf="!isSortByLastRace && isAll"> {{user.totalPoints}}</td>
            <td *ngIf="isSortByLastRace && !isAll">{{user.points}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row align-items-center  text-center" *ngIf="noPlayer==true  ">
      <div class="col-lg-12">
        <p>No records found</p>
      </div>
    </div>
  </div>
  <!-- <a *ngIf="router.url == '/user/dashboard'" class="more btn btn-outline-dark" [routerLink]="'/user/playerBoard'"><i
            class="fa fa-expand" aria-hidden="true"></i></a>
    <a *ngIf="router.url == '/user/playerBoard'" class="more btn btn-outline-dark" [routerLink]="'/user/dashboard'"><i
            class="fa fa-compress" aria-hidden="true"></i></a> -->
</div>




<!-- Modal -->
<div class="modal fade add-user" id="exampleModalCenter" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" data-backdrop="false" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="challenge-icon text-center">
          <img src="assets/img/challenge.gif" alt="challenge-icon" />
        </div>
        <div class="message text-center">
          Are you sure you want to challenge {{this.selectedUser.userName}} for {{this.raceName}} ?
        </div>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" id="closeId" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="saveChallenge()">Challenge</button>
      </div>
      <div class="box-loader" *ngIf="PreLoaderForSave">
        <img src="assets/img/progress.svg" alt="Progress">
      </div>
    </div>
  </div>
</div>

<div class="modal fade add-user" id="addRaceEntryModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" data-backdrop="false" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="message text-center">
          Please Add the Race Entry to give challenge
        </div>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="modal-footer"> 
        <button type="button" class="btn btn-primary" id="closeId" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade add-user delete-user" id="revokeChallengeModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" data-backdrop="false" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="challenge-icon text-center">
          <img src="assets/img/challenge.gif" alt="challenge-icon" />
        </div>
        <div class="message text-center">
          Are you sure you want to revoke challenge from {{this.selectedUser.userName}} for {{this.raceName}} ?
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" id="closeId" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="revokeChallenge();">Revoke Challenge</button>
      </div>
      <div class="box-loader" *ngIf="PreLoaderForRevoke">
        <img src="assets/img/progress.svg" alt="Progress">
      </div>
    </div>
  </div>
</div>