import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RaceResultComponent } from './race-result/race-result.component';
import { UpdateRaceEntryResultComponent } from './update-race-entry-result/update-race-entry-result.component';

const routes: Routes = [
  {
    path:'raceResult',
    component:RaceResultComponent
  },
  {
    path:'update/raceResult',
    component:UpdateRaceEntryResultComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RaceResultRoutingModule { }
