import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserModule } from './user-module/user-module.module';
import { ForgetPasswordComponent } from './common/forget-password/forget-password.component';
import { SignInComponent } from './common/sign-in/sign-in.component';
import { SigninWithTwitterComponent } from './common/signin-with-twitter/signin-with-twitter.component';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { UserHomeComponent } from './user-module/user-home.component';
import { AuthGuard } from './services/auth-guard.service';
import { CanDeactivateGuard } from './services/guard-deactivate';
import { SignInAuthGuard } from './services/signin-auth-guard.service';
import { AdminHomeComponent } from './admin-module/admin-home.component';
import { AdminModule } from './admin-module/admin.module';
import { AdminAuthGuard } from './services/admin-auth-guard.service';
const routes: Routes = [
  { path: 'forgetPassword', component: ForgetPasswordComponent },
  { path: 'signIn', component: SignInComponent,
   canActivate: [SignInAuthGuard]
 },
  { path: 'signInWithTwitter', component: SigninWithTwitterComponent },
  { path: 'resetPassword', component: ResetPasswordComponent },
  {
    path: 'user',
    component: UserHomeComponent,
    canActivate: [AuthGuard],
    loadChildren: './user-module/user-module.module#UserModule'
  },
  {
    path: 'admin',
    component: AdminHomeComponent,
    canActivate: [AdminAuthGuard],
    loadChildren: './admin-module/admin.module#AdminModule'
  },
  {
    path: '',
    redirectTo: '/signIn',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: 'signIn' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
