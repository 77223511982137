<div class="row h-100 mx-0 playerladder actual-page-view">
    <!-- Ladder Matches -->
    <div class="col-xl-7 col-lg-12">
      <div class="card shadow-sm rounded ladder-matches">
        <div class="card-title bg-blue">
          <h4 class="m-0">Ladder Matches</h4>
          <ul class="list-unstyled card-title-details">
            <li> No of Challenges Left :<span class="text-green">{{noRace?'-':noOfChallengesLeft}}</span> </li>
          </ul>
          
        </div>
        <div class="card-body" *ngIf="!noRace else noraceAvailable">
          <div class="notify" *ngIf="preChallengDetails.isChallengedForThisrace == true">
            You are already challenged for this race
          </div>
          <div class="table-responsive">
            <table class="table table-dark table-hover">
              <thead>
                <tr>
                  <th *ngIf="isFirstRace==true" scope="col">S.N0</th>
                  <th *ngIf="isFirstRace==false" scope="col">Rank</th>
                  <th scope="col">Name</th>
                  <th scope="col">Team</th>
                  <th scope="col">Points</th>
                  <th scope="col">Challenges</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr>
                <td scope="row">#1</td>
                <td class="user-name-pic"><span class="user-pic"><img src="../../../../assets/img/1.jpg" class="img-fluid" /></span>Jacob</td>
                <td>Thornton</td>
                <td>486</td>
                <td><a class="btn btn-primary btn-challenge" data-toggle="modal" data-target="#exampleModalCenter">Challenge</a></td>
              </tr>
              <tr>
                <td scope="row">#2</td>
                <td class="user-name-pic"><span class="user-pic"><img src="../../../../assets/img/1.jpg" class="img-fluid" /></span>Jacob</td>
                <td>Thornton</td>
                <td>486</td>
                <td><a class="btn btn-secondary btn-challenge disabled">Challenge</a></td>
              </tr> -->
  
                <tr *ngFor="let user of users;let i=index" [class.active]="user.userId == userId">
                  <td *ngIf="isFirstRace==true">#{{i+1}}</td>
                  <td *ngIf="isFirstRace==false" scope="row">#{{user.rank}}</td>
                  <td class="cursor-pointer" (click)="showIndividualProfile(user.userId)">
                    <div class="user-name-pic">
                      <span class="user-pic" [ngStyle]="{backgroundImage: 'url(' + user.userImage + ')'}">
                      </span>
                      <span class="user-name">{{user.userName}}</span>
                    </div>
                  </td>
                  <td>{{user.teamName}}</td>
                  <td>{{user.totalPoints}}</td>
                  <td *ngIf="user.userId!=revokeUserId"><a class="btn btn-primary btn-challenge"
                      [hidden]="user.userId==userId" [ngClass]="{'disabled': user.isDisabled}"
                      (click)="challengePerson(user)">Challenge</a></td>
                  <td *ngIf="user.userId==revokeUserId"><a class="btn btn-primary btn-challenge"
                      (click)="openRevokeChallengeModal(user)">Revoke</a></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-12 p-2 ladder-info" *ngIf="upcomingRaceRound==1">
              <div class="row ladder align-items-center justify-content-center">
                  <div class="col-lg-12">
                      <p>It is understood that you are eagerly anticipating to challenge. Hold your peace until the second race starts for this season.</p>
                  </div>
                </div>
          </div>
 
          
        </div>
        <div class="box-loader" *ngIf="PreLoader">
          <img src="assets/img/progress.svg" alt="Progress">
        </div>
      </div>
    </div>
    <div class="col-xl-5 col-lg-12 h-100">
      <!-- Challenged By -->
      <div class="row h-50 pb-1 challenged-by">
        <div class="col-xl-12 h-100">
          <div class="card shadow-sm rounded total-points">
            <h4 class="card-title">Total Points Lost / Gained : <span class="text-green">{{noRace?'-':sumOfChallenges}}</span></h4>
          </div>
          <div class="card shadow-sm rounded">
            <div class="card-title bg-blue">
              <h4 class="m-0">Ladder History</h4>
              <ul class="list-unstyled card-title-details" *ngIf="!noRace" >
                <li><span><a [ngClass]="{'active': challenged==true &&challengedBy==false}"
                      (click)="challenged=true;challengedBy=false">Challenged</a></span></li>
                <li><span><a [ngClass]="{'active':  challenged==false && challengedBy==true}"
                      (click)="challenged=false;challengedBy=true"> Challenged By</a></span></li>
              </ul>
            </div>
            <div class="card-body" *ngIf="challenged && !noRace else nochallengeAvailable">
              <!-- <div class="notify">You are left with {{10-this.preChallengDetails.noOfChallengesForDoneInThisSeason}}
              challenges for this season</div> -->
              <div class="table-responsive">
                <table class="table table-dark table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Challenged</th>
                      <th scope="col">Race</th>
                      <th scope="col">Result</th>
                      <th scope="col">Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let challenge of challenges;let i=index">
                      <td class="cursor-pointer" (click)="showIndividualProfile(challenge.challengedUserId)">
                        <div class="user-name-pic">
                          <span class="user-pic">
                            <img src={{challenge.challengedUserProfile}} alt="user" />
                          </span>
                          <span class="user-name">{{challenge.challengedUserName}}</span>
                        </div>
                      </td>
                      <td>{{challenge.raceName}}</td>
                      <td>{{challenge.result==null?'Result awaiting':challenge.result}}</td>
                      <td>{{challenge.points==null?'Result awaiting':challenge.points}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row" *ngIf="showMessage==true">
                <div class="col-lg-12 text-center">
                  <p>No challenges found</p>
                </div>
              </div>
              <div class="box-loader" *ngIf="PreLoader1">
                <img src="assets/img/progress.svg" alt="Progress">
              </div>
            </div>
            <div class="card-body" *ngIf="challengedBy  && !noRace">
              <!-- <div class="notify">You are left with {{10-this.preChallengDetails.noOfChallengesForDoneInThisSeason}}
            challenges for this season</div> -->
              <div class="table-responsive">
                <table class="table table-dark table-hover">
                  <thead>
                    <tr>
                      <th scope="col">User name</th>
                      <th scope="col">Race</th>
                      <th scope="col">Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let challenge of challengesForUserList;let i=index">
                      <td class="cursor-pointer" (click)="showIndividualProfile(challenge.challengedUserId)">
                        <div class="user-name-pic">
                          <span class="user-pic">
                            <img src={{challenge.challengedUserProfile}} alt="user" />
                          </span>
                          <span class="user-name">{{challenge.challengedUserName}}</span>
                        </div>
                      </td>
                      <td>{{challenge.raceName}}</td>
                      <td>{{challenge.result==null?'Result awaiting':challenge.result}}</td>
                      <!--    <td>{{challenge.points==null?'Result awaiting':challenge.points}}</td> -->
                    </tr>
  
                    <!-- <tr>
                <td class="user-name-pic"><span class="user-pic"><img src="../../../../assets/img/1.jpg" class="img-fluid" /></span>Jacob</td>
                <td>Thornton</td>
                <td>Lost</td>
                <td>486</td>
              </tr> -->
                  </tbody>
                </table>
              </div>
              <div class="row" *ngIf="showMessage1==true">
                <div class="col-lg-12 text-center">
                  <p>No challenges found</p>
                </div>
              </div>
              <div class="box-loader" *ngIf="PreLoader3">
                <img src="assets/img/progress.svg" alt="Progress">
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- ladder History -->
      <div class="row h-50 ladder-history pt-5">
        <div class="col-xl-12 h-100">
          <div class="card shadow-sm rounded">
            <div class="card-title bg-rose">
              <h4 class="m-0">All Challenges</h4>

            <ul class="list-unstyled card-title-details mr-xl-5">
              <li><span> <a [ngClass]="{'active': sortByRace==false}"(click)="sortByRace=false;challengeForRace=[]" >All</a></span></li>
              <li><span><a [ngClass]="{'active': sortByRace==true}"(click)="sortByRace=true;">Sort By Race</a></span>
              </li>
          </ul>
            </div>

            <div class="card-body"*ngIf="sortByRace==false">
                <div class="table-responsive">
                  <table class="table table-dark table-hover">
                    <thead>
                      <tr>
                        <th scope="col">User Name</th>
                        <th scope="col">Challenged</th>
                        <th scope="col">Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let challenge of previousRaceChallenge;let i=index">
                        <td class="cursor-pointer">
                          <div class="user-name-pic">
                            <span class="user-pic">
                              <img src={{challenge.userProfile}} alt="user" />
                            </span>
                            <span class="user-name">{{challenge.userName}}</span>
                          </div>
                        </td>
                        <td class="cursor-pointer">
                          <div class="user-name-pic">
                            <span class="user-pic">
                              <img src={{challenge.challengedUserProfile}} alt="user" />
                            </span>
                            <span class="user-name">{{challenge.challengedUserName}}</span>
                          </div>
                        </td>
    
                        <td>{{challenge.result==null?'Result awaiting':challenge.result}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" *ngIf="previousRaceChallenge.length==0">
                  <div class="col-lg-12 text-center">
                    <p>No challenges found</p>
                  </div>
                </div>
                <div class="box-loader" *ngIf="PreLoader2">
                  <img src="assets/img/progress.svg" alt="Progress">
                </div>
              </div>
              <div class="card-body"*ngIf="sortByRace==true">
                  <div class="table-responsive">
                  <span class="pull-right" >
                      <ul class="list-unstyled card-title-details select-with-no-border" *ngIf="races.length>0">
                          <li><select (change)="filterForRace($event.target.value)"class="form-control" placeholder="Enter race name">
                            <option *ngFor="let race of races" value={{race.RaceResult.raceId}}>
                              {{race.RaceResult.name}}</option>
                          </select></li>
                        </ul> 
              </span>
                <table class="table table-dark table-hover">
                  <thead>
                    <tr>
                      <th scope="col">User Name</th>
                      <th scope="col">Challenged</th>
                      <th scope="col">Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let challenge of challengeForRace;let i=index">
                      <td class="cursor-pointer">
                        <div class="user-name-pic">
                          <span class="user-pic">
                            <img src={{challenge.userProfile}} alt="user" />
                          </span>
                          <span class="user-name">{{challenge.userName}}</span>
                        </div>
                      </td>
                      <td class="cursor-pointer">
                        <div class="user-name-pic">
                          <span class="user-pic">
                            <img src={{challenge.challengedUserProfile}} alt="user" />
                          </span>
                          <span class="user-name">{{challenge.challengedUserName}}</span>
                        </div>
                      </td>
  
                      <td>{{challenge.result==null?'Result awaiting':challenge.result}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row" *ngIf="challengeForRace.length==0">
                <div class="col-lg-12 text-center">
                  <p>No challenges found</p>
                </div>
              </div>
              <div class="box-loader" *ngIf="PreLoader2">
                <img src="assets/img/progress.svg" alt="Progress">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noraceAvailable>
<div class="col-lg-12 p-2 ladder-info" *ngIf="noRace">
    <div class="row ladder align-items-center justify-content-center">
        <div class="col-lg-12">
          <p>Come back after you submit your first race entry for this season...
            </p>
        </div>
      </div>
  </div>

    </ng-template>
    <ng-template #nochallengeAvailable>
        <div class="col-lg-12 p-2 ladder-info" *ngIf="noRace">
            <div class="row ladder align-items-center justify-content-center">
                <div class="col-lg-12">
                  <p>Looking for a challenge! Wait for the first race of this season
                    </p>
                </div>
              </div>
          </div>
    </ng-template>
  </div>
  
  <!-- Modal -->
  <div class="modal fade add-user delete-user" id="exampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" data-backdrop="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="challenge-icon text-center">
            <img src="assets/img/challenge.gif" alt="challenge-icon" />
          </div>
          <div class="message text-center">
            Are you sure you want to challenge {{this.selectedUser.userName}} for {{this.raceName}} ?
          </div>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary" id="closeId" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="saveChallenge()">Challenge</button>
        </div>
        <div class="box-loader" *ngIf="PreLoaderForSave">
          <img src="assets/img/progress.svg" alt="Progress">
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal -->
  <div class="modal fade add-user delete-user" id="revokeChallengeModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" data-backdrop="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="challenge-icon text-center">
            <img src="assets/img/challenge.gif" alt="challenge-icon" />
          </div>
          <div class="message text-center">
            Are you sure you want to revoke challenge from {{this.selectedUser.userName}} for {{this.raceName}} ?
          </div>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary" id="closeId" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="revokeChallenge()">Revoke Challenge</button>
        </div>
        <div class="box-loader" *ngIf="PreLoaderForSave">
          <img src="assets/img/progress.svg" alt="Progress">
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal fade add-user delete-user" id="addRaceEntryModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" data-backdrop="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="message text-center">
            Please Add the Race Entry to give challenge
          </div>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" id="closeId" data-dismiss="modal">Ok</button>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Single User Detail view -->
  <div class="row h-100 m-0 single-user-detail-view" *ngIf="userProfileId!=undefined && userProfileId!=0">
    <div class="col-lg-12 h-100">
      <div class="card h-auto mb-4 r down-arw" (click)="closeSingleUserDetailView()">
        <div class="card-body p-3 text-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="34.848" height="18.292" viewBox="0 0 34.848 18.292">
            <path class="a" d="M7028.1,184.362,7044.15,199.5l16.76-15.138" transform="translate(-7027.068 -183.249)" />
          </svg>
        </div>
      </div>
      <app-single-user-detail [individualUserDetail]="{'userId':userProfileId,'isindividual':true}">
      </app-single-user-detail>
    </div>
  </div>