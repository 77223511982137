import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeamManagementListComponent } from './team-management-list/team-management-list.component';

const routes: Routes = [
  {
    path:'teamManagement',
    component:TeamManagementListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeamManagementRoutingModule { }
