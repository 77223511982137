import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-race-entry-result',
  templateUrl: './update-race-entry-result.component.html',
  styleUrls: ['./update-race-entry-result.component.scss']
})
export class UpdateRaceEntryResultComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
