import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing';
import { LadderBoardModule } from './ladder-board/ladder-board.module';
import { TeamManagementModule } from './team-management/team-management.module';
import { RulesEngineModule } from './rules-engine/rules-engine.module';
import { RaceResultModule } from './race-result/race-result.module';
import { AdminProfileModule } from './admin-profile/admin-profile.module';
import { RaceConstructorModule } from './race-constructor/race-constructor.module';
import { MasterDataModule } from './master-data/master-data.module';
import { RaceDriverModule } from './race-driver/race-driver.module';
import { SubscriptionDetailModule } from './subscription-detail/subscription-detail.module';

@NgModule({
  declarations: [],
  imports : [
    CommonModule,
    AdminRoutingModule,
    TeamManagementModule,
    RulesEngineModule,
    RaceResultModule,
    LadderBoardModule,
    AdminProfileModule,
    RaceConstructorModule,
    RaceDriverModule,
    MasterDataModule,
    SubscriptionDetailModule
  ]
})
export class AdminModule { }
