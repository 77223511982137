
<div class="row h-100 m-0">
    <div class="col-lg-12 h-100">
        <div class="card shadow-sm rounded view-player">
            <div class="card-title bg-blue">
                <h4>Race Engine</h4>
                <ul class="list-unstyled card-title-details">
                    <li><a href="javascript:;" class="btn btn-outline-primary" data-toggle="modal"
                            (click)="getCircuitListInfo()" data-target="#addRace">Add Race</a></li>
                </ul>
            </div>
            <div class="card-body rules-engine">
                <div class="table-responsive">
                    <table class="table table-dark table-hover">
                        <thead>
                            <tr>
                                <th class="w-15">Race</th>
                                <th class="w-30">Circuit</th>
                                <th class="w-10">Sprint Entry</th>
                                <th class="w-15">Drivers In Race</th>
                                <th class="w-15">Constructors In Race</th>
                                <th class="w-15">Race Result</th>
                                <!-- <th class="action text-center">Action</th> -->
                            </tr>
                        </thead>
                        <tbody *ngIf="races.length>0">
                            <tr *ngFor="let race of races">
                                <td>{{race.RaceResult.name}}</td>
                                <td>{{race.RaceResult.circuits.name}}
                                    <a href="javascript:;" *ngIf="race.modeForDriver=='update'" (click)="editCircuit(race.RaceResult)"
                                        class="btn btn-outline-secondary"><i class="fa fa-edit"></i></a>
                                </td>
                                <td>{{race.RaceResult.isSprintEntry ? 'Yes':'No'}}</td>
                                <!-- <td>
                                    Update <a href="javascript:;" class="btn btn-outline-secondary" (click)="showNotification()"><i class="fa fa-edit"></i></a>
                                </td> -->
                                <td *ngIf="race.modeForDriver=='update';else defaultDriver"
                                    [routerLink]="'/admin/raceDriver'"
                                    [queryParams]="{ raceId: race.RaceResult.raceId,mode:'edit'}">
                                    Update <a href="javascript:;" class="btn btn-outline-secondary"><i
                                            class="fa fa-edit"></i></a>
                                </td>
                                <td *ngIf="race.modeForDriver=='update';else defaultConstructor"
                                    [routerLink]="'/admin/raceConstructor'"
                                    [queryParams]="{ raceId: race.RaceResult.raceId,mode:'edit'}">
                                    Update <a href="javascript:;" class="btn btn-outline-secondary"><i
                                            class="fa fa-edit"></i></a>
                                </td>
                                <td *ngIf="race.mode=='update';else default" [routerLink]="'/admin/update/raceResult'"
                                    [queryParams]="{ raceId: race.RaceResult.raceId, raceResultId: race.raceResultId,mode:'edit'}">
                                    Update <a href="javascript:;" (click)="addingSprintEntery(race)" class="btn btn-outline-secondary"><i
                                            class="fa fa-edit"></i></a>
                                </td>
                                <!-- //For defaut Race Result -->
                                <ng-template #default>
                                    <td *ngIf="race.mode=='notUpdated'">
                                        Not Updated <a href="javascript:;" class="btn btn-outline-secondary">
                                            <!-- <i class="fa fa-edit"></i> -->
                                        </a>
                                    </td>
                                    <td *ngIf="race.mode=='updated'" [routerLink]="'/admin/update/raceResult'"
                                        [queryParams]="{ raceId: race.RaceResult.raceId, raceResultId: race.raceResultId,mode:'view'}">
                                        Updated <a href="javascript:;" class="btn btn-outline-secondary"><i
                                                class="fa fa-eye"></i></a>
                                    </td>
                                </ng-template>
                                <!-- //For defaut defaultConstructor -->
                                <ng-template #defaultConstructor>
                                    <!-- <td *ngIf="race.modeForDriver=='notUpdated'">
                                        Not Updated <a href="javascript:;" class="btn btn-outline-secondary"><i class="fa fa-edit"></i></a>
                                    </td> -->
                                    <td *ngIf="race.modeForDriver=='updated'" [routerLink]="'/admin/raceConstructor'"
                                        [queryParams]="{ raceId: race.RaceResult.raceId, mode:'view'}">
                                        Updated <a href="javascript:;" class="btn btn-outline-secondary"><i
                                                class="fa fa-eye"></i></a>
                                    </td>
                                </ng-template>
                                <ng-template #defaultDriver>
                                    <!-- <td *ngIf="race.modeForDriver=='notUpdated'">
                                      Not Updated <a href="javascript:;" class="btn btn-outline-secondary"><i class="fa fa-edit"></i></a>
                                  </td> -->
                                    <td *ngIf="race.modeForDriver=='updated'" [routerLink]="'/admin/raceDriver'"
                                        [queryParams]="{ raceId: race.RaceResult.raceId, mode:'view'}">
                                        Updated <a href="javascript:;" class="btn btn-outline-secondary"><i
                                                class="fa fa-eye"></i></a>
                                    </td>
                                </ng-template>
                                <!-- <td class="action text-center">
                                    <div><a href="javascript:;" class="btn btn-outline-secondary"
                                            (click)="showNotification()"><i class="fa fa-trash-o"></i></a></div>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                   
                </div>
                <div class="row text-center">
                    <div class="list-unstyled card-title-details col-lg-12 mt-40" id="pills-tabContent"
                        *ngIf="showAddRaceEngineButton==true">
                        <div>Races have not been added for this season.</div>
                        <div class="mb-3"> Would you like to add races now?</div>
                        <a href="javascript:;" class="btn btn-outline-primary addRaceEngine" (click)="addRaceEngine()" >Add Races</a>
                    </div>
                </div>

            </div>
            <div class="box-loader" *ngIf="PreLoaderList">
                <img src="assets/img/progress.svg" alt="Progress">
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade add-user delete-user" id="addRace" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    data-backdrop="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add Race </h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <form [formGroup]="addRaceForm">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Race Name <span
                                        class="star-icon">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" id="name" formControlName="name"
                                        placeholder="Enter race name" required>
                                    <small
                                        *ngIf="addRaceForm.controls['name'].errors && !addRaceForm.controls['name'].pristine"
                                        class="form-text text-muted">Race Name is required</small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="circuitId" class="col-sm-4 col-form-label">Circuit Name<span
                                        class="star-icon">*</span></label>
                                <div class="col-sm-8">
                                    <select class="form-control" id="circuitId" formControlName="circuitId"
                                        placeholder="Enter race name">
                                        <option *ngFor="let circuit of circuitList" [ngValue]="circuit.circuitId">
                                            {{circuit.name}}</option>
                                    </select>
                                    <small
                                        *ngIf="addRaceForm.controls['circuitId'].errors && !addRaceForm.controls['circuitId'].pristine"
                                        class="form-text text-muted">Circuit Name is required</small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="round" class="col-sm-4 col-form-label">Round<span
                                        class="star-icon">*</span></label>
                                <div class="col-sm-8">
                                    <input type="number" class="form-control" id="round" formControlName="round"
                                        placeholder="Enter round" required>
                                    <small style="color:red"
                                        *ngIf="addRaceForm.controls['round'].errors?.pattern;else requiredRound">
                                        Enter valid round without spaces and special characters
                                    </small>
                                    <ng-template #requiredRound>
                                        <small
                                            *ngIf="addRaceForm.controls['round'].errors && !addRaceForm.controls['round'].pristine"
                                            class="form-text text-muted">Round is required</small>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="year" class="col-sm-4 col-form-label">Race Date<span
                                        class="star-icon">*</span></label>
                                <div class="col-sm-8">
                                    <my-date-picker name="mydate" id="year" [options]="myDatePickerOptions"
                                        formControlName="year" required>
                                    </my-date-picker>
                                    <small
                                        *ngIf="addRaceForm.controls['year'].errors && !addRaceForm.controls['year'].pristine"
                                        class="form-text text-muted">Race Date is required</small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="time" class="col-sm-4 col-form-label">Time<span
                                        class="star-icon">*</span></label>
                                <div class="col-sm-8 time-picker">
                                    <ngb-timepicker id="time" formControlName="time" ></ngb-timepicker>
                                    <small
                                        *ngIf="addRaceForm.controls['time'].errors && !addRaceForm.controls['time'].pristine"
                                        class="form-text text-muted">Time is required</small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="activeFrom" class="col-sm-4 col-form-label">Is Sprint Entry</label>
                                <div class="col-sm-8">
                                    <div class="custom-control custom-checkbox pt-1">
                                        <input type="checkbox" class="custom-control-input" formControlName="isSprintEntry" id="isSprintEntry">
                                        <label class="custom-control-label" for="isSprintEntry"></label>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary" id="closeId" data-dismiss="modal"
                    (click)="closeRaceModal()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="addRaceForm.invalid == true"
                    (click)="saveRace()">Save</button>
            </div>
            <div class="box-loader" *ngIf="PreLoader">
                <img src="assets/img/progress.svg" alt="Progress">
              </div>
        </div>
    </div>
</div>

<div class="modal fade add-user" id="editCircuit" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    data-backdrop="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Circuit </h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-8">
                        <form [formGroup]="editCircuitForm">
                            <div class="form-group row">
                                <label for="circuitId" class="col-sm-4 col-form-label">Circuit Name<span
                                        class="star-icon">*</span></label>
                                <div class="col-sm-8">
                                    <select class="form-control" id="circuitId" formControlName="circuitId"
                                        placeholder="Enter race name">
                                        <option *ngFor="let circuit of circuitList" [ngValue]="circuit.circuitId">
                                            {{circuit.name}}</option>
                                    </select>
                                    <small
                                        *ngIf="editCircuitForm.controls['circuitId'].errors && !editCircuitForm.controls['circuitId'].pristine"
                                        class="form-text text-muted">Circuit Name is required</small>
                                </div>
                            </div>
                            <div>
                                <input type="number" hidden placeholder="raceId" formControlName="raceId" name="raceId"
                                    readonly>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary" id="closeId" data-dismiss="modal"
                    (click)="closeEditCircuitModal()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="editCircuitForm.invalid == true"
                    (click)="saveCircuit()">Save</button>
            </div>
            <div class="box-loader" *ngIf="PreLoader">
                <img src="assets/img/progress.svg" alt="Progress">
              </div>
        </div>
    </div>
</div>