<!-- Alert Messages -->
<div *ngIf="errorMsgForProfile!=undefined||errorMsgForProfile!=null"
  [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"
  class="alert alert-icon alert-danger fade show animated faster" role="alert">
  <div class="gif-icon">
    <img src={{failureGif}} alt="success-icon" />
  </div>
  {{errorMsgForProfile}}
</div>
<div *ngIf="errorMsgForTeam!=undefined||errorMsgForTeam!=null"
  [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"
  class="alert alert-icon alert-danger fade show animated faster" role="alert">
  <div class="gif-icon">
    <img src={{failureGif}} alt="success-icon" />
  </div>
  {{errorMsgForTeam}}
</div>
<!-- User Profile -->
<div class="row h-100 m-0">
  <div class="col-lg-12">
    <div class="card shadow-sm rounded user-profile">
      <div class="card-title bg-blue">
        <h4 class="mb-2 m-sm-0">User Profile</h4>
        <ul *ngIf="editProfile==false" class="list-unstyled card-title-details">
          <li><a href="javascript:;" class="btn btn-outline-primary" (click)="editProfile=true">Edit</a></li>
          <li *ngIf="isPrincipal==true" class="ml-3"><a href="javascript:;" class="btn btn-outline-primary"
              data-toggle="modal" (click)="changeProfile()">Switch To {{switchAdmin==true?'Admin':'User'}}</a></li>
        </ul>
      </div>
      <!-- User Info -->
      <div class="card-body pt-4 user-info" [ngClass]="{'d-none':editProfile==true}">
        <div class="row h-100">
          <div class="col-lg-6">
            <div *ngIf="!!userObj" class="row m-lg-0">
              <div class="col-md-6 pb-4 pb-lg-5">
                <span class="label">User Name</span>
                <span class="value">{{userObj.userName}}</span>
              </div>
              <div class="col-md-6 pb-4 pb-lg-5">
                <span class="label">Team Name</span>
                <span *ngIf="userObj.team!=undefined" class="value">{{userObj.team.name}}</span>
              </div>
              <div class="col-md-6 pb-4 pb-lg-5">
                <span class="label">Email</span>
                <span class="value">{{userObj.email}}</span>
              </div>
              <div class="col-md-6 pb-4 pb-lg-5">
                <span class="label">Mobile Number</span>
                <span class="value">{{userObj.phone}}</span>
              </div>
              <div class="col-md-6 pb-4 pb-lg-5">
                <span class="label">DOB</span>
                <span class="value">{{userObj.dobString?userObj.dobString:'-'}}</span>
              </div>
              <div class="col-md-6 pb-4 pb-lg-5">
                <span class="label">Subscription Status</span>
                <span class="value">{{status}}</span>
              </div>
              <div class="col-md-12 pb-4 pb-lg-5" *ngIf="userObj.subscriptionStartDate">
                <span class="label">Member Since</span>
                <span class="value">{{userObj.subscriptionStartDate | date}}</span>
              </div>
              <div class="col-md-6 pb-2">
                <a href="javascript:;" class="btn btn-outline-primary btn-change-pwd" data-toggle="modal"
                  data-target="#changePwd" (click)="changePswdForm.reset()">Change password</a>
              </div>
              <div class="col-md-6 pb-2">
                <a href="javascript:;" class="btn btn-outline-primary btn-change-pwd" data-toggle="modal"
                  data-target="#subscription" (click)="getSubcriptionDetails()">Payment Details</a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="profile-pic">
              <div class="user-profile-pic">
                <label>User Profile Picture</label>
                <div class="pic" [ngStyle]="{backgroundImage: 'url(' + profileSrc+ ')'}">
                  <img *ngIf="uploadedProfilePic==false" src="../../../../assets/img/default.jpg" class="img-fluid" />
                </div>
              </div>

              <div class="user-profile-pic">
                <label>Team Profile Picture</label>
                <div class="pic" [ngStyle]="{backgroundImage: 'url(' + teamSrc+ ')'}">
                  <img *ngIf="uploadTeamPic==false" src="../../../../assets/img/default.jpg" class="img-fluid" />
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- Box Loader -->
        <div class="box-loader" *ngIf="PreLoaderForProfile">
          <img class="text-center image-responsive-height demo-mw-50" src="assets/img/progress.svg" alt="Progress">
        </div>
      </div>
      <!-- Edit User Info -->
      <div class="card-body pt-4 edit-user-info " [ngClass]="{'d-none':editProfile==false}">
        <form [formGroup]="profileForm">
          <div class="row h-100">
            <div class="col-lg-6">
              <div class="row m-lg-0">
                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="username">User Name<span class="star-icon">*</span></label>
                    <input type="text" class="form-control" id="userName" formControlName="userName" required
                      maxlength="30">
                    <small style="color:red" *ngIf="profileForm.controls['userName'].errors?.pattern;else invalidName">
                      Enter valid user name without spaces at start and end
                    </small>
                    <ng-template #invalidName>
                      <small
                        *ngIf="profileForm.controls['userName'].errors && !profileForm.controls['userName'].pristine"
                        style="color:red">Enter User Name</small>
                    </ng-template>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="teamname">Team Name<span class="star-icon">*</span></label>
                    <input type="text" class="form-control" id="teamName" formControlName="teamName" required
                      maxlength="35">
                    <small style="color:red" *ngIf="profileForm.controls['teamName'].errors?.pattern;else invalidTeam">
                      Enter valid team name without spaces at start and end
                    </small>
                    <ng-template #invalidTeam>
                      <small
                        *ngIf="profileForm.controls['teamName'].errors && !profileForm.controls['teamName'].pristine"
                        class="form-text" style="color:red">Enter Team Name</small>
                    </ng-template>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="teamname">Mobile Number</label>
                    <input pattern="[0-9]+" type="text" class="form-control" maxlength="10" id="mobileNumber"
                      formControlName="phone">
                    <small style="color:red" *ngIf="profileForm.controls['phone'].errors?.pattern;else invalidPhone">
                      Please enter only numbers
                    </small>
                    <ng-template #invalidPhone>
                      <small style="color:red"
                        *ngIf="profileForm.controls['phone'].errors?.invalidNumber && !profileForm.controls['phone'].pristine || profileForm.controls['phone'].errors?.minlength">
                        Mobile number must exactly contain 10 digits </small>
                    </ng-template>
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="dob">Date of Birth</label>
                    <my-date-picker name="mydate" id="dob" [options]="myDatePickerOptions" formControlName="dob">
                    </my-date-picker>
                    <small *ngIf="profileForm.controls['dob'].errors && !profileForm.controls['dob'].pristine"
                      class="form-text" style="color:red">Enter
                      DOB</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <span class="label">Subscription Status</span>
                    <span class="value">: {{status}}</span>
                  </div>
                  <!-- <button *ngIf="status=='In Active'" (click)="invokeRazorPay($event)"
                  class="btn btn-primary">Pay Now</button> -->
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="profile-pic">
                <div class="user-profile-pic">
                  <!-- [ngStyle]="{backgroundImage: 'url(' + profileSrc+ ')'}" -->
                  <label>User Profile Picture<span class="star-icon">*</span></label>
                  <div class="pic" [ngStyle]="{backgroundImage: 'url(' + profileSrc+ ')'}">
                    <!-- <img *ngIf="uploadedProfilePic==true" id="blah1" [src]="profileSrc || 'http://placehold.it/180'"
                      alt="your image" class="img-fluid" /> -->
                    <img *ngIf="uploadedProfilePic==false" src="../../../../assets/img/default.jpg" class="img-fluid" />
                    <div class="upload">
                      <i class="fa fa-camera"> </i>
                      <span> Upload Image
                        <!-- <small>160 x 160</small> -->
                      </span>
                      <input type="file" (change)="readURL($event);" />
                    </div>
                  </div>
                </div>

                <div class="user-profile-pic">
                  <label>Team Profile Picture</label>
                  <div class="pic" [ngStyle]="{backgroundImage: 'url(' + teamSrc+ ')'}">
                    <!-- <img *ngIf="uploadTeamPic==true" id="blah1" [src]="teamSrc || 'http://placehold.it/180'"
                      alt="your image" class="img-fluid" /> -->
                    <img *ngIf="uploadTeamPic==false" src="../../../../assets/img/default.jpg" class="img-fluid" />
                    <div class="upload">
                      <i class="fa fa-camera"> </i>
                      <span> Upload Image</span>
                      <input type="file" (change)="readURLForTeam($event);" />
                    </div>
                  </div>
                </div>

              </div>
              <div class="w-100 text-lg-center mt-4">
                <p><strong>Note:</strong> Please add picture with resolution 300 x 300</p>
              </div>

              <!-- <div class="subscription-details">
                <ul>
                  <li><strong>Subscription</strong>:<span>{{status}}</span></li>
                  <li><strong>Ends on</strong>:<span>{{endsOn | date}}</span></li>
                </ul>
              </div> -->
            </div>
            <div class="col-lg-12 text-center mt-4">
              <button (click)="editProfile=false;profileSrc=userObj.profileImage;teamSrc=this.userObj.team.profileImage"
                class="btn btn-outline-primary mr-4">Cancel</button>
              <button type="submit" class="btn btn-primary" (click)="updateProfile()"
                [disabled]="profileForm.invalid">Confirm</button>
            </div>
          </div>
        </form>
        <!-- Box Loader -->
        <div class="box-loader" *ngIf="PreLoaderForProfile">
          <img class="text-center image-responsive-height demo-mw-50" src="assets/img/progress.svg" alt="Progress">
        </div>
        <div class="box-loader" *ngIf="PreLoaderForProfileSave">
          <img class="text-center image-responsive-height demo-mw-50" src="assets/img/progress.svg" alt="Progress">
        </div>
        <!-- Change Password -->

        <!-- Box Loader -->
        <div class="box-loader" *ngIf="PreLoaderForChangePswd">
          <img class="text-center image-responsive-height demo-mw-50" src="assets/img/progress.svg" alt="Progress">
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade add-user" id="changePasswordAlert" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" data-backdrop="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="message text-center">
            Password changed successfully, Please login again
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn  btn-primary" routerLink="/signIn" data-dismiss="modal">OK</button>

        </div>
      </div>
    </div>
  </div>


  <!-- Change PASSWORD -->
  <div class="modal fade add-user delete-user" id="changePwd" tabindex="-1" role="dialog" aria-labelledby="addUserTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Change Password</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <form [formGroup]="changePswdForm" novalidate (submit)="changePswd()" #myform="ngForm">
                <div class="form-group row">
                  <label for="oldpassword" class="col-sm-4 col-form-label">Old Password</label>
                  <div class="col-sm-8">
                    <input [type]="showOldPswd==true ? 'text' : 'password'" type="password" class="form-control"
                      id="oldpassword" class="form-control" formControlName="oldPswd" required>
                    <div class="form-control-icons">
                      <i *ngIf="showOldPswd" class="fa fa-eye" aria-hidden="true"
                        (click)="showOldPswd=!showOldPswd"></i>
                      <i *ngIf="!showOldPswd" class="fa fa-eye-slash" aria-hidden="true"
                        (click)="showOldPswd=!showOldPswd"></i>
                    </div>
                    <div style="color:red"
                      *ngIf="!changePswdForm.controls['oldPswd'].pristine && changePswdForm.get('oldPswd').hasError('required') && myform.submitted ">
                      Old password is required</div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="newPassword" class="col-sm-4 col-form-label">New Password</label>
                  <div class="col-sm-8">
                    <input [type]="showNewPassword==true ? 'text' : 'password'" class="form-control" id="newPassword"
                      class="form-control" formControlName="newPswd" required minlength="8">
                    <div class="form-control-icons">
                      <i *ngIf="showNewPassword" class="fa fa-eye" aria-hidden="true"
                        (click)="showNewPassword=!showNewPassword"></i>
                      <i *ngIf="!showNewPassword" class="fa fa-eye-slash" aria-hidden="true"
                        (click)="showNewPassword=!showNewPassword"></i>
                    </div>
                    <div style="color:red"
                      *ngIf="!changePswdForm.controls['newPswd'].pristine && changePswdForm.get('newPswd').hasError('required') && myform.submitted">
                      New password is required.
                    </div>

                    <div style="color:red"
                      *ngIf="!changePswdForm.controls['newPswd'].pristine &&  changePswdForm.get('newPswd').hasError('minlength') && myform.submitted">
                      Password should contain minimum 8 characters
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="confirmPassword" class="col-sm-4 col-form-label">Confirm Password</label>
                  <div class="col-sm-8">
                    <input [type]="show==true ? 'text' : 'password'" class="form-control" id="confirmPassword"
                      class="form-control" formControlName="confirmPswd" required minlength="8">
                    <div class="form-control-icons">
                      <i *ngIf="show" class="fa fa-eye" aria-hidden="true" (click)="show=!show"></i>
                      <i *ngIf="!show" class="fa fa-eye-slash" aria-hidden="true" (click)="show=!show"></i>
                    </div>
                    <div style="color:red"
                      *ngIf="!changePswdForm.controls['confirmPswd'].pristine && changePswdForm.get('confirmPswd').hasError('required') && myform.submitted">
                      Confirm password is required.
                    </div>
                    <div style="color:red"
                      *ngIf="!changePswdForm.controls['confirmPswd'].pristine && changePswdForm.get('confirmPswd').hasError('minlength') && myform.submitted">
                      Password should contain minimum 8 characters
                    </div>
                    <div style="color:red"
                      *ngIf="!changePswdForm.controls['confirmPswd'].pristine && changePswdForm.get('confirmPswd').hasError('minlength') ==false && changePswdForm.get('confirmPswd').hasError('required')==false && changePswdForm.value.newPswd != changePswdForm.value.confirmPswd && myform.submitted">
                      New password and confirm password not matched
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                  <button type="submit" class="btn btn-primary">Confirm</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--Subscription Details -->
  <div class="modal fade add-user delete-user" id="subscription" tabindex="-1" role="dialog"
    aria-labelledby="addUserTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Payment Details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">

              <div class="form-group row">
                <label for="membership_fees" class="col-sm-4 col-form-label">Membership Fees</label>
                <div class="col-sm-8">
                  {{subscriptionDetails.membershipFee}}
                </div>
              </div>
              <div class="form-group row">
                <label for="payment_date" class="col-sm-4 col-form-label">Payment Due Date</label>
                <div class="col-sm-8">
                  {{subscriptionDetails.paymentDueDate}}
                </div>
              </div>
              <div class="form-group row">
                <label for="penalty" class="col-sm-4 col-form-label">Penalty Fees</label>
                <div class="col-sm-8">
                  {{subscriptionDetails.penalityFee}}
                </div>
              </div>
              <div class="form-group row">
                <label for="duration_for_penalty" class="col-sm-4 col-form-label">Duration for Penalty</label>
                <div class="col-sm-8">
                  {{subscriptionDetails.durationForPenality}}
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>