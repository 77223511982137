import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserModule } from './user-module/user-module.module';
import { ForgetPasswordComponent } from './common/forget-password/forget-password.component';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { SignInComponent } from './common/sign-in/sign-in.component';
import { SigninWithTwitterComponent } from './common/signin-with-twitter/signin-with-twitter.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserHomeComponent } from './user-module/user-home.component';
import { GenericAPIService } from './services/generic-api.service';
import { UrlConstants } from './constants/url-constants';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './services/auth-guard.service';
import { CanDeactivateGuard } from './services/guard-deactivate';
import { MyDatePickerModule } from 'mydatepicker';
import { commonService } from './services/commonMethodService';
import { AdminModule } from './admin-module/admin.module';
import { AdminHomeComponent } from './admin-module/admin-home.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { mobileValidatorDirective, ValidationService } from './constants/validation.directive';
import { UserIdleModule } from 'angular-user-idle';

@NgModule({
  declarations: [
    AppComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    SignInComponent,
    SigninWithTwitterComponent,
    UserHomeComponent,
    AdminHomeComponent,
    mobileValidatorDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    AppRoutingModule,
    NgbModule,
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) 
    // and `ping` is 120 (2 minutes).
    //idle: 840(14 min) grace time :1min
    UserIdleModule.forRoot({idle: 840, timeout: 60, ping: 60}),
    


    AdminModule,
    UserModule,


  ],
  providers: [
    HttpClient,
    GenericAPIService,
    UrlConstants,
    AuthGuard,
    CanDeactivateGuard,
    commonService,
    ValidationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
