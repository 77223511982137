import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class commonService {
    
    getBytes(str) {
        var bytes = [];
        for (var i = 0; i < str.length; ++i) {
          bytes.push(str.charCodeAt(i));
        }
        var a = (new String(str.length)).charCodeAt(0);
        bytes=[a,...bytes,a];
        return bytes;
      }
    
       reverseString(str) {
        var splitString = str.split(""); 
        var reverseArray = splitString.reverse();
        var joinArray = reverseArray.join(""); 
       var v= this.getBytes(joinArray);
        return v;
    } 
   //deep clone 
  keepCloning(objectpassed) {
    if (objectpassed === null || typeof objectpassed !== 'object') {
      return objectpassed;
    }
    // give temporary-storage the original obj's constructor
    var temporary = objectpassed.constructor();
    for (var key in objectpassed) {
      temporary[key] = this.keepCloning(objectpassed[key]);
    }
    return temporary;
  }

}