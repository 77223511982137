import { Component, OnInit } from '@angular/core';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationConstants } from 'src/app/constants/notification-constants';
declare var $: any;

@Component({
  selector: 'app-circuit',
  templateUrl: './circuit.component.html',
  styleUrls: ['./circuit.component.scss']
})
export class CircuitComponent implements OnInit {
  circuitMDlistUrl = UrlConstants.circuitMDList;
  circuitSaveMDUrl = UrlConstants.savecircuitMD;
  circuitDeleteMDurl = UrlConstants.deleteCircuitMD;
  circuitList: any = [];
  noRecord: boolean = false;
  circuitForm;
  circuitImg: File;
  circuitIcon: File;
  circuitIconSrc = null;
  circuitImgSrc = null;
  public uploadcircuitIcon = false;
  public uploadcircuitImg = false;
  reqPayLoad = {};
  isEdit = false;
  failureMsg;
  failureUrl="../../../../assets/img/failure.gif";
  failureGif;
  editObj = {};
  deleteObj = {};
  deletePayload = {}
  preloader: boolean = true;
  slideOutRight: boolean;
  slideInRight: boolean;

  // showCiruitIconMsg;
  // showCiruitImgMsg
  constructor(public genericService: GenericAPIService) { }

  ngOnInit() {
    this.getCircuitMDList();
    this.createForm();
  }
  createForm() {
    this.circuitForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern(/^[^\s].+[^\s]$/)]),
      length: new FormControl('', [Validators.required]),
      lapRecord: new FormControl('', [Validators.required]),
      turns: new FormControl('', [Validators.required]),
      drsZone: new FormControl('', [Validators.required]),
    });
  }
  resetFields() {
    this.uploadcircuitIcon = false;
    this.uploadcircuitImg = false;
    this.circuitImg = null;
    this.circuitIcon = null;
    this.circuitIconSrc = null;
    this.circuitImgSrc = null;
    this.isEdit = false;
    this.circuitForm.reset();
  }
  getEditCircuitDetails(circuit) {
    this.resetFields();
    this.editObj = circuit;
    this.circuitForm.controls['name'].setValue(this.editObj['name']);
    this.circuitForm.controls['length'].setValue(this.editObj['length']);
    this.circuitForm.controls['lapRecord'].setValue(this.editObj['lapRecord']);
    this.circuitForm.controls['turns'].setValue(this.editObj['turns']);
    this.circuitForm.controls['drsZone'].setValue(this.editObj['drsZone']);
    this.isEdit = true;
    if (this.editObj['circuitActive'] != null) {
      this.uploadcircuitIcon = true;
      this.circuitIconSrc = this.editObj['circuitActive'];
    }
    if (this.editObj['circuitImage'] != null) {
      this.uploadcircuitImg = true;
      this.circuitImgSrc = this.editObj['circuitImage'];
    }
    $("#addcircuit").modal("show");
  }

  readURLForIcon(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (this.checkValidOrNotForUser(file) == true) {
        this.circuitIcon = file;
        this.uploadcircuitIcon = true;
        const reader = new FileReader();
        reader.onload = e => this.circuitIconSrc = reader.result;
        reader.readAsDataURL(file);
      }
    }
  }
  readURLForImg(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (this.checkValidOrNotForUser(file) == true) {
        this.circuitImg = file;
        this.uploadcircuitImg = true;
        const reader = new FileReader();
        reader.onload = e => this.circuitImgSrc = reader.result;
        reader.readAsDataURL(file);
      }
    }
  }
  checkValidOrNotForUser(file) {
    var valid: boolean = true;
    this.failureGif=NotificationConstants.getLinkPicture(this.failureUrl);
    if (file.size > 300000) {
      valid = false;
      this.failureMsg = 'File size should not Exceeds 300KB';
    }
    var fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length);
    if (fileExtension != "jpeg" && fileExtension != "png" && fileExtension != "jpg") {
      valid = false;
      this.failureMsg = NotificationConstants.correctFileTypeMsg;
    }
    setTimeout(() => {
      this.slideOutRight=true;
      this.slideInRight=false;
    },3000)
    setTimeout(() => {
      this.slideOutRight=false;
      this.slideInRight=true;
      this.failureMsg = undefined;
    }, 6000);
    if (valid == true) {
      this.failureMsg = undefined
    }
    return valid;
  }
  getCircuitMDList() {
    this.genericService.getAll(this.circuitMDlistUrl).subscribe(response => {
      if (response.status.success = "Success") {
        this.circuitList = response.data;
        this.preloader=false;
        response.totalResult < 1 ? this.noRecord = true : this.noRecord = false;
      }
      else {
        this.noRecord = true;
        this.preloader=false;

      }
    }, error => {
      this.preloader=false;

    })
  }

  payloadFormation() {
    this.reqPayLoad = this.circuitForm.value;
    this.reqPayLoad['isActive'] = true;
    if (this.isEdit == true) {
      this.reqPayLoad['circuitId'] = this.editObj['circuitId'];
    }
    this.save();
  }

  save() {
    if ((this.circuitImgSrc != null || this.circuitImgSrc != undefined) && (this.circuitIconSrc != null || this.circuitIconSrc != undefined)) {
      this.preloader = true;
      let formdata: FormData = new FormData();
      let eee = JSON.stringify(this.reqPayLoad);
      formdata.append('circuit', eee);
      this.circuitImg != null || this.circuitImg != undefined ? formdata.append('circuitImage', this.circuitImg) : formdata.append('circuitImage', null);
      this.circuitIcon != null || this.circuitIcon != undefined ? formdata.append('circuitIcon', this.circuitIcon) : formdata.append('circuitIcon', null);

      this.genericService.imagePost(this.circuitSaveMDUrl, formdata).subscribe(response => {
        if (response.status.success == 'Success' || response.status.success == 'success') {
          this.resetFields();
          this.getCircuitMDList();
          $("#addcircuit").modal("hide");
        }
          this.preloader = false;
      }, error => {
        this.preloader = false;
      });
    }
    else {
      this.failureMsg = NotificationConstants.mandotoryMsg;
      this.failureGif=NotificationConstants.getLinkPicture(this.failureUrl);
      setTimeout(() => {
        this.slideOutRight=true;
        this.slideInRight=false;
      },3000)
      setTimeout(() => {
        this.slideOutRight=false;
        this.slideInRight=true;
        this.failureMsg = undefined;
      }, 6000);
    }
  }

  cancelDelete() {
    $("#deletecircuit").modal("hide");
    this.deleteObj = {};
  }
  delete() {
    this.preloader = true;
    if (this.deleteObj != null) {
      this.deletePayload['circuitId'] = this.deleteObj['circuitId'];
      this.deletePayload['isActive'] = false;
      this.genericService.post(this.circuitDeleteMDurl, this.deletePayload).subscribe(response => {
        this.deleteObj = {};
        this.deletePayload = {};
        if (response.status.success == 'Success') {
          $("#deletecircuit").modal("hide");
          this.getCircuitMDList();
        }
        this.preloader = false;
      }, error => {
        this.preloader = false;
      })
    }
  }


}