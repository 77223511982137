<!-- Alert Messages -->
<div *ngIf="message!=undefined||message!=null"
[ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"class="alert alert-icon alert-danger fade show animated faster"
role="alert">
    <div class="gif-icon">
        <img src={{successGif}} alt="success-icon" />
    </div>
    {{message}}
</div>
<div *ngIf="showerrorMessage!=undefined||showerrorMessage!=null"
[ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"class="alert alert-icon alert-danger fade show animated faster"
role="alert">
    <div class="gif-icon">
        <img src="../../../../assets/img/failure.gif" alt="success-icon" />
    </div>
    {{showerrorMessage}}
</div>
<div *ngIf="expired" [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"class="alert alert-icon alert-danger fade show animated faster"
role="alert">
    <div class="gif-icon">
        <img src={{failureGif}} alt="success-icon" />
    </div>
    Your reset link expired or has already been used. To reset your password, enter your email address
</div>

<section class="login-container h-100">
    <div class="row justify-content-start align-items-center h-100">
        <!-- Forgot Card -->
        <div class="card login-card forgot-card">
            <h4 class="mb-5 pb-2 card-title">Forgot Password</h4>
            <form [formGroup]="forgetpasswordForm">
                <div class="form-group">
                    <input type="email" class="form-control" id="Email" formControlName="email"
                        placeholder="Email Address" required>
                    <small
                        *ngIf="forgetpasswordForm.controls['email'].errors && !forgetpasswordForm.controls['email'].pristine"
                        class="form-text text-muted">Email address required</small>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-login mb-2" [disabled]="forgetpasswordForm.invalid"
                        (click)="send()">Send</button>
                    <small class="form-text text-center text-muted">{{showerrorMessage}}</small>
                    <!-- <small style="color: #2fff3d" class="form-text text-center">{{message}}</small> -->
                </div>

                <!-- Show this div if reset link expired -->
                <div class="form-group forgot-pwd mb-0">
                    <a routerLink="/signIn" class="text-white">Go To Sign In</a>
                </div>
            </form>
            <!-- Box Loader -->
            <div class="box-loader" *ngIf="PreLoader">
                <img class="text-center image-responsive-height demo-mw-50" src="assets/img/progress.svg"
                    alt="Progress">
            </div>
        </div>
    </div>
</section>