import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { UrlConstants } from 'src/app/constants/url-constants';
import { ActivatedRoute } from '@angular/router';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localStorage.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
declare var $: any;

@Component({
  selector: 'app-view-race-entry',
  templateUrl: './view-race-entry.component.html',
  styleUrls: ['./view-race-entry.component.scss']
})
export class ViewRaceEntryComponent implements OnInit {
  public latestRaceDetailsUrl = UrlConstants.latestRaceDetail;
  public PreLoader = false;
  raceEntryUrl = UrlConstants.raceEntry;
  public circuitDetail;
  public raceEntryDriverObj = [];
  public raceEntryConstructorObj = [];
  public raceEntryLapDetail;
  public fastestLapDriver;
  public mostPlacesGainedDriver;
  public polePositionDriver;
  public raceEntryObj;
  public raceId = 0;
  public showEditButton = false;
  public showViewButton = false;
  public showAddTemplate = false;
  public startDateFormat;
  public endDateFormat;
  public resultNotYetUpdated = false;
  // public currentDateTime = new Date();
  public startDiff: number;
  public startDays: number;
  public startHours: number;
  public startMinutes: number;
  public startSeconds: number;
  public getSessioncloseDate: any;
  public endDiff: number;
  public endDays: number;
  public endHours: number;
  public endMinutes: number;
  public endSeconds: number;
  public tempstartDate: any;
  public tempendDate: any;
  public openTill;
  public raceDateFormat;
  public tempRaceDate;
  public notMadeRaceEntry: boolean = false;
  public currentDateTime;
  public noRace: boolean=false;
  public raceEntrySprintTrx:any=null;

  constructor(public _genericService: GenericAPIService, public encryptDecryptService: EncryptDecryptService, public encryptDecryptLocalStorageService: EncryptDecryptLocalStorageService,
    public dataSharingService: DataSharingService) {
      var userId = this.encryptDecryptService.getLocalStorage("userId");
      this.raceId = +this.encryptDecryptService.getLocalStorage("upcomingRaceId");

      this._genericService.getAll(this.latestRaceDetailsUrl+'?raceId='+this.raceId+'&userId='+userId).subscribe(response => {
        if (response.status.success == "Success") {
          var raceDetails = response.data;
        this.currentDateTime = new Date(raceDetails.todayDateTime);
        this.tempstartDate = this.encryptDecryptService.getLocalStorage("raceBdStartingDateTime");
        this.tempendDate = this.encryptDecryptService.getLocalStorage("raceBdClosingDateTime");
        this.tempRaceDate = this.encryptDecryptService.getLocalStorage("raceDate");
        this.startDateFormat = new Date(this.tempstartDate);
        this.endDateFormat = new Date(this.tempendDate);
        this.raceDateFormat = new Date(this.tempRaceDate)
     
        interval(1000).pipe(
          map((x) => {
            this.currentDateTime.setSeconds(this.currentDateTime.getSeconds() + 1);
          })).subscribe((x) => {
          });
    
        this.getSessioncloseDate = this.encryptDecryptService.getLocalStorage("raceBdClosingDateTime")
        var temp = this.getSessioncloseDate.split("T");
        this.openTill = temp[0];
        if (this.currentDateTime < this.startDateFormat) {
          //Need to show this previous result with only view button
          this.raceId = +this.encryptDecryptService.getLocalStorage("previousRaceId");
          this.showEditButton = false;
          this.showViewButton = true;
        }
        if ((this.currentDateTime >= this.startDateFormat) && (this.currentDateTime <= this.endDateFormat)) {
          //Need to show Add entry button for first time
          this.raceId = +this.encryptDecryptService.getLocalStorage("upcomingRaceId");
          if (this.currentDateTime > this.endDateFormat) {
            this.showViewButton = true;
            this.showEditButton = false;
          }
          else {
            this.showViewButton = false;
            this.showEditButton = true;
          }
        }
    
        if ((this.currentDateTime > this.endDateFormat) && (this.currentDateTime <= this.raceDateFormat)) {
          this.raceId = +this.encryptDecryptService.getLocalStorage("upcomingRaceId");
          this.showViewButton = true;
          this.showEditButton = false;
        }
    
        interval(1000).pipe(
          map((x) => {
            this.startDiff = Date.parse(this.endDateFormat) - Date.parse(this.currentDateTime.toString());
          })).subscribe((x) => {
            this.startDays = this.getDays(this.startDiff);
            this.startHours = this.getHours(this.startDiff);
            this.startMinutes = this.getMinutes(this.startDiff);
            this.startSeconds = this.getSeconds(this.startDiff);
            if (this.startDays == 0 && this.startHours == 0 && this.startMinutes == 0 && this.startSeconds == 0) {
              this.showViewButton = false;
              this.showEditButton = false;
              this.showAddTemplate = true;
            }
          });
    
        interval(1000).pipe(
          map((x) => {
            this.endDiff = Date.parse(this.endDateFormat) - Date.parse(this.currentDateTime.toString());
          })).subscribe((x) => {
            this.endDays = this.getDays(this.endDiff);
            this.endHours = this.getHours(this.endDiff);
            this.endMinutes = this.getMinutes(this.endDiff);
            this.endSeconds = this.getSeconds(this.endDiff);
            if (this.endDays == 0 && this.endHours == 0 && this.endMinutes == 0 && this.endSeconds == 0) {
              this.showViewButton = true;
              this.showEditButton = false;
              this.showAddTemplate = false;
              this.raceId = +this.encryptDecryptService.getLocalStorage("upcomingRaceId");
              this.getRaceEntryList();
            }
          });
        setTimeout(() => {
          this.PreLoader = false;
        }, 8500);
        }
        this.getRaceEntryList();
      })
      
  }

  getDays(t) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t) {
    return Math.floor((t / 1000) % 60);
  }

  ngOnInit() {
   
  }
  getRaceEntryList() {
    this.PreLoader = true;
    this._genericService.getAll(this.raceEntryUrl + "?userId=" + this.encryptDecryptService.getLocalStorage("userId") + "&raceId=" + this.raceId).subscribe(response => {
      if (response.status.success == "Success") {
        this.noRace=false;
        this.raceEntryObj = response.data;
        this.circuitDetail = this.raceEntryObj.race;
        this.raceEntryDriverObj = this.raceEntryObj.raceEntryDriverTRX;
        this.raceEntryConstructorObj = this.raceEntryObj.raceEntryConstructorTRX;
        if (this.raceEntryObj.raceEntryLapDetail != undefined) {
          this.raceEntryLapDetail = this.raceEntryObj.raceEntryLapDetail;
          this.fastestLapDriver = this.raceEntryLapDetail.fastestLapDriver;
          this.mostPlacesGainedDriver = this.raceEntryLapDetail.mostPlacesGainedDriver;
          this.polePositionDriver = this.raceEntryLapDetail.polePositionDriver;
          this.raceEntrySprintTrx = response.data.raceEntrySprintTrx;
        }
        // 28Feb-2020 dashboard empty rece entry issue fixed  before ( this.currentDateTime > this.endDateFormat )
        if (this.currentDateTime.getTime() < this.endDateFormat.getTime()) {
          if (this.raceEntryDriverObj.length == 0 && this.raceEntryConstructorObj.length == 0 && this.raceEntryObj.raceEntryLapDetail == null) {
            //if no entry is made
            this.notMadeRaceEntry = true;
            this.showEditButton = false;
            this.showAddTemplate = false;
            this.showViewButton = false;
          }
        }


        setTimeout(() => {
          this.PreLoader = false;
        }, 500)
      }
      else {
        if (response.status.message == "Race Entry Not Found") {
       //   var currentDateTime = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
          var raceDate = sessionStorage.upcommingMatchDate;
          if ((this.currentDateTime >= this.startDateFormat) && (this.currentDateTime <= this.endDateFormat)) {
            this.showEditButton = false;
            this.showViewButton = false;
            this.showAddTemplate = true;
          }

          if (raceDate < this.currentDateTime) {
            //Need to show msg result not yet updated.........
            this.showEditButton = false;
            this.showViewButton = false;
            this.showAddTemplate = false;
            this.resultNotYetUpdated = true;
          }


        }
        this.PreLoader = false;
      }
      if(this.raceId==0)
      {
        this.noRace=true;
      }

    })
  }
  getposition(position) {
    if (position == 1) {
      return position + "st";
    }
    else if (position == 2) {
      return position + "nd";
    }
    else if (position == 3) {
      return position + "rd";
    }
    return position + "th";
  }

  goTop() {
    if ($(window).width() < 1200) {
      $('.main-container').scrollTop(0);
    }
  }
}
