<div class="row h-100 mx-0 actual-page-view">
    <div id="playerLeaderBoard" class="col-xl-4">
        <app-view-player-leader-board (emitUserId)="userIdFromPLB($event)" [expand]='expandPlayer'>
        </app-view-player-leader-board>
        <a class="more btn btn-outline-dark expand-btn" (click)="expandplb()"><i class="fa fa-expand"></i></a>
        <a class="more btn btn-outline-dark d-none compress-btn" (click)="compressplb()"><i
                class="fa fa-compress"></i></a>
    </div>
    <div id="raceEntry" class="col-xl-8 col-lg-12 h-60">
        <app-view-race-entry></app-view-race-entry>
    </div>
    <div id="teamLeaderBoard" class="col-xl-5 col-lg-7 h-40">
        <!-- col-xl-12 -->
        <app-view-team-leader-board [expand]="expandTeam"></app-view-team-leader-board>
        <a class="more btn btn-outline-dark expand-btn" (click)="expandtclb();expandTeam=false;
        "><i class="fa fa-expand"></i></a>
        <a class="more btn btn-outline-dark compress-btn d-none" (click)="compresstclb();expandTeam=true;"><i class="fa fa-compress"
                aria-hidden="true"></i></a>
    </div>
    <div id="twitterFeed" class="col-xl-3 col-lg-5 h-40">
        <app-view-race-prediction></app-view-race-prediction>
        <a class="more btn btn-outline-dark expand-btn" (click)="expandtf()"><i class="fa fa-expand"></i></a>
        <a class="more btn btn-outline-dark compress-btn d-none" (click)="compresstf()"><i
                class="fa fa-compress"></i></a>
    </div>
</div>

<!-- Single User Detail view -->
<div class="row h-100 m-0 single-user-detail-view" *ngIf="userProfileId!=undefined && userProfileId!=0">
    <div class="col-lg-12 h-100">
        <div class="card h-auto mb-4 r down-arw" (click)="closeSingleUserDetailView()">
            <div class="card-body p-3 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.848" height="18.292" viewBox="0 0 34.848 18.292">
                    <path class="a" d="M7028.1,184.362,7044.15,199.5l16.76-15.138"
                        transform="translate(-7027.068 -183.249)" />
                </svg>
            </div>
        </div>
        <app-single-user-detail [individualUserDetail]="{'userId':userProfileId,'isindividual':true}">
        </app-single-user-detail>
    </div>
</div>