
  <div class="card shadow-sm rounded view-player">
      <h4 class="card-title bg-blue">Player Leaderboard</h4>
      <div class="card-body">
          <table class="table table-dark table-hover">
              <thead>
                  <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Team</th>
                      <th scope="col">Points</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let user of users;let i=index">
                      <td scope="row">{{i+1}}</td>
                      <td class="user-name-pic"><span class="user-pic"><img src="{{user.profileImage}}"
                                  class="img-fluid" /></span>{{user.userName}}</td>
                      <td>{{user.team.name}}</td>
                      <td>-</td>
                  </tr>
              </tbody>
          </table>
          <div class="box-loader" *ngIf="PreLoader">
              <img src="assets/img/progress.svg" alt="Progress">
          </div>
      </div>
      <a *ngIf="router.url == '/user/dashboard'" class="more btn btn-outline-dark" [routerLink]="'/user/playerBoard'"><i
              class="fa fa-expand" aria-hidden="true"></i></a>
      <a *ngIf="router.url == '/user/playerBoard'" class="more btn btn-outline-dark" [routerLink]="'/user/dashboard'"><i
              class="fa fa-expand" aria-hidden="true"></i></a>
  </div>