import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RacePredictionComponent } from './race-prediction/race-prediction.component';
import { AuthGuard } from 'src/app/services/auth-guard.service';

const routes: Routes = [
  {path:'racePrediction',
  component:RacePredictionComponent,
  canActivate:[AuthGuard]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RacePredictionRoutingModule { }
