import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RaceEntryComponent } from './race-entry/race-entry.component';
import { RaceEntryRoutingModule } from './race-entry-routing.module';
import { PreviousRaceResultComponent } from './previous-race-result/previous-race-result.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [RaceEntryComponent, PreviousRaceResultComponent],
  imports: [
    CommonModule,
    RaceEntryRoutingModule,
    FormsModule ,
    ReactiveFormsModule ,
    NgbModule,  
  ],
  exports: [RaceEntryComponent, PreviousRaceResultComponent],

})
export class RaceEntryModule { }
