import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewRaceEntryComponent } from './view-race-entry/view-race-entry.component';
import { ViewPlayerLeaderBoardComponent } from './view-player-leader-board/view-player-leader-board.component';
import { ViewTeamLeaderBoardComponent } from './view-team-leader-board/view-team-leader-board.component';
import { ViewRacePredictionComponent } from './view-race-prediction/view-race-prediction.component';
import { DashboardRoutingModule } from './dashboard.routing';
import { DashboardComponent } from './dashboard.component';
import { UserDetailsModule } from '../user-details/user-details.module';

@NgModule({
  declarations: [
    ViewRaceEntryComponent,
    ViewPlayerLeaderBoardComponent,
    ViewTeamLeaderBoardComponent,
    ViewRacePredictionComponent,
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    UserDetailsModule
  ]
})
export class DashboardModule { }
