import { Component, OnInit } from '@angular/core';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { NotificationConstants } from 'src/app/constants/notification-constants';

@Component({
  selector: 'app-rules-engine',
  templateUrl: './rules-engine.component.html',
  styleUrls: ['./rules-engine.component.scss']
})
export class RulesEngineComponent implements OnInit {

  public allRulesUrl = UrlConstants.allRulesUrl;
  public saveRuleUrl = UrlConstants.saveRuleUrl;
  public rules = [];
  public preLoader = false;
  public lapDetailObj: any;
  failureMsg;
  failureUrl="../../../../assets/img/failure.gif";
  failureGif;
  slideOutRight;
  slideInRight;
  constructor(public genericService: GenericAPIService) {
    this.getAllRules();
  }

  ngOnInit() {
  }

  getAllRules() {
    this.preLoader = true;
    this.genericService.getAll(this.allRulesUrl).subscribe(response => {
      if (response.status.success == "Success") {
        this.rules = response.data;
        for (let rule of this.rules) {
          rule.isDisable = true;
          rule.showSave = false;
          rule.showEdit = true;
        }
        //this is to lap details from array because lap details have rulePoleCondition
        this.lapDetailObj = this.rules[3];
        this.lapDetailObj.isDisable = true;
        this.lapDetailObj.showSave = false;
        this.lapDetailObj.showEdit = true;
        this.rules.splice(3, 1);

        this.preLoader = false;
      }
      else {
        this.preLoader = false;
      }
    }, error => {
      this.preLoader = false;
    })
  }

  onEditOfRule(rule) {
    rule.isDisable = false;
    rule.showSave = true;
    rule.showEdit = false;
  }

  onSave(rule) {

    var valid = true;
    if (rule.ruleCondition.length > 0) {
      for (let condition of rule.ruleCondition) {
        if (condition.points == undefined || condition.points == '' || isNaN(condition.points)) {
          valid = false;
          break;
        }
      }
    }
    else {

      if ((rule.rulePoleCondition.fastestLap == undefined || rule.rulePoleCondition.fastestLap == '') ||
        (rule.rulePoleCondition.mostPlacesGained == undefined || rule.rulePoleCondition.mostPlacesGained == '') ||
        (rule.rulePoleCondition.position == undefined || rule.rulePoleCondition.position == '')) {
        valid = false;
      }
    }
    if (valid == true) {
      this.genericService.post(this.saveRuleUrl, rule).subscribe(response => {
        if (response.status.success == 'Success') {
          rule.isDisable = true;
          rule.showSave = false;
          rule.showEdit = true;
        }
      }, error => {
      })
    }
    else{
      this.failureGif=NotificationConstants.getLinkPicture(this.failureUrl);
      this.failureMsg = "Please Fill Valid Points ";
      setTimeout(() => {
        this.slideOutRight=true;
        this.slideInRight=false;
      },3000)
      setTimeout(() => {
        this.slideOutRight=false;
        this.slideInRight=true;
        this.failureMsg = undefined;
      }, 6000);
  
    }
  }


}
