import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { UrlConstants } from 'src/app/constants/url-constants';
import { Router } from '@angular/router';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localStorage.service';
import { commonService } from 'src/app/services/commonMethodService';
declare var $: any;

@Component({
  selector: 'app-view-player-leader-board',
  templateUrl: './view-player-leader-board.component.html',
  styleUrls: ['./view-player-leader-board.component.scss']
})
export class ViewPlayerLeaderBoardComponent implements OnInit {
  public playerLadderUrl = UrlConstants.playerList;
  public getPreChallengeInfoUrl = UrlConstants.getPreChallengeInfo;
  public saveChallengeUrl = UrlConstants.saveChallenge;
  public revokeChallengeUrl = UrlConstants.revokeChallengeUrl;
  public getChallengesUrl = UrlConstants.ladderHistoryUrl;
  public latestRaceDetailsUrl = UrlConstants.latestRaceDetail;
  public users = [];
  public isUserList = false;
  public userResultList = [];
  PreLoader = false;
  noPlayer = false;

  userId;
  successMessage: any;
  failureMessage: any;
  public isPart1 = false;
  startDate: any;
  endDate: any;
  startDateFormat: Date;
  endDateFormat: Date;
  public alreadyChallenged = false;
  public preChallengDetails = {
    "noOfChallengesDoneInPart1": 0,
    "totalNoOfracesInSeason": 0,
    "isChallengedForThisrace": false,
    "noOfChallengesForDoneInThisSeason": 0
  }
  noOfChallengesLeft = 0;
  public isRaceEntryDone = false;
  upcomingRaceId;
  revokeUserId = 0;
  revokeChallengeId;
  public selectedUser: any = {};
  isFirstRace: boolean = false;
  @Input() expand: boolean;
  @Output() emitUserId = new EventEmitter();
  public raceName: any;
  public challenges = [];
  PreLoaderForSave: boolean = false;
  PreLoaderForRevoke: boolean = false;
  sortListByPoints: any = [];
  allUserList: any = [];
  isAll: boolean = true;
  isSortByLastRace: boolean = false;
  constructor(public _genericService: GenericAPIService, public commonService: commonService, public encryptDecryptLocalStorageService: EncryptDecryptLocalStorageService, public router: Router, public encryptDecryptService: EncryptDecryptService) {
    this.userId = this.encryptDecryptService.getLocalStorage("userId");
    this.upcomingRaceId = +this.encryptDecryptService.getLocalStorage("upcomingRaceId");
    this.raceName = this.encryptDecryptService.getLocalStorage("raceName");
    this.getLatestRaceDetail()
    this.getPlayerLeaderBoardList();
    this.getChallangesByUserAndSeason();
    this.getPreChallengedata();
  }

  getLatestRaceDetail() {
    this._genericService.getAll(this.latestRaceDetailsUrl + '?raceId=' + this.upcomingRaceId + '&userId=' + this.userId).subscribe(response => {
      if (response.status.success == "Success") {        
        this.encryptDecryptService.setToLocalStorage("raceName", response.data.raceName);
        this.encryptDecryptService.setToLocalStorage("isSprintEntryFlag", response.data.isSprintEntry);
        this.raceName = this.encryptDecryptService.getLocalStorage("raceName");
      }
    })
  }
  ngOnInit() {
  }
  LastRaceList() {
    this.PreLoader = true;
    this.isSortByLastRace = true;
    this.isAll = false;
    this.userResultList = this.sortListByPoints;
    //   this.getChallengeDetails();
    setTimeout(() => {
      this.PreLoader = false;
    }, 800);
  }
  AllList() {
    this.PreLoader = true;
    this.isSortByLastRace = false;
    this.isAll = true;
    this.userResultList = this.allUserList;
    setTimeout(() => {
      this.PreLoader = false;
    }, 800);
    this.getChallengeDetails();
  }
  sortList() {
    this.sortListByPoints.sort(function (a, b) {
      var pointA = a.points, pointB = b.points
      if (pointA > pointB) //sort string ascending
        return -1
      if (pointA < pointB)
        return 1
      return 0 //default return value (no sorting)
    });
  }

  challengePerson(user) {
    if (this.isRaceEntryDone == false) {
      $("#addRaceEntryModal").modal("show");
    }
    else {
      this.selectedUser = user;
      $("#exampleModalCenter").modal("show");

    }

  }

  getPreChallengedata() {
    this.PreLoader = true
    this._genericService.getAll(this.getPreChallengeInfoUrl + "?userId=" + this.userId + "&raceId=" + this.upcomingRaceId).subscribe(response => {
      if (response.status.success == "Success") {

        this.preChallengDetails = response.data;
        if (this.preChallengDetails.noOfChallengesForDoneInThisSeason != null) {
          this.noOfChallengesLeft = 12 - this.preChallengDetails.noOfChallengesForDoneInThisSeason
        }
        this.isRaceEntryDone = response.data.isRaceEntryDone;
        this.getPlayerLeaderBoardList();
      }
      else {
        this.PreLoader = false
      }
    }, error => {
      this.PreLoader = false
    })
  }
  saveChallenge() {
    this.PreLoaderForSave = true
    var payload = {
      "challengeId": 0,
      "isPart1": this.isPart1,
      "challenger": {
        "f1ApplicationUserId": this.encryptDecryptService.getLocalStorage("userId"),
      },
      "challengedPlayer": {
        "f1ApplicationUserId": this.selectedUser.userId
      },
      "race": {
        "raceId": this.upcomingRaceId
      }
    }

    this._genericService.post(this.saveChallengeUrl, payload).subscribe(response => {
      if (response.status.success == "Success") {
        this.successMessage = response.status.message;
        $("#exampleModalCenter").modal("hide");
        this.getPreChallengedata();
        this.getChallangesByUserAndSeason();
        this.alreadyChallenged = true;
        this.PreLoaderForSave = false
      }
      else {
        this.failureMessage = response.status.message;
        this.PreLoaderForSave = false
      }
      setTimeout(() => {
        this.successMessage = undefined;
        this.failureMessage = undefined;
      }, 2000);
    })
  }

  isDisabled(index, rowUser) {
    var isButtonDisable: boolean = true;
    this.startDate = this.encryptDecryptService.getLocalStorage("raceBdStartingDateTime");
    this.endDate = this.encryptDecryptService.getLocalStorage("raceBdClosingDateTime");
    var currentDateTime = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
    this.startDateFormat = new Date(this.startDate);
    this.endDateFormat = new Date(this.endDate);
    //get pre data...
    var userId = this.encryptDecryptService.getLocalStorage("userId");
    var userIndex = this.userResultList.findIndex(x => x.userId == userId);
    var userTeamName = this.encryptDecryptService.getLocalStorage("teamName");
    var loggedInUser = this.userResultList[userIndex];
    if ((currentDateTime >= this.startDateFormat) && (currentDateTime <= this.endDateFormat)) {
      if (loggedInUser != undefined) {
        // if (loggedInUser.totalPoints > 0) {
        if (this.alreadyChallenged == false) {
          // //he canot  challenge the person with same marks but if 1 person above is same mark need to give chance for above two
          // 1.He can challenge anyone up to 2 places above him on the league points table.but he cant challenge same team
          //if above two belongs to same team we are enbling above above two other than 

          var above1IsSameTeam = false;
          var above2IsSameTeam = false;
          var above1IsSamePonits = false;
          var above2IsSamePoints = false;
          if (userIndex > 0) {
            var above1User = this.userResultList[userIndex - 1];
            if (above1User != undefined) {
              if (above1User.teamName == userTeamName) {
                above1IsSameTeam = true
              }
              if (above1User.ponits == loggedInUser.totalPoints) {
                above1IsSamePonits = true
              }
            }

            var above2User = this.userResultList[userIndex - 2];
            if (above2User != undefined) {
              if (above2User.teamName == userTeamName) {
                above2IsSameTeam = true
              }
              if (above2User.ponits == loggedInUser.totalPoints) {
                above2IsSamePoints = true
              }
            }
            if (above1IsSameTeam == true && above2IsSameTeam == true) {
              if (index == userIndex - 3 || index == userIndex - 4) {
                isButtonDisable = false
              }
            }
            if (above1IsSameTeam == true && above2IsSameTeam == false) {
              if (index == userIndex - 2 || index == userIndex - 3) {
                isButtonDisable = false
              }
            }
            if (above1IsSameTeam == false && above2IsSameTeam == true) {
              if (index == userIndex - 1 || index == userIndex - 3) {
                isButtonDisable = false
              }
            }
            if (above1IsSameTeam == false && above2IsSameTeam == false) {
              if (index == userIndex - 1 || index == userIndex - 2) {
                isButtonDisable = false
              }
            }
          }

          // 2.Each player will get a maximum of 10 challenges for the entire year. 
          var noOfChallengesForDoneInThisSeason = this.preChallengDetails.noOfChallengesForDoneInThisSeason;
          if (noOfChallengesForDoneInThisSeason >= 10) {
            isButtonDisable = true;
          }

          //3. A player CANNOT challenge his own team mate

          if (rowUser.userId != this.encryptDecryptService.getLocalStorage("userId")) {
            if (rowUser.teamName == userTeamName) {
              isButtonDisable = true;
            }
          }


          //4.A player can make only 1 Challenge per race weekend.
          if (this.preChallengDetails.isChallengedForThisrace == true) {
            isButtonDisable = true;
          }
        }
        // }
      }
    }
    return isButtonDisable;
  }



  getPlayerLeaderBoardList() {
    this.PreLoader = true;
    var raceId = +this.encryptDecryptService.getLocalStorage("previousRaceId");
    this._genericService.getAll(this.playerLadderUrl + "?raceId=" + raceId).subscribe(response => {
      if (response.status.success.toLowerCase() == "success") {
        this.isUserList = response.data.isUserList;
        if (this.isUserList == true) {
          this.users = response.data.responseList;
          this.PreLoader = false;
        }
        else {
          this.userResultList = this.commonService.keepCloning(response.data.responseList);
          this.allUserList = this.commonService.keepCloning(response.data.responseList);
          this.sortListByPoints = this.commonService.keepCloning(response.data.responseList);
          this.userResultList.length == 0 ? this.noPlayer = true : this.noPlayer = false;
          this.sortList();
          this.PreLoader = false;
          this.getChallengeDetails();
        }
      }
      else {
        this.noPlayer = true;
        this.PreLoader = false;
      }

    }, error => {
      this.PreLoader = false;
      this.noPlayer = true;
    })
  }

  showIndividualProfile(userId) {
    this.emitUserId.emit(userId);
  }
  getChallengeDetails() {
    // var userId = this.encryptDecryptService.getLocalStorage("userId");
    // var userIndex = this.userResultList.findIndex(x => x.userId == userId);
    // var loggedInUser = this.userResultList[userIndex];
    // var loggedInUserMarks;
    // var sameMarksUsers = []
    // var indexOfLastPerson;
    // if (loggedInUser != undefined) {
    //   loggedInUserMarks = loggedInUser.totalPoints
    //   sameMarksUsers = this.userResultList.filter(user=>user.totalPoints==loggedInUserMarks);
    //   if(sameMarksUsers.length>1){
    //   var  lastPersonWithSameMarks=sameMarksUsers[sameMarksUsers.length-1];
    //   indexOfLastPerson = this.userResultList.findIndex(x => x.userId == lastPersonWithSameMarks.userId);
    //   this.userResultList[indexOfLastPerson]=loggedInUser;
    //   this.userResultList[userIndex]=lastPersonWithSameMarks;
    //   }
    // }
    //To make logged In user should be down when compare with same marks persons end


    // var count = 0;
    // for (let user of this.userResultList) {
    //   user.isDisabled = this.isDisabled(count, user);
    //   count++;
    // }

    for (let user of this.userResultList) {
      user.isDisabled = true;
    }
    this.checkChallengeCondition();
    if (this.userResultList[0].rank == 0) {
      this.isFirstRace = true;
    }
  }
  getChallangesByUserAndSeason() {
    this._genericService.getAll(this.getChallengesUrl + "?userId=" + this.userId).subscribe(response => {
      if (response.status.success == "Success") {
        this.challenges = response.data;
        this.startDate = this.encryptDecryptService.getLocalStorage("raceBdStartingDateTime");
        this.endDate = this.encryptDecryptService.getLocalStorage("raceBdClosingDateTime");
        var currentDateTime = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
        this.startDateFormat = new Date(this.startDate);
        this.endDateFormat = new Date(this.endDate);
        //get pre data...
        for (let challege of this.challenges) {
          if (challege.raceName == this.raceName) {
            if ((currentDateTime >= this.startDateFormat) && (currentDateTime <= this.endDateFormat)) {
              this.revokeUserId = challege.challengedUserId;
              this.revokeChallengeId = challege.challengeId;
            }
          }
        }
        this.PreLoader = false
      }
      else {
        this.PreLoader = false
        this.challenges = [];
      }

    }, error => {
      this.challenges = [];
      this.PreLoader = false
    })
  }
  revokeChallenge() {
    this.PreLoaderForRevoke = true
    this._genericService.delete(this.revokeChallengeUrl + '?challengeId=' + this.revokeChallengeId).subscribe(response => {
      if (response.status.success == "Success") {
        this.revokeUserId = 0;
        $("#revokeChallengeModal").modal("hide");
        this.getPreChallengedata();
        this.getChallangesByUserAndSeason();
        this.alreadyChallenged = false;
        this.PreLoaderForRevoke = false;
      }
      else {
        this.PreLoaderForRevoke = false
      }
    }, error => {
      this.PreLoaderForRevoke = false;
    })
  }
  openRevokeChallengeModal(user) {
    this.selectedUser = user;
    $("#revokeChallengeModal").modal("show");
  }

  checkChallengeCondition() {
    //find login user index and make all above to enble...
    var isButtonDisable: boolean = true;
    this.startDate = this.encryptDecryptService.getLocalStorage("raceBdStartingDateTime");
    this.endDate = this.encryptDecryptService.getLocalStorage("raceBdClosingDateTime");
    var currentDateTime = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
    this.startDateFormat = new Date(this.startDate);
    this.endDateFormat = new Date(this.endDate);
    //get pre data...
    var userId = this.encryptDecryptService.getLocalStorage("userId");
    var userIndex = this.userResultList.findIndex(x => x.userId == userId);
    var userTeamName = this.encryptDecryptService.getLocalStorage("teamName");
    var loggedInUser = this.userResultList[userIndex];
    if ((currentDateTime >= this.startDateFormat) && (currentDateTime <= this.endDateFormat)) {
      if (loggedInUser != undefined) {
        if (this.alreadyChallenged == false) {
          //Make all above enable start...........
          for (let user of this.userResultList) {
            if (user.rank < loggedInUser.rank) {
              user.isDisabled = false;
            }
          }
          //Make all above enable end...........

          //same team members to be disable start....
          for (let user of this.userResultList) {
            if (user.teamId == loggedInUser.teamId) {
              user.isDisabled = true;
            }
          }
          //same team members to be disable end....

          //count how many enables are there...make only two enable start....
          var aboveUsers = [];
          for (let user of this.userResultList) {
            if (user.rank < loggedInUser.rank) {
              aboveUsers.push(user);
            }
          }
          aboveUsers.reverse();
          var count = 0;
          for (let aboveUser of aboveUsers) {
            if (aboveUser.isDisabled == false) {
              count = count + 1;
            }
            if (count > 2) {
              aboveUser.isDisabled = true;
            }
          }
          console.log(aboveUsers);

          //count how many enables are there...make only two enable end....

          // 2.Each player will get a maximum of 10 challenges for the entire year. 
          var noOfChallengesForDoneInThisSeason = this.preChallengDetails.noOfChallengesForDoneInThisSeason;
          if (noOfChallengesForDoneInThisSeason >= 10) {
            for (let user of this.userResultList) {
              user.isDisabled = true;
            }
          }




          //4.A player can make only 1 Challenge per race weekend.
          if (this.preChallengDetails.isChallengedForThisrace == true) {
            for (let user of this.userResultList) {
              user.isDisabled = true;
            }
          }


        }
      }
    }


  }


}
