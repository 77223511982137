import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserDetailsRoutingModule } from './user-details-routing.module';
import { SingleUserDetailComponent } from './single-user-detail/single-user-detail.component';
import { UserDetailsComponent } from './user-details.component';
import { RaceEntryModule } from '../race-entry-module/race-entry.module';

@NgModule({
  declarations: [SingleUserDetailComponent, UserDetailsComponent],
  imports: [
    CommonModule,
    UserDetailsRoutingModule,
    RaceEntryModule
  ],
  exports:[SingleUserDetailComponent]
})
export class UserDetailsModule { }
