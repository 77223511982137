<!-- Alert Messages -->

<div *ngIf="failureMsg!=undefined||failureMsg!=null" [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"class="alert alert-icon alert-danger fade show animated faster"
  role="alert">
  <div class="gif-icon">
      <img src={{failureGif}} alt="success-icon"  />
    </div>
  {{failureMsg}}
</div>


<div class="card shadow-sm rounded view-player">
  <div class="card-title bg-blue">
    <h4>Constructors</h4>
    <ul class="card-title-details float-right">
      <li><a class="btn btn-outline-primary" data-target="#addConstructor" data-toggle="modal" href="javascript:;"
          (click)="resetFields()">Add
          Constructor</a></li>

    </ul>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-dark table-hover">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Constructor</th>
            <th class="action text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let constructor of constructorList;let i =index">
            <td scope="row"> {{i+1}}</td>
            <td class="user-name-pic">
              <span class="user-pic" [ngStyle]="{backgroundImage: 'url('+constructor.constructorPic+ ')'}">
              </span> {{constructor.name}} </td>
            <td class="text-center action">
              <div>
                <a class="btn btn-outline-secondary" href="javascript:;"
                  (click)="getEditConstructorDetails(constructor);isEdit=true"><i class="fa fa-edit"></i></a><a
                  class="btn btn-outline-secondary" (click)="deleteObj=constructor" data-target="#deleteconstructor"
                  data-toggle="modal" href="javascript:;"><i class="fa fa-trash-o"></i></a>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <div *ngIf="noRecord==true" class="row align-items-center  text-center">
      <div class="col-lg-12">
        <p>No records found</p>
      </div>
    </div>
  </div>
  <div class="box-loader" *ngIf="preloader">
    <img src="assets/img/progress.svg" alt="Progress">
  </div>
</div>


<!-- Add constructor -->
<div class="modal fade add-user" id="addConstructor" tabindex="-1" role="dialog" aria-labelledby="addUserTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{isEdit==false?'Add ':'Edit '}}Constructor</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-8">
            <form [formGroup]="constructorForm">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Constructor Name<span
                    class="star-icon">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Enter constructor name"
                    formControlName="constructorName" id="constructorName" required maxlength="35">
                  <small style="color:red"
                    *ngIf="constructorForm.controls['constructorName'].errors?.pattern;else invalidName">
                    Enter
                    valid constructor name without spaces at start and end
                  </small>
                  <ng-template #invalidName>
                    <small
                      *ngIf="constructorForm.controls['constructorName'].errors && !constructorForm.controls['constructorName'].pristine"
                      class="form-text" style="color:red">Enter
                      constructor name</small>
                  </ng-template>
                </div>
              </div>
              <div class="form-group row">
                <label for="joinedDate" class="col-sm-4 col-form-label">Join Date<span class="star-icon">*</span></label>
                <div class="col-sm-8">
                    <my-date-picker name="mydate" id="joinedDate" [options]="myDatePickerOptions" formControlName="joinedDate" required></my-date-picker>
                    <small *ngIf="constructorForm.controls['joinedDate'].errors && !constructorForm.controls['joinedDate'].pristine" class="form-text text-muted">Joined Date is required</small>
                </div>
            </div>
            </form>
          </div>
          <div class="col-lg-4">
            <div class="user-profile-pic">
              <div class="pic" [ngStyle]="{backgroundImage: 'url(' + constructorSrc+ ')'}">
                <!-- <img *ngIf="uploadConstructorPic==false"  src="../../../../assets/img/default.jpg" class="img-fluid" /> -->
                <div class="upload">
                  <i class="fa fa-camera"> </i>
                  <span> Upload Image
                    <!-- <small>160 x 160</small> -->
                  </span>
                  <input type='file' (change)="readURLForUser($event);" />
                </div>
              </div>
              <label>Constructor Picture<span class="star-icon">*</span></label>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="constructorForm.invalid"
          (click)="isEdit==false? add() : update() ">save</button>
      </div>
      <div class="box-loader" *ngIf="preloader">
        <img src="assets/img/progress.svg" alt="Progress">
      </div>
    </div>
  </div>
</div>


<!-- delete constructor popup -->
<div class="modal fade add-user delete-user" id="deleteconstructor" tabindex="-1" role="dialog"
  aria-labelledby="deleteconstructor" aria-hidden="true">
  <div *ngIf="!!deleteObj" class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Delete Constructor</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete {{deleteObj.name}} ?
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-primary" (click)="cancelDelete()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="delete()">Confirm</button>
      </div>
      <div class="box-loader" *ngIf="preloader">
        <img src="assets/img/progress.svg" alt="Progress">
      </div>
    </div>
  </div>
</div>



<!-- delete constructor popup -->
<!-- <div class="modal fade add-user delete-user" id="delete" tabindex="-1" role="dialog" aria-labelledby="delete"
  aria-hidden="true">
  <div *ngIf="!!deleteObj" class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Delete Driver</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete {{deleteObj.foreName+" "+deleteObj.surName}} ?
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-primary" (click)="cancelDelete()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="delete()">Confirm</button>
      </div>
    </div>
  </div>
</div> -->