import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { RacePredictionRoutingModule } from './race-prediction-routing.module';
import { RacePredictionComponent } from './race-prediction/race-prediction.component';
import { UserDetailsModule } from '../user-details/user-details.module';

@NgModule({
  declarations: [RacePredictionComponent],
  imports: [
    CommonModule,
    NgbModule,
    RacePredictionRoutingModule,
    UserDetailsModule
  ]
})
export class RacePredictionModule { }
