import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { GenericAPIService } from '../services/generic-api.service';
import { UrlConstants } from '../constants/url-constants';
import { DataSharingService } from '../services/data-sharing.service';
declare var $: any;
import {Location} from '@angular/common';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {
  public userName: any;
  public userImage: any;
  preLoader=false;
  public logoutUrl = UrlConstants.logoutUrl;
  public showMobileMenu:boolean=false;
  public date_now=new Date(); 

  constructor( private _location: Location,public router: Router,public encryptDecryptService:EncryptDecryptService ,public _genericService: GenericAPIService ,public dataSharingService:DataSharingService) {

    setInterval(() => {
      this.date_now = new Date();
    }, 1000);

    this.dataSharingService.userNameObs.subscribe(data => {
      if (data != '') {
        this.userName = data;
      }
    })
  

    this.dataSharingService.userImageObs.subscribe(data => {
      if (data != '') {
        this.userImage = data;
      }
    })
  }

  ngOnInit() {
    this.userName=this.encryptDecryptService.getLocalStorage('userName');
   this.userImage=this.encryptDecryptService.getLocalStorage('userImage');
  }

  logout() {
    this.preLoader=true;
    this._genericService.post(this.logoutUrl, "").subscribe(response => {
      if (response.status.success == "Success") {
        this.preLoader=false;
        sessionStorage.clear();
        this.router.navigate(['signIn']);
      }
      else {
        this.preLoader=false;
        // sessionStorage.clear();
        this.router.navigate(['signIn']);
      }
    },
    error=>{
      this.preLoader=false;
    });
  }
  navFromProfile(){
    if(this.router.url=='/admin/profile'){
      this._location.back();
    }
  }
  goTop(){
    if ($(window).width() < 1200) {
      $('.main-container').scrollTop(0);
    }
  }

}
