<router-outlet></router-outlet>
<div *ngIf="showNotification" [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}" class="alert alert-icon alert-success fade show animated faster"
  role="alert">  {{sessionExpireNotification}}
  <div class="gif-icon">
    <img src="../assets/img/warning.gif" alt="success-icon" />
  </div>
</div>

<div *ngIf="showSuccessNotification" [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"class="alert alert-icon alert-success fade show animated faster"
  role="alert"> {{successMessage}}
  <div class="gif-icon">
      <img src={{successGif}} alt="success-icon" />
    </div>
 
</div>
<div *ngIf="showSuccessFlagNotification" [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"class="alert alert-icon alert-success fade show animated faster flag-icon"
  role="alert"> {{successFlagMessage}}
  <div class="gif-icon">
      <img src={{successFlagGif}} alt="success-icon" />
    </div>
 
</div>
<div *ngIf="showFailureNotification"  [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}" class="alert alert-icon alert-success fade show animated faster"
  role="alert"> {{failureMessage}}
  <div class="gif-icon">
      <img src={{failureGif}} alt="success-icon" />
    </div>
 
</div>