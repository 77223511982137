<div class="row h-100 mx-0 race-prediction actual-page-view">

  <div class="col-lg-12 h-100">
    <div class="card shadow-sm rounded">
      <h4 class="card-title bg-rose">Race Prediction</h4>
      <div class="card-body" *ngIf="!noRace">
        <!-- Desktop -->
        <div id="racePredictionSlider" class="carousel slide d-none d-lg-block" data-ride="carousel"
          data-interval="false">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-2 text-center" *ngFor="let race of racesForcarosel1">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="95.074"
                        height="49.918" viewBox="0 0 95.074 49.918">
                        <path d="M5.614,14.94c-1.681,3.737-1.636,3.582-2.287,7.948-.6,4.032-.817,3.534,3.284,7.6A26.43,26.43,0,0,1,10,34.178a22.277,22.277,0,0,1-1.417,2.264c-2.283,3.373-2.178,3.673,1.7,4.827A58.128,58.128,0,0,0,28.1,43.684c6.475-.06,7.969.488,8.879,3.258.651,1.982-.5,1.878,19.978,1.81,14.769-.049,18.877-.15,19.352-.477,1.228-.846,1.59-1.959,1.447-4.451-.181-3.176-.448-3.132,6.668-1.1,2.406.686,3.239.815,3.87.6,1.5-.51,4.972-6.928,5.8-10.728.225-1.029-.939-1.912-6.529-4.954-9.021-4.909-8.834-4.869-18.454-3.957-3.651.346-4.514.713-6.561,2.79-2.551,2.588-10.618,3.475-15.305,1.682-6.4-2.45-13.3-11.126-11.981-15.062.651-1.939-3.036-5.343-9.733-8.989C21.93,2.142,17.988,2.637,14.275,5.52,12.346,7.018,11.7,7.263,8.8,7.587c-1.262.1-2.556,5.949-3.182,7.353Z"
                          transform="translate(-1.052 -0.853)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 2 -->
                    <li class="nav-item col-sm-2 text-center" *ngFor="let race of racesForcarosel2">
                      <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-home" aria-selected="true"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="129.18" height="34.221" viewBox="0 0 129.18 34.221">
                        <path d="M131.5,31.838a2.544,2.544,0,0,0,.062-.451c.084-1.385.572-1.313-9.788-1.441-.2,0-.407-.009-.612-.018-1.183-.1-2.36-.237-3.527-.423-4.357-.838-9.724-2.93-17.676-6.8C94.132,19.87,93.4,19.157,93.586,16.5c.116-1.657.073-1.69-5.8-4.491a73.109,73.109,0,0,0-10.49-4.3C60.6,2.941,50.972,3.34,51.048,8.788c.035,2.576-.123,2.6-7.083,1.2-7.372-1.484-8.621-1.34-11.745,1.348-1.869,1.608-2.347,1.908-6.109,3.836-3.226,1.653-3.492,1.7-5.107.857-2-1.042-2.738-.723-8.263,3.583A23.571,23.571,0,0,0,5.784,27.7c-.058.092-.119.182-.176.275a10.811,10.811,0,0,0-.55,1.188c-.078.166-.155.33-.233.5C3.958,31.591,4.484,33.568,5.98,34c1.314.382,1.378.351,2.676-1.33,1.493-1.934,1.7-2,3.963-1.246l3.5,1.163c5,1.659,6.125,1.819,12.781,1.815l4.314,0c1.825,0,4.367-.02,5.649-.041s3.822-.029,5.644-.018,4.456.017,5.853.013c2.707-.008,2.988.084,3.391,1.112a1.492,1.492,0,0,0,1.7,1.144c.648-.04,6.006-.067,9.276-.047s14.468.043,18.13.037c2.373,0,7.15,0,10.615.011s6.864-.059,7.552-.153c1.669-.227,8.854-1.439,9.788-1.651.407-.092,2.059-.374,3.67-.627s4.248-.81,5.858-1.238l1.539-.409a22.34,22.34,0,0,1,2.313-.282l2.654.251c3.51.333,4.425.262,4.654-.665Z"
                          transform="translate(-3.397 -3.396)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 3 -->
                    <li class="nav-item col-sm-2 text-center" *ngFor="let race of racesForcarosel3">
                      <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-home" aria-selected="true"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="129.18" height="34.221" viewBox="0 0 129.18 34.221">
                        <path d="M131.5,31.838a2.544,2.544,0,0,0,.062-.451c.084-1.385.572-1.313-9.788-1.441-.2,0-.407-.009-.612-.018-1.183-.1-2.36-.237-3.527-.423-4.357-.838-9.724-2.93-17.676-6.8C94.132,19.87,93.4,19.157,93.586,16.5c.116-1.657.073-1.69-5.8-4.491a73.109,73.109,0,0,0-10.49-4.3C60.6,2.941,50.972,3.34,51.048,8.788c.035,2.576-.123,2.6-7.083,1.2-7.372-1.484-8.621-1.34-11.745,1.348-1.869,1.608-2.347,1.908-6.109,3.836-3.226,1.653-3.492,1.7-5.107.857-2-1.042-2.738-.723-8.263,3.583A23.571,23.571,0,0,0,5.784,27.7c-.058.092-.119.182-.176.275a10.811,10.811,0,0,0-.55,1.188c-.078.166-.155.33-.233.5C3.958,31.591,4.484,33.568,5.98,34c1.314.382,1.378.351,2.676-1.33,1.493-1.934,1.7-2,3.963-1.246l3.5,1.163c5,1.659,6.125,1.819,12.781,1.815l4.314,0c1.825,0,4.367-.02,5.649-.041s3.822-.029,5.644-.018,4.456.017,5.853.013c2.707-.008,2.988.084,3.391,1.112a1.492,1.492,0,0,0,1.7,1.144c.648-.04,6.006-.067,9.276-.047s14.468.043,18.13.037c2.373,0,7.15,0,10.615.011s6.864-.059,7.552-.153c1.669-.227,8.854-1.439,9.788-1.651.407-.092,2.059-.374,3.67-.627s4.248-.81,5.858-1.238l1.539-.409a22.34,22.34,0,0,1,2.313-.282l2.654.251c3.51.333,4.425.262,4.654-.665Z"
                          transform="translate(-3.397 -3.396)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 4 -->
                    <li class="nav-item col-sm-2 text-center" *ngFor="let race of racesForcarosel4">
                      <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-home" aria-selected="true"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="129.18" height="34.221" viewBox="0 0 129.18 34.221">
                        <path d="M131.5,31.838a2.544,2.544,0,0,0,.062-.451c.084-1.385.572-1.313-9.788-1.441-.2,0-.407-.009-.612-.018-1.183-.1-2.36-.237-3.527-.423-4.357-.838-9.724-2.93-17.676-6.8C94.132,19.87,93.4,19.157,93.586,16.5c.116-1.657.073-1.69-5.8-4.491a73.109,73.109,0,0,0-10.49-4.3C60.6,2.941,50.972,3.34,51.048,8.788c.035,2.576-.123,2.6-7.083,1.2-7.372-1.484-8.621-1.34-11.745,1.348-1.869,1.608-2.347,1.908-6.109,3.836-3.226,1.653-3.492,1.7-5.107.857-2-1.042-2.738-.723-8.263,3.583A23.571,23.571,0,0,0,5.784,27.7c-.058.092-.119.182-.176.275a10.811,10.811,0,0,0-.55,1.188c-.078.166-.155.33-.233.5C3.958,31.591,4.484,33.568,5.98,34c1.314.382,1.378.351,2.676-1.33,1.493-1.934,1.7-2,3.963-1.246l3.5,1.163c5,1.659,6.125,1.819,12.781,1.815l4.314,0c1.825,0,4.367-.02,5.649-.041s3.822-.029,5.644-.018,4.456.017,5.853.013c2.707-.008,2.988.084,3.391,1.112a1.492,1.492,0,0,0,1.7,1.144c.648-.04,6.006-.067,9.276-.047s14.468.043,18.13.037c2.373,0,7.15,0,10.615.011s6.864-.059,7.552-.153c1.669-.227,8.854-1.439,9.788-1.651.407-.092,2.059-.374,3.67-.627s4.248-.81,5.858-1.238l1.539-.409a22.34,22.34,0,0,1,2.313-.282l2.654.251c3.51.333,4.425.262,4.654-.665Z"
                          transform="translate(-3.397 -3.396)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#racePredictionSlider" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#racePredictionSlider" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!-- Tab -->
        <div id="racePredictionSlider1" class="carousel slide d-none d-sm-block d-lg-none" data-ride="carousel"
          data-interval="false">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab1">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="95.074"
                        height="49.918" viewBox="0 0 95.074 49.918">
                        <path d="M5.614,14.94c-1.681,3.737-1.636,3.582-2.287,7.948-.6,4.032-.817,3.534,3.284,7.6A26.43,26.43,0,0,1,10,34.178a22.277,22.277,0,0,1-1.417,2.264c-2.283,3.373-2.178,3.673,1.7,4.827A58.128,58.128,0,0,0,28.1,43.684c6.475-.06,7.969.488,8.879,3.258.651,1.982-.5,1.878,19.978,1.81,14.769-.049,18.877-.15,19.352-.477,1.228-.846,1.59-1.959,1.447-4.451-.181-3.176-.448-3.132,6.668-1.1,2.406.686,3.239.815,3.87.6,1.5-.51,4.972-6.928,5.8-10.728.225-1.029-.939-1.912-6.529-4.954-9.021-4.909-8.834-4.869-18.454-3.957-3.651.346-4.514.713-6.561,2.79-2.551,2.588-10.618,3.475-15.305,1.682-6.4-2.45-13.3-11.126-11.981-15.062.651-1.939-3.036-5.343-9.733-8.989C21.93,2.142,17.988,2.637,14.275,5.52,12.346,7.018,11.7,7.263,8.8,7.587c-1.262.1-2.556,5.949-3.182,7.353Z"
                          transform="translate(-1.052 -0.853)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab2">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="95.074"
                        height="49.918" viewBox="0 0 95.074 49.918">
                        <path d="M5.614,14.94c-1.681,3.737-1.636,3.582-2.287,7.948-.6,4.032-.817,3.534,3.284,7.6A26.43,26.43,0,0,1,10,34.178a22.277,22.277,0,0,1-1.417,2.264c-2.283,3.373-2.178,3.673,1.7,4.827A58.128,58.128,0,0,0,28.1,43.684c6.475-.06,7.969.488,8.879,3.258.651,1.982-.5,1.878,19.978,1.81,14.769-.049,18.877-.15,19.352-.477,1.228-.846,1.59-1.959,1.447-4.451-.181-3.176-.448-3.132,6.668-1.1,2.406.686,3.239.815,3.87.6,1.5-.51,4.972-6.928,5.8-10.728.225-1.029-.939-1.912-6.529-4.954-9.021-4.909-8.834-4.869-18.454-3.957-3.651.346-4.514.713-6.561,2.79-2.551,2.588-10.618,3.475-15.305,1.682-6.4-2.45-13.3-11.126-11.981-15.062.651-1.939-3.036-5.343-9.733-8.989C21.93,2.142,17.988,2.637,14.275,5.52,12.346,7.018,11.7,7.263,8.8,7.587c-1.262.1-2.556,5.949-3.182,7.353Z"
                          transform="translate(-1.052 -0.853)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab3">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="95.074"
                        height="49.918" viewBox="0 0 95.074 49.918">
                        <path d="M5.614,14.94c-1.681,3.737-1.636,3.582-2.287,7.948-.6,4.032-.817,3.534,3.284,7.6A26.43,26.43,0,0,1,10,34.178a22.277,22.277,0,0,1-1.417,2.264c-2.283,3.373-2.178,3.673,1.7,4.827A58.128,58.128,0,0,0,28.1,43.684c6.475-.06,7.969.488,8.879,3.258.651,1.982-.5,1.878,19.978,1.81,14.769-.049,18.877-.15,19.352-.477,1.228-.846,1.59-1.959,1.447-4.451-.181-3.176-.448-3.132,6.668-1.1,2.406.686,3.239.815,3.87.6,1.5-.51,4.972-6.928,5.8-10.728.225-1.029-.939-1.912-6.529-4.954-9.021-4.909-8.834-4.869-18.454-3.957-3.651.346-4.514.713-6.561,2.79-2.551,2.588-10.618,3.475-15.305,1.682-6.4-2.45-13.3-11.126-11.981-15.062.651-1.939-3.036-5.343-9.733-8.989C21.93,2.142,17.988,2.637,14.275,5.52,12.346,7.018,11.7,7.263,8.8,7.587c-1.262.1-2.556,5.949-3.182,7.353Z"
                          transform="translate(-1.052 -0.853)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab4">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="95.074"
                        height="49.918" viewBox="0 0 95.074 49.918">
                        <path d="M5.614,14.94c-1.681,3.737-1.636,3.582-2.287,7.948-.6,4.032-.817,3.534,3.284,7.6A26.43,26.43,0,0,1,10,34.178a22.277,22.277,0,0,1-1.417,2.264c-2.283,3.373-2.178,3.673,1.7,4.827A58.128,58.128,0,0,0,28.1,43.684c6.475-.06,7.969.488,8.879,3.258.651,1.982-.5,1.878,19.978,1.81,14.769-.049,18.877-.15,19.352-.477,1.228-.846,1.59-1.959,1.447-4.451-.181-3.176-.448-3.132,6.668-1.1,2.406.686,3.239.815,3.87.6,1.5-.51,4.972-6.928,5.8-10.728.225-1.029-.939-1.912-6.529-4.954-9.021-4.909-8.834-4.869-18.454-3.957-3.651.346-4.514.713-6.561,2.79-2.551,2.588-10.618,3.475-15.305,1.682-6.4-2.45-13.3-11.126-11.981-15.062.651-1.939-3.036-5.343-9.733-8.989C21.93,2.142,17.988,2.637,14.275,5.52,12.346,7.018,11.7,7.263,8.8,7.587c-1.262.1-2.556,5.949-3.182,7.353Z"
                          transform="translate(-1.052 -0.853)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab5">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="95.074"
                        height="49.918" viewBox="0 0 95.074 49.918">
                        <path d="M5.614,14.94c-1.681,3.737-1.636,3.582-2.287,7.948-.6,4.032-.817,3.534,3.284,7.6A26.43,26.43,0,0,1,10,34.178a22.277,22.277,0,0,1-1.417,2.264c-2.283,3.373-2.178,3.673,1.7,4.827A58.128,58.128,0,0,0,28.1,43.684c6.475-.06,7.969.488,8.879,3.258.651,1.982-.5,1.878,19.978,1.81,14.769-.049,18.877-.15,19.352-.477,1.228-.846,1.59-1.959,1.447-4.451-.181-3.176-.448-3.132,6.668-1.1,2.406.686,3.239.815,3.87.6,1.5-.51,4.972-6.928,5.8-10.728.225-1.029-.939-1.912-6.529-4.954-9.021-4.909-8.834-4.869-18.454-3.957-3.651.346-4.514.713-6.561,2.79-2.551,2.588-10.618,3.475-15.305,1.682-6.4-2.45-13.3-11.126-11.981-15.062.651-1.939-3.036-5.343-9.733-8.989C21.93,2.142,17.988,2.637,14.275,5.52,12.346,7.018,11.7,7.263,8.8,7.587c-1.262.1-2.556,5.949-3.182,7.353Z"
                          transform="translate(-1.052 -0.853)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab6">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="95.074"
                        height="49.918" viewBox="0 0 95.074 49.918">
                        <path d="M5.614,14.94c-1.681,3.737-1.636,3.582-2.287,7.948-.6,4.032-.817,3.534,3.284,7.6A26.43,26.43,0,0,1,10,34.178a22.277,22.277,0,0,1-1.417,2.264c-2.283,3.373-2.178,3.673,1.7,4.827A58.128,58.128,0,0,0,28.1,43.684c6.475-.06,7.969.488,8.879,3.258.651,1.982-.5,1.878,19.978,1.81,14.769-.049,18.877-.15,19.352-.477,1.228-.846,1.59-1.959,1.447-4.451-.181-3.176-.448-3.132,6.668-1.1,2.406.686,3.239.815,3.87.6,1.5-.51,4.972-6.928,5.8-10.728.225-1.029-.939-1.912-6.529-4.954-9.021-4.909-8.834-4.869-18.454-3.957-3.651.346-4.514.713-6.561,2.79-2.551,2.588-10.618,3.475-15.305,1.682-6.4-2.45-13.3-11.126-11.981-15.062.651-1.939-3.036-5.343-9.733-8.989C21.93,2.142,17.988,2.637,14.275,5.52,12.346,7.018,11.7,7.263,8.8,7.587c-1.262.1-2.556,5.949-3.182,7.353Z"
                          transform="translate(-1.052 -0.853)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab7">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="95.074"
                        height="49.918" viewBox="0 0 95.074 49.918">
                        <path d="M5.614,14.94c-1.681,3.737-1.636,3.582-2.287,7.948-.6,4.032-.817,3.534,3.284,7.6A26.43,26.43,0,0,1,10,34.178a22.277,22.277,0,0,1-1.417,2.264c-2.283,3.373-2.178,3.673,1.7,4.827A58.128,58.128,0,0,0,28.1,43.684c6.475-.06,7.969.488,8.879,3.258.651,1.982-.5,1.878,19.978,1.81,14.769-.049,18.877-.15,19.352-.477,1.228-.846,1.59-1.959,1.447-4.451-.181-3.176-.448-3.132,6.668-1.1,2.406.686,3.239.815,3.87.6,1.5-.51,4.972-6.928,5.8-10.728.225-1.029-.939-1.912-6.529-4.954-9.021-4.909-8.834-4.869-18.454-3.957-3.651.346-4.514.713-6.561,2.79-2.551,2.588-10.618,3.475-15.305,1.682-6.4-2.45-13.3-11.126-11.981-15.062.651-1.939-3.036-5.343-9.733-8.989C21.93,2.142,17.988,2.637,14.275,5.52,12.346,7.018,11.7,7.263,8.8,7.587c-1.262.1-2.556,5.949-3.182,7.353Z"
                          transform="translate(-1.052 -0.853)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>

          </div>
          <a class="carousel-control-prev" href="#racePredictionSlider1" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#racePredictionSlider1" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!-- Mobile -->
        <div id="racePredictionSlider2" class="carousel slide d-block d-sm-none" data-ride="carousel"
          data-interval="false">
          <div class="carousel-inner">
            <div class="carousel-item" *ngFor="let race of raceList;let i=index" [ngClass]="{'active': i ==0}">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-12 text-center">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="95.074"
                        height="49.918" viewBox="0 0 95.074 49.918">
                        <path d="M5.614,14.94c-1.681,3.737-1.636,3.582-2.287,7.948-.6,4.032-.817,3.534,3.284,7.6A26.43,26.43,0,0,1,10,34.178a22.277,22.277,0,0,1-1.417,2.264c-2.283,3.373-2.178,3.673,1.7,4.827A58.128,58.128,0,0,0,28.1,43.684c6.475-.06,7.969.488,8.879,3.258.651,1.982-.5,1.878,19.978,1.81,14.769-.049,18.877-.15,19.352-.477,1.228-.846,1.59-1.959,1.447-4.451-.181-3.176-.448-3.132,6.668-1.1,2.406.686,3.239.815,3.87.6,1.5-.51,4.972-6.928,5.8-10.728.225-1.029-.939-1.912-6.529-4.954-9.021-4.909-8.834-4.869-18.454-3.957-3.651.346-4.514.713-6.561,2.79-2.551,2.588-10.618,3.475-15.305,1.682-6.4-2.45-13.3-11.126-11.981-15.062.651-1.939-3.036-5.343-9.733-8.989C21.93,2.142,17.988,2.637,14.275,5.52,12.346,7.018,11.7,7.263,8.8,7.587c-1.262.1-2.556,5.949-3.182,7.353Z"
                          transform="translate(-1.052 -0.853)" fill="none" stroke="#fff" stroke-linejoin="round"
                          stroke-width="2" /></svg> -->
                        <div>{{race.name}}</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#racePredictionSlider2" role="button" data-slide="prev"
            (click)="previousForMobile()">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#racePredictionSlider2" role="button" data-slide="next"
            (click)="nextForMobile()">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>

        <!-- Search box *ngIf="showSearchBox==true" -->
        <form onkeypress="return event.keyCode != 13" id="search">
          <div *ngIf="showSearchBox==true" class="form-group search-box">
            <div class="input-group">
              <input type="text" class="form-control" [ngClass]="{'input-blink':hideBlink}" (click)="hideBlink=false"
                (keyup)="onKey($event)" id="Search" placeholder="Search">
              <div class="input-group-append">
                <span class="input-group-text" id="inputGroupPrepend"><i class="fa fa-search"
                    (click)="onClickSearchPrediction($event)"></i></span>
              </div>
            </div>
          </div>
        </form>
        <!-- New UI for Race Prediction *ngIf="raceEntries.length>0"-->
        <div class="tab-content" id="pills-tabContent" *ngIf="raceEntries.length>0">
          <!-- Location 1 -->
          <div class="tab-pane fade show active" id="location-1" role="tabpanel" aria-labelledby="pills-home-tab">

            <div class="card pb-0 mb-4">
              <div class="card-body p-0 overflow-hidden">
                <div class="table-responsive race-prediction-table">
                  <table class="table table-dark border-bottom">
                    <thead>
                      <tr>
                        <th rowspan="2" class="text-center border-left border-right headcol"> Competitors</th>
                        <th colspan="6" class="text-center border-right bb-0">Drivers</th>
                        <th colspan="3" class="text-center border-right bb-0">Sprint Entry</th>
                        <th colspan="3" class="text-center border-right bb-0">Constructors</th>
                        <th rowspan="2" class="text-center border-right"> MPG</th>
                        <th rowspan="2" class="text-center border-right"> FL</th>
                        <th rowspan="2" class="text-center border-right"> PP</th>
                      </tr>
                      <tr>
                        <th class="headcol border-0 d-block d-xl-none"></th>
                        <th class="bt-0">1st</th>
                        <th class="bt-0">2nd</th>
                        <th class="bt-0">3rd</th>
                        <th class="bt-0">4th</th>
                        <th class="bt-0">5th</th>
                        <th class="bt-0 border-right">6th</th>
                        <th class="bt-0">1st</th>
                        <th class="bt-0">2nd</th>
                        <th class="bt-0 border-right">3rd</th>
                        <th class="bt-0">1st</th>
                        <th class="bt-0">2nd</th>
                        <th class="bt-0 border-right">3rd</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let raceEntry of raceEntries">
                        <td class="border-left border-right text-center cursor-pointer headcol"
                          (click)="showIndividualProfile(raceEntry.userId)">
                          <div>{{raceEntry.userName}}</div>
                        </td>

                        <td *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent0>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[0].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[0].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[0].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent0"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[0].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[0].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent0" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[0].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[0].drivers.surName}}</div>
                        </td>
                        <!-- //for Empty Drivers -->
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center border-right">
                          -
                        </td>




                        <!-- //for Empty Constructors -->
                        <td *ngIf="raceEntry.raceEntryConstructorTRX.length==0" class="center ">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryConstructorTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryConstructorTRX.length==0" class="center border-right">
                          -
                        </td>
                        <!-- //For Lap Details -->
                        <td *ngIf="raceEntry.raceEntryLapDetail==null" class="center border-right">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryLapDetail==null" class="center border-right">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryLapDetail==null" class="center border-right">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent1>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[1].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[1].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[1].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent1"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[1].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[1].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent1" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[1].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[1].drivers.surName}}</div>
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent2>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[2].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[2].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[2].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent2"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[2].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[2].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent2" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[2].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[2].drivers.surName}}</div>
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent3>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[3].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[3].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[3].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent3"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[3].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[3].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent3" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[3].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[3].drivers.surName}}</div>
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent4>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[4].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[4].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[4].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent4"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[4].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[4].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent4" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[4].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[4].drivers.surName}}</div>
                        </td>
                        <td class="border-right" *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent5>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[5].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[5].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[5].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent5"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[5].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[5].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent5" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[5].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[5].drivers.surName}}</div>
                        </td>

                        <!-- raceEntrySprintTrx -->

                        <!-- race entry -->
                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length==0"
                          class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length==0"
                          class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length==0"
                          class="center border-right">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC0>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntrySprintTrx[0].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntrySprintTrx[0].drivers.foreName+'
                              '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                            </div>
                          </ng-template>

                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC0"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[0].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                          </div>

                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC0"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[0].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                          </div>
                        </td>

                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC0>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntrySprintTrx[1].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntrySprintTrx[1].drivers.foreName+'
                              '+raceEntry.raceEntrySprintTrx[1].drivers.surName}}
                            </div>
                          </ng-template>

                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC0"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[1].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[1].drivers.surName}}
                          </div>

                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC0"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[1].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[1].drivers.surName}}
                          </div>
                        </td>

                        <td class="border-right"
                          *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC0>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntrySprintTrx[2].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntrySprintTrx[2].drivers.foreName+'
                              '+raceEntry.raceEntrySprintTrx[2].drivers.surName}}
                            </div>
                          </ng-template>

                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC0"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[2].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[2].drivers.surName}}
                          </div>

                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC0"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[2].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[2].drivers.surName}}
                          </div>
                        </td>



                        <!-- <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC0>
                            <div class="user-name-pic"><span class="user-pic"><img src={{raceEntry.raceEntrySprintTrx[0].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntrySprintTrx[0].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                            </div>
                          </ng-template>
                           
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC0" placement="right"
                            triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[0].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                          </div>
                           
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC0" placement="top"
                            [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[0].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                          </div>
                        </td>

                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC1>
                            <div class="user-name-pic"><span class="user-pic"><img src={{raceEntry.raceEntrySprintTrx[1].drivers.driverPic}}
                                  class="img-fluid" /></span>
                                  {{raceEntry.raceEntrySprintTrx[1].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[1].drivers.surName}}
                            </div>
                          </ng-template>
                           
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC1" placement="right"
                            triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[1].drivers.foreName+' '+aceEntry.raceEntrySprintTrx[1].drivers.surName}}
                          </div>
                          
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC1" placement="top"
                            [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[1].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[1].drivers.surName}}
                          </div>
                        </td>

                        <td class="border-right" *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC2>
                            <div class="user-name-pic"><span class="user-pic"><img src={{raceEntry.raceEntrySprintTrx[2].drivers.driverPic}}
                                  class="img-fluid" /></span>
                                  {{raceEntry.raceEntrySprintTrx[2].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[2].drivers.surName}}
                            </div>
                          </ng-template>
                          
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC2" placement="right"
                            triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[2].drivers.foreName+' '+aceEntry.raceEntrySprintTrx[2].drivers.surName}}
                          </div>
                          
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC2" placement="top"
                            [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[2].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[2].drivers.surName}}
                          </div>
                        </td> -->


                        <td *ngIf="raceEntry.raceEntryConstructorTRX.length>0">
                          <ng-template #popContentC0>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryConstructorTRX[0].constructors.constructorPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryConstructorTRX[0].constructors.name}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC0"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryConstructorTRX[0].constructors.name}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC0"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntryConstructorTRX[0].constructors.name}}</div>
                        </td>
                        <td *ngIf="raceEntry.raceEntryConstructorTRX.length>0">
                          <ng-template #popContentC1>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryConstructorTRX[1].constructors.constructorPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryConstructorTRX[1].constructors.name}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC1"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryConstructorTRX[1].constructors.name}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC1"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntryConstructorTRX[1].constructors.name}}</div>
                        </td>
                        <td class="border-right" *ngIf="raceEntry.raceEntryConstructorTRX.length>0">
                          <ng-template #popContentC2>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryConstructorTRX[2].constructors.constructorPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryConstructorTRX[2].constructors.name}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC2"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryConstructorTRX[2].constructors.name}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC2"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntryConstructorTRX[2].constructors.name}}</div>
                        </td>


                        <td class="border-right text-center" *ngIf="raceEntry.raceEntryLapDetail!=null">
                          <ng-template #popContentMPG>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.foreName+'
                              '+raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.surName}}
                            </div>
                          </ng-template>
                          <!-- Deskop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentMPG"
                            placement="left" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentMPG"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.surName}}</div>
                        </td>

                        <td class="border-right text-center" *ngIf="raceEntry.raceEntryLapDetail!=null">
                          <ng-template #popContentFL>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryLapDetail.fastestLapDriver.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryLapDetail.fastestLapDriver.foreName+'
                              '+raceEntry.raceEntryLapDetail.fastestLapDriver.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentFL"
                            placement="left" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryLapDetail.fastestLapDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.fastestLapDriver.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentFL"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntryLapDetail.fastestLapDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.fastestLapDriver.surName}}</div>
                        </td>
                        <td class="border-right text-center" *ngIf="raceEntry.raceEntryLapDetail!=null">
                          <ng-template #popContentPP>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryLapDetail.polePositionDriver.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryLapDetail.polePositionDriver.foreName+'
                              '+raceEntry.raceEntryLapDetail.polePositionDriver.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentPP"
                            placement="left" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryLapDetail.polePositionDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.polePositionDriver.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentPP"
                            placement="left" [autoClose]="true">
                            {{raceEntry.raceEntryLapDetail.polePositionDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.polePositionDriver.surName}}</div>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-content text-center" id="pills-tabContent"
          *ngIf="searchResultNotFound==true && resultNotthere==false">
          <span>No records found</span>
        </div>

        <div class="tab-content" id="pills-tabContent" *ngIf="resultNotthere==true && searchResultNotFound==false">
          <div class="row">
            <div class="col-sm-12 text-center">
              <span>Race prediction not yet updated</span>
            </div>
          </div>
        </div>
        <div class="box-loader" *ngIf="PreLoader">
          <img src="assets/img/progress.svg" alt="Progress">
        </div>
      </div>
      <!-- no race for a year -->
      <div class="col-lg-12 p-2 race-prediction " *ngIf="noRace">
        <div class="row prediction align-items-center justify-content-center h-100" *ngIf="noRace">
          <div class="col-lg-12">
            <p class="border-0 m-0">Currently, there are no race entries, stay tuned for updates...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Single User Detail view -->
<div class="row h-100 m-0 single-user-detail-view" *ngIf="userProfileId!=undefined && userProfileId!=0">
  <div class="col-lg-12 h-100">
    <div class="card h-auto mb-4 r down-arw" (click)="closeSingleUserDetailView()">
      <div class="card-body p-3 text-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="34.848" height="18.292" viewBox="0 0 34.848 18.292">
          <path class="a" d="M7028.1,184.362,7044.15,199.5l16.76-15.138" transform="translate(-7027.068 -183.249)" />
        </svg>
      </div>
    </div>
    <app-single-user-detail [individualUserDetail]="{'userId':userProfileId,'isindividual':true}">
    </app-single-user-detail>
  </div>
</div>