import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MasterDataRoutingModule } from './master-data-routing.module';
import { MasterDataComponent } from './master-data.component';
import { DriverComponent } from './driver/driver.component';
import { ConstructorComponent } from './constructor/constructor.component';
import { CircuitComponent } from './circuit/circuit.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
  declarations: [MasterDataComponent, DriverComponent, ConstructorComponent, CircuitComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    FormsModule,
    MasterDataRoutingModule
    
  ]
})
export class MasterDataModule { }
