<div class="subscription-details card shadow-sm">
  <div class="card-title bg-blue">
    <h4>Subscription Details</h4>
  </div>
  <div class="card-body race-circuits">
    <div class="col-lg-12">
      <form [formGroup]="subscriptionForm" novalidate (ngSubmit)="submitForm()">
        <div class="row mt-5 pt-3">
          <div class="col-lg-3 col-md-6">
            <div class="form-group mb-4">
              <label for="member-fees">Membership Fees</label>
              <input (keypress)="numberOnly($event)" class="form-control" id="member-fees"
                formControlName="membershipFee" type="text" placeholder="Rs.">
            </div>
            <div *ngIf="membershipFee.invalid && (membershipFee.dirty || membershipFee.touched)">
              <small *ngIf="membershipFee.errors.required" class="form-text" style="color:red">Membership fees is required</small>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="form-group mb-4">
              <label for="payent-date">Payment Due Date</label>
              <my-date-picker name="mydate" id="payent-date" [options]="myDatePickerOptions"
                formControlName="paymentDueDate" required>
              </my-date-picker>
            </div>
            <div *ngIf="paymentDueDate.invalid && (paymentDueDate.dirty || paymentDueDate.touched)">
              <small *ngIf="paymentDueDate.errors.required" class="form-text" style="color:red">Please
                 select payment due date</small>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="form-group mb-4">
              <label for="penality-fees">Penality Fees</label>
              <input (keypress)="numberOnly($event)" class="form-control" id="penality-fees"
                formControlName="penalityFee" type="text" placeholder="Rs">
            </div>
            <div *ngIf="penalityFee.invalid && (penalityFee.dirty || penalityFee.touched)">
              <small *ngIf="penalityFee.errors.required" class="form-text" style="color:red">
                Penality Fees is required. </small>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="form-group mb-4">
              <label for="penality-duration">Duration for Penality</label>
              <input (keypress)="numberOnly($event)" class="form-control" id="penality-duration"
                formControlName="durationForPenality" type="text" placeholder="No of days">
            </div>
          </div>
          <div class="col-md-12 mt-5 d-flex justify-content-center">
            <!-- <button type="button" class="btn btn-outline-primary btn-change-pwd mr-5">Clear</button> -->
            <button type="submit" class="btn btn-primary" #submitBtn (click)="submitBtn.value=1">{{subsDetailId?'Update':'Save'}}</button>
            <!-- subsDetailId?'Update': -->
          </div>
        </div>
      </form>
    </div>

  </div>
</div>