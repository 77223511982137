<div class="card shadow-sm rounded view-race-entry">
    <!-- <app-view-race-entry></app-view-race-entry> -->
    <div class="card-title bg-rose">
        <h4>Race Entry</h4>
        <ul class="list-unstyled card-title-details" *ngIf="!!raceEntryObj">
            <!-- <li *ngIf="showEditButton==true" class="open-date"><span>Open Till :</span> {{openTill}}
            </li> -->
            <li *ngIf="showEditButton==true"><a [routerLink]="'/user/raceEntry'" (click)="goTop()" class="btn btn-outline-primary">Edit
                    Details</a></li>
            <li *ngIf="showViewButton==true"><a [routerLink]="'/user/raceEntry'" (click)="goTop()" class="btn btn-outline-primary">View
                    Details</a></li>
        </ul>
    </div>
    <div class="card-body pt-3">
        <!-- Add Entry -->
            <div class="row add-entry align-items-center justify-content-center h-100" *ngIf="showAddTemplate==true">
                <div class="col-lg-12" *ngIf="PreLoader==false">
                    <p class="border-0 mb-4">You have not made any entry for this race</p>
                    <a [routerLink]="'/user/raceEntry'" (click)="goTop()" class="btn btn-outline-primary">Add Entry</a>
                </div>
            </div>
            <div class="row add-entry align-items-center justify-content-center h-100" *ngIf=" showAddTemplate==false && notMadeRaceEntry==true">
                <div class="col-lg-12" *ngIf="PreLoader==false">
                    <p class="border-0 mb-4">You have not made any entry for this race</p>
                </div>
            </div>


        <div class="row add-entry align-items-center justify-content-center h-100" *ngIf="resultNotYetUpdated==true">
            <div class="col-lg-12">
                <p class="border-0 m-0">Race Entry Result not yet Updated,stay tuned for updates...</p>
                <!-- <a [routerLink]="'/user/raceEntry'" class="btn btn-outline-primary">Add Entry</a> -->
            </div>
        </div>
        <div class="row add-entry align-items-center justify-content-center h-100" *ngIf="noRace==true">
            <div class="col-lg-12">
                <p class="border-0 m-0">No races available currently, stay tuned for updates...</p>
            </div>
        </div>
        <div class="row" *ngIf="showAddTemplate==false && notMadeRaceEntry==false ">
            <div class="col-lg-12" *ngIf="!!raceEntryObj">
                <p>{{circuitDetail.name}} -{{circuitDetail.circuits.name}},
                    {{circuitDetail.circuits.location}},{{circuitDetail.circuits.country}}.</p>
                <ul class="list-unstyled race-entry-list mb-3">
                    <li>
                        <h5>Drivers</h5>
                    </li>
                    <li *ngFor="let driver of raceEntryDriverObj;let i=index">
                        <div class="race-entry-ranking">
                            <span class="position">{{getposition(driver.position)}}</span>
                            <span class="picture">
                                <img src={{driver.drivers.driverPic}} alt="driver" />
                            </span>
                            <span class="name">{{driver.drivers.foreName+' '+ driver.drivers.surName}}</span>
                        </div>
                    </li>
                </ul>
                <ul class="list-unstyled race-entry-list d-none d-lg-flex">
                    <li>
                        <h5>Constructors</h5>
                    </li>
                    <li *ngFor="let constructor of raceEntryConstructorObj;let i=index">
                        <div class="race-entry-ranking">
                            <span class="position">{{getposition(constructor.position)}}</span>
                            <span class="picture">
                                <img *ngIf="constructor.constructors.constructorPic!=undefined;else defaultImg" src={{constructor.constructors.constructorPic}}
                                    alt="driver" />
                                <ng-template #defaultImg>
                                    <img src="../../../assets/img/1.jpg" alt="driver" />
                                </ng-template>
                            </span>
                            <span class="name">{{constructor.constructors.name}}</span>
                        </div>
                    </li>
                    <li class="border-left">
                        <div class="race-entry-ranking lap">
                            <div class="position">Fastest Lap</div>
                            <div>
                                <span class="picture">
                                    <img src={{fastestLapDriver.driverPic}} alt="driver" />
                                </span>
                                <span class="name">{{fastestLapDriver.foreName+' '+fastestLapDriver.surName}}</span></div>
                        </div>
                    </li>
                    <li>
                        <div class="race-entry-ranking pole">
                            <div class="position">Pole Position</div>
                            <div><span class="picture">
                                    <img src={{polePositionDriver.driverPic}} alt="driver" />                                   
                                </span>
                                <span class="name">{{polePositionDriver.foreName+' '+polePositionDriver.surName}}</span></div>
                        </div>
                    </li>
                    <li>
                        <div class="race-entry-ranking place">
                            <div class="position">Most Places Gained</div>
                            <div><span class="picture">
                                    <img src={{mostPlacesGainedDriver.driverPic}} alt="driver" />
                                </span>
                                <span class="name">{{mostPlacesGainedDriver.foreName+'
                                    '+mostPlacesGainedDriver.surName}}</span></div>
                        </div>
                    </li>
                    
                </ul>

                <ul class="list-unstyled race-entry-list d-block d-lg-none">
                    <li>
                        <h5>Constructors</h5>
                    </li>
                    <li *ngFor="let constructor of raceEntryConstructorObj;let i=index">
                        <div class="race-entry-ranking">
                            <span class="position">{{getposition(constructor.position)}}</span>
                            <span class="picture">
                                <img *ngIf="constructor.constructors.constructorPic!=undefined;else defaultImg" src={{constructor.constructors.constructorPic}}
                                    alt="driver" />
                                <ng-template #defaultImg><img src="../../../assets/img/1.jpg" alt="driver" /></ng-template>
                            </span>
                            <span class="name">{{constructor.constructors.name}}</span>
                        </div>
                    </li>
                </ul>
                <ul class="list-unstyled race-entry-list d-block d-lg-none">
                    <li class="border-left">
                        <div class="race-entry-ranking lap">
                            <div class="position">Fastest Lap</div>
                            <div>
                                <span class="picture">
                                    <img src={{fastestLapDriver.driverPic}} alt="driver" />
                                </span>
                                <span class="name">{{fastestLapDriver.foreName+' '+fastestLapDriver.surName}}</span></div>
                        </div>
                    </li>
                    <li>
                        <div class="race-entry-ranking pole">
                            <div class="position">Pole Position</div>
                            <div><span class="picture">
                                    <img src={{polePositionDriver.driverPic}} alt="driver" />
                                </span>
                                <span class="name">{{polePositionDriver.foreName+' '+polePositionDriver.surName}}</span></div>
                        </div>
                    </li>
                    <li>
                        <div class="race-entry-ranking place">
                            <div class="position">Most Places Gained</div>
                            <div><span class="picture">
                                    <img src={{mostPlacesGainedDriver.driverPic}} alt="driver" />
                                </span>
                                <span class="name">{{mostPlacesGainedDriver.foreName+'
                                    '+mostPlacesGainedDriver.surName}}</span></div>
                        </div>
                    </li>
                </ul>
                 
                
                <ul class="list-unstyled race-entry-list mb-3 Sprint-Entry-list" style="display: flex;
                justify-content: start !important; gap: 40px;" *ngIf="raceEntrySprintTrx.length > 0">
                    <li style="margin: 0;">
                        <h5>Sprint Entry</h5>
                    </li>
                    <li *ngFor="let receEntyDriv of raceEntrySprintTrx;let i=index" style="padding-right: 2rem;">
                        <div class="race-entry-ranking">
                            <span class="position">{{getposition(receEntyDriv.position)}}</span>
                            <span class="picture">
                                <img *ngIf="receEntyDriv.drivers.driverPic!=undefined;else defaultImg" src={{receEntyDriv.drivers.driverPic}}
                                    alt="driver" />
                                <ng-template #defaultImg>
                                    <img src="../../../assets/img/1.jpg" alt="driver" />
                                </ng-template>
                            </span>
                            <span class="name">{{receEntyDriv.drivers.foreName}} {{receEntyDriv.drivers.surName}}</span>
                        </div>
                    </li>
                </ul>

                
                
                <div class="list-unstyled race-entry-list mb-3">
                    <div class="col-md-5">
                        <div class="last-update"><span>Last Updated On:</span> {{raceEntryObj.updatedOnUI|date:'medium'}}</div>
                    </div>
                    <div class="col-md-7 text-md-right" *ngIf="raceEntryObj.challenge!=null">
                        <div class="last-update user-name-pic justify-content-md-end"><span>Challenged :</span>
                            <div class="user-name-pic flex-column"> <span class="user-pic m-0">
                                <img src={{raceEntryObj.challenge.challengedUserProfile}} alt="user" />
                            </span>{{raceEntryObj.challenge.challengedUserName}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-loader" *ngIf="PreLoader">
            <img src="assets/img/progress.svg" alt="Progress">
        </div>
    </div>
</div>