import { Component } from '@angular/core';
import { GenericAPIService } from './services/generic-api.service';
import { UrlConstants } from './constants/url-constants';
import { Router, RouterLink } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { NotificationConstants } from './constants/notification-constants';
import { DataSharingService } from './services/data-sharing.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public logoutUrl = UrlConstants.logoutUrl;
  title = 'F1';
  preLoader = false;
  sessionExpireNotification = '';
  successMessage='';
  showNotification = false;
  showSuccessNotification = false;
  showSuccessFlagNotification = false;
  flagGifUrl='../../../../assets/img/flag.gif'
  sGifUrl='../../../../assets/img/success.gif';
  successGif;
  failureMessage='';
  showFailureNotification = false;
  fGifUrl='../../../../assets/img/failure.gif';
  failureGif;
  slideInRight;
  slideOutRight;
  successFlagMessage: string='';
  successFlagGif: string;
  constructor(public _genericService: GenericAPIService, public router: Router, public dataSharingService: DataSharingService) {
    this.dataSharingService.notificationObs.subscribe(data => {
      this.slideInRight=true
      this.sessionExpireNotification = data;
      if (this.sessionExpireNotification != '') {
        this.showNotification = true;
        setTimeout(() => {
          this.slideOutRight=true;
          this.slideInRight=false;
        },4000)
        setTimeout(() => {
          this.slideOutRight=false;
          this.slideInRight=true;
          this.successMessage = '';
          this.showNotification = false;
        }, 8000);
      }
      else {
        this.showNotification = false;
      }
    }, error => {
    }
    )

    this.getSuccessMessage();
    this.getSuccessFlagMessage();
    this.getFailureMessage();
  }


  ngOnInit() {


  }

  getSuccessMessage(){
    this.dataSharingService.successNotificationObs.subscribe(data => {
      this.successMessage = data;
      this.showSuccessNotification = true;
      if (this.successMessage != '') {
        this.showSuccessNotification = true;
        this.successGif= NotificationConstants.getLinkPicture(this.sGifUrl);
        setTimeout(() => {
          this.slideOutRight=true;
          this.slideInRight=false;
        },3000)
        setTimeout(() => {
          this.slideOutRight=false;
          this.slideInRight=true;
          this.successMessage = '';
          this.showSuccessNotification = false;
        }, 6000);
      }
      else {
        this.showSuccessNotification = false;
      }
    }, error => {
    }
    )
  }
  
  getSuccessFlagMessage(){
    this.dataSharingService.successFlagNotificationObs.subscribe(data => {
      this.successFlagMessage = data;
      this.showSuccessFlagNotification = true;
      if (this.successFlagMessage != '') {
        this.showSuccessFlagNotification = true;
        this.successFlagGif= NotificationConstants.getLinkPicture(this.flagGifUrl);
        setTimeout(() => {
          this.slideOutRight=true;
          this.slideInRight=false;
        },3000)
        setTimeout(() => {
          this.slideOutRight=false;
          this.slideInRight=true;
          this.successFlagMessage = '';
          this.showSuccessFlagNotification = false;
        }, 6000);
      }
      else {
        this.showSuccessFlagNotification = false;
      }
    }, error => {
    }
    )
  }

  getFailureMessage(){
    this.dataSharingService.failureNotificationObs.subscribe(data => {
      this.failureMessage = data;
      this.showFailureNotification = true;
      if (this.failureMessage != '') {
        this.showFailureNotification = true;
        this.failureGif= NotificationConstants.getLinkPicture(this.fGifUrl);
        setTimeout(() => {
          this.slideOutRight=true;
          this.slideInRight=false;
        },3000)
        setTimeout(() => {
          this.slideOutRight=false;
          this.slideInRight=true;
          this.failureMessage = '';
          this.showFailureNotification = false;
        }, 6000);
      }
      else {
        this.showFailureNotification = false;
      }
    }, error => {
    }
    )
  }

  // ngAfterViewInit() {

  //   this.setupBeforeUnloadListener();
  // }
  
  // Setup the `beforeunload` event listener
  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      // return this.logout();
    });
  };

 

  logout() {
    this.preLoader = true;
    this._genericService.post(this.logoutUrl, "").subscribe(response => {
      if (response.status.success == "Success") {
        this.preLoader = false;
        sessionStorage.clear();
        window.location.reload();
        this.router.navigate(['signIn']);
      }
      else {
        this.preLoader = false;
        sessionStorage.clear();
        this.router.navigate(['signIn']);
      }
    },
      error => {
        this.preLoader = false;
      });

    setTimeout(() => {
      this.sessionExpireNotification = undefined
    }, 4000);
  }
}
