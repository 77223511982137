import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TeamManagementRoutingModule } from './team-management-routing.module';
import { TeamManagementListComponent } from './team-management-list/team-management-list.component';

@NgModule({
  declarations: [TeamManagementListComponent],
  imports: [
    CommonModule,
    FormsModule,
    TeamManagementRoutingModule,
    ReactiveFormsModule
  ]
})
export class TeamManagementModule { }
