<div class="card shadow-sm rounded view-race-prediction">
   <h4 class="card-title bg-rose">Twitter Feed</h4>
   <div _ngcontent-c2="" class="card-body">
      <a class="twitter-timeline" href="https://twitter.com/F1?ref_src=twsrc%5Etfw" data-link-color="#3EB662" data-chrome="transparent noheader nofooter" data-theme="dark"></a>
   </div>
   <!-- <a *ngIf="router.url == '/user/dashboard'" class="more btn btn-outline-dark" [routerLink]="'/user/twitterFeed'"><i
         class="fa fa-expand" aria-hidden="true"></i></a>
   <a *ngIf="router.url == '/user/twitterFeed'" class="more btn btn-outline-dark" [routerLink]="'/user/dashboard'"><i
         class="fa fa-compress" aria-hidden="true"></i></a> -->
</div>
<!-- <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet">

<div class="row">
   <div class="col-md-4"></div>
   <div class="container">
      <div class="col-md-4">
         <div class="panel panel-danger">
            <div class="panel-heading">
               <h3 class="panel-title"><i class="fa fa-twitter-square" aria-hidden="true"></i>
                  Solodev Live
               </h3>
            </div>
            <div class="panel-body">
               <a class="twitter-timeline" data-width="100%" href="https://twitter.com/solodev">Tweets by solodev</a> 
            </div>
         </div>
      </div>
   </div>
   <div class="col-md-4">
   </div>
</div>
<script async src="//platform.twitter.com/widgets.js" charset="utf-8"></script> -->