import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RaceConstructorComponent } from './race-constructor/race-constructor.component';
import { RaceConstructorRoutingModule } from './race-constructor.routing.module';

@NgModule({
  declarations: [RaceConstructorComponent],
  imports: [
    CommonModule,
    RaceConstructorRoutingModule
  ]
})
export class RaceConstructorModule { }
