<!-- Alert Messages -->
<div *ngIf="failureMsg!=undefined||failureMsg!=null" [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"class="alert alert-icon alert-danger fade show animated faster"
  role="alert">
  <div class="gif-icon">
      <img src={{failureGif}} alt="success-icon"  />
    </div>
  {{failureMsg}}
</div> 

<div class="card shadow-sm rounded view-player">
  <div class="card-title bg-blue">
    <h4>Drivers</h4>
    <ul class="card-title-details float-right">
      <li><a class="btn btn-outline-primary" data-target="#addDriver" (click)="resetFields()" data-toggle="modal"
          href="javascript:;">Add
          Driver </a></li>
    </ul>
  </div>
  <div class="card-body">   
    <!-- Search Box -->
    <form>
      <div class="form-group search-box">
        <div class="input-group">
            <input type="text" class="form-control" placeholder="search" (input)="searchDriver($event)" >
          <div class="input-group-append">
            <span class="input-group-text" id="inputGroupPrepend"><i class="fa fa-search"></i></span>
          </div>
        </div>
      </div>
    </form>
    <div class="table-responsive">
      <table class="table table-dark table-hover">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Driver</th>
            <th class="action text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let driver of driverList;let i =index">
            <td scope="row"> {{i+1}}</td>
            <td class="user-name-pic"><span class="user-pic" [ngStyle]="{backgroundImage: 'url('+driver.driverPic+')'}">
              </span> {{driver.foreName+" "+driver.surName}} </td>
            <td class="text-center action">
              <div>
                <a class="btn btn-outline-secondary" href="javascript:;" data-target="#addDriver"
                  (click)="getEditDriverDetails(driver);isEdit=true"><i class="fa fa-edit"></i></a><a
                  class="btn btn-outline-secondary" data-target="#delete" data-toggle="modal" (click)="deleteObj=driver"
                  href="javascript:;"><i class="fa fa-trash-o"></i></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="noRecord==true" class="row align-items-center  text-center">
      <div class="col-lg-12">
        <p>No records found</p>
      </div>
    </div>
   
  </div>
  <div class="box-loader" *ngIf="preloader">
    <img src="assets/img/progress.svg" alt="Progress">
  </div>
</div>

<!-- Add driver -->
<div class="modal fade add-user" id="addDriver" tabindex="-1" role="dialog" aria-labelledby="addUserTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{isEdit==false?'Add ':'Edit '}}Driver</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-8">
            <form [formGroup]="driverForm">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">First Name<span class="star-icon">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Enter first name" formControlName="foreName"
                    id="foreName" required maxlength="15">
                  <small style="color:red" *ngIf="driverForm.controls['foreName'].errors?.pattern;else invalidName">
                    Enter
                    valid first name without spaces at start and end
                  </small>
                  <ng-template #invalidName>
                    <small
                      *ngIf="driverForm.controls['foreName'].errors?.required && !driverForm.controls['foreName'].pristine"
                      class="form-text" style="color:red">Enter
                      first name</small>
                  </ng-template>
                </div>
              </div>
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Last Name<span class="star-icon">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Enter last name" formControlName="surName"
                    id="surName" required maxlength="15">
                  <small style="color:red" *ngIf="driverForm.controls['surName'].errors?.pattern;else invalidName2">
                    Enter
                    valid last name without spaces at start and end
                  </small>
                  <ng-template #invalidName2>
                    <small *ngIf="driverForm.controls['surName'].errors && !driverForm.controls['surName'].pristine"
                      class="form-text" style="color:red">Enter
                      last name</small>
                  </ng-template>

                </div>
              </div>
              <div class="form-group row">
                <label for="joinedDate" class="col-sm-4 col-form-label">Join Date<span class="star-icon">*</span></label>
                <div class="col-sm-8">
                    <my-date-picker name="mydate" id="joinedDate" [options]="myDatePickerOptions" formControlName="joinedDate" required></my-date-picker>
                    <small *ngIf="driverForm.controls['joinedDate'].errors && !driverForm.controls['joinedDate'].pristine" class="form-text text-muted">Joined Date is required</small>
                </div>
            </div>
            <div class="form-group row">
              <label for="constructorJoinedDate" class="col-sm-4 col-form-label">Constructor Join Date<span class="star-icon">*</span></label>
              <div class="col-sm-8">
                  <my-date-picker name="mydate" id="constructorJoinedDate" [options]="myDatePickerOptions" formControlName="constructorJoinedDate" required></my-date-picker>
                  <small *ngIf="driverForm.controls['constructorJoinedDate'].errors && !driverForm.controls['constructorJoinedDate'].pristine" class="form-text text-muted">Constructor Joined Date is required</small>
              </div>
          </div>

            </form>

          </div>
          <div class="col-lg-4">
            <div class="user-profile-pic">
              <div class="pic">
                <div class="pic" *ngIf="uploadDriverPic==true" [ngStyle]="{backgroundImage: 'url(' + driverSrc+ ')'}">
                </div>
                <img *ngIf="uploadDriverPic==false" src="../../../../assets/img/default.jpg" class="img-fluid" />
                <div class="upload">
                  <i class="fa fa-camera"> </i>
                  <span> Upload Image
                    <!-- <small>160 x 160</small> -->
                  </span>
                  <input type='file' (change)="readURLForUser($event);" />
                </div>
              </div>
              <label>Driver Picture<span class="star-icon">*</span></label>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="driverForm.invalid"
          (click)="payloadFormation()">save</button>
      </div>
      <div class="box-loader" *ngIf="preloader">
        <img src="assets/img/progress.svg" alt="Progress">
      </div>
    </div>
  </div>
</div>



<!-- delete driver popup -->
<div class="modal fade add-user delete-user" id="delete" tabindex="-1" role="dialog" aria-labelledby="delete"
  aria-hidden="true">
  <div *ngIf="!!deleteObj" class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Delete Driver</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete {{deleteObj.foreName+" "+deleteObj.surName}} ?
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-primary" (click)="cancelDelete()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="delete()">Confirm</button>
      </div>
      <div class="box-loader" *ngIf="preloader">
        <img src="assets/img/progress.svg" alt="Progress">
      </div>
    </div>
  </div>
</div>