
<div  class="row h-100 m-0">
  <div class="col-xl-6 col-lg-12">
    <div id="race-entry" class="card shadow-sm rounded race-entry race-entry-page race-constructor pb-0">
      <div class="card-title bg-blue">
        <h4  class="m-0">Constructors In Race</h4>
      </div>
      <div class="card-body p-0" *ngIf="preLoader!=true">
        <div class="row race-entry-details h-100 m-0">
          <div class="col-lg-12 p-2">
            <div class="row m-0 h-100">
            
              <div class="col-lg-12" id="myDIV">
                <h5 class="mb-3" *ngIf="circuitDetail!=undefined">{{circuitDetail.name}}
                  {{circuitDetail.location!=null?','+circuitDetail.location:''}}{{circuitDetail.country!=null?','+circuitDetail.country:''}}</h5>
                  <ul class="list-unstyled race-entry-list flex-wrap">
                    <!-- <li>
                      <h5>Constructors</h5>
                    </li> -->
                    <li *ngFor="let constructorobj of raceConstrctors; let i=index">
                      <!-- if edit btn not available -->
                      <div class="race-entry-ranking" *ngIf="showEditButton==false">
                        <div class="ranking">
                          
                          <span class="picture">
                            <img src={{constructorobj.constructorPic}} alt="driver" />
                          </span>
                          <span class="name">{{constructorobj.name}}</span>
                          
                        </div>
                      </div>
                  
                      <!-- if  edit btn available -->
                      <div class="race-entry-ranking" *ngIf="showEditButton==true">
                        <div class="ranking" (click)="addConstructor(i);addscss()">
                        
                          <span class="picture">
                            <i  *ngIf="constructorobj.constructorId!=0" class="fa fa-times pull-right cross" aria-hidden="true"
                              (click)="removeFromConstructorList(constructorobj);;addscss()"></i>
                            <img *ngIf="constructorobj.constructorId!=0" src={{constructorobj.constructorPic}} alt="driver" />
                            <i *ngIf="constructorobj.constructorId==0"  class="fa fa-plus"></i>
                          </span>
                          <span class="name">{{constructorobj.name}}</span>
                         
                        </div>
                      </div>
                    </li>
                  </ul>
              
                <div class="text-center">
                  <button type="submit"   class="btn btn-outline-primary mt-4 mb-2 mx-1 mx-sm-2"
                    [routerLink]="'/admin/raceResult'">Cancel </button>
                  <button type="submit" *ngIf="showEditButton==true"   [disabled]="enableSaveButton()" class="btn btn-primary mt-4 mb-2 mx-1 mx-sm-2"
                    (click)="saveConstructors()">save </button>
                </div>

              </div>
            </div>
            
          </div>
        </div>
        
       
      </div>
      <div class="box-loader" *ngIf="preLoader">
        <img src="assets/img/progress.svg" alt="Progress">
      </div>
    </div>
  </div>
  <!-- //For master constructors -->
  <div class="col-xl-6 col-lg-12">
    <div id="race-entry" class="card shadow-sm rounded race-entry race-entry-page race-constructor pb-0">
      <div class="card-title bg-blue">
        <h4  class="m-0">Constructors</h4>
      </div>
      <div class="card-body p-0">
        <div class="row race-entry-details h-100 m-0">
          <div class="col-lg-12 p-2">
            <div class="row m-0 h-100">
            
              <div class="col-lg-12" id="myDIV">
             
                  <ul class="list-unstyled race-entry-list flex-wrap">
                    <!-- <li>
                      <h5>Constructors</h5>
                    </li> -->
                    <li *ngFor="let constructorobj of masterConstructors; let i=index">
                      <!-- if edit btn not available -->
                      <div class="race-entry-ranking" *ngIf="showEditButton==false">
                        <div class="ranking">
                          
                          <span class="picture">
                            <img src={{constructorobj.constructorPic}} alt="driver" />
                          </span>
                          <span class="name">{{constructorobj.name}}</span>
                          
                        </div>
                      </div>
                  
                      <!-- if  edit btn available -->
                      <div class="race-entry-ranking" *ngIf="showEditButton==true">
                        <div class="ranking" (click)="addMaster(constructorobj)" >
                        
                          <span class="picture">
                            <img  src={{constructorobj.constructorPic}} alt="driver"/>
                          </span>
                          <span class="name">{{constructorobj.name}}</span>
                         
                        </div>
                      </div>
                    </li>
                  </ul>
              
               

              </div>
            </div>
            
          </div>
        </div>
        
       
      </div>
      <div class="box-loader" *ngIf="preLoader">
        <img src="assets/img/progress.svg" alt="Progress">
      </div>
    </div>
  </div>
 

  </div>
