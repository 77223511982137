import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RaceDriverComponent } from './race-driver/race-driver.component';
import { RaceDriverRoutingModule } from './race-driver.routing.module';

@NgModule({
  declarations: [RaceDriverComponent],
  imports: [
    CommonModule,
    RaceDriverRoutingModule
  ]
})
export class RaceDriverModule { }
