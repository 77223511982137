import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public ShowCompressPlayerLadder: boolean = false;
  public ShowCompressRacePrediction: boolean = false;
  public ShowCompressTeamLeader: boolean = false;
  public ShowExpandPlayerLadder = true;
  public ShowExpandTeamLeader = true;
  public ShowExpandRacePrediction = true;
  public expandRaceEntry: boolean = false;
  
  playerLadderBoardClass: {};
  OutterClass: {};
  RaceEntryClass = false;
  InnerClass: {};
  teamLeaderClass: {};
  racePredictionClass = {};
  userProfileId = 0;
  expandPlayer: boolean = false;
  expandTeam: boolean = true;

  constructor() { }
  ngOnInit() {

  }


  // Expand and compress player leaderboard
  expandplb() {
    this.expandPlayer = true;
    $("#playerLeaderBoard").addClass('col-xl-12');
    $("#raceEntry, #teamLeaderBoard, #twitterFeed").addClass('d-none');
    $(".expand-btn").addClass('d-none');
    $(".compress-btn").removeClass('d-none');
  }
  compressplb() {
    this.expandPlayer = false;
    $("#playerLeaderBoard").removeClass('col-xl-12');
    $("#raceEntry, #teamLeaderBoard, #twitterFeed").removeClass('d-none');
    $(".expand-btn").removeClass('d-none');
    $(".compress-btn").addClass('d-none');
  }

  // Expand and compress team challenge leaderboard
  expandtclb() {
    $("#teamLeaderBoard").addClass('ani col-xl-12');
    $("#playerLeaderBoard,#raceEntry,#twitterFeed").addClass('d-none');
    $(".expand-btn").addClass('d-none');
    $(".compress-btn").removeClass('d-none');
  }
  compresstclb() {
    $("#teamLeaderBoard").removeClass('ani col-xl-12');
    $("#playerLeaderBoard,#raceEntry,#twitterFeed").removeClass('d-none');
    $(".expand-btn").removeClass('d-none');
    $(".compress-btn").addClass('d-none');
  }

  // Expand and compress team challenge leaderboard
  expandtf() {
    $("#twitterFeed").addClass('ani col-xl-12');
    $("#playerLeaderBoard,#raceEntry,#teamLeaderBoard").addClass('d-none');
    $(".expand-btn").addClass('d-none');
    $(".compress-btn").removeClass('d-none');
  }
  compresstf() {
    $("#twitterFeed").removeClass('ani col-xl-12');
    $("#playerLeaderBoard,#raceEntry,#teamLeaderBoard").removeClass('d-none');
    $(".expand-btn").removeClass('d-none');
    $(".compress-btn").addClass('d-none');
  }

  // Show Single User Detail View
  userIdFromPLB(userId) {
    this.userProfileId = userId;
    // Desktop
    if ($(window).width() > 1200) {
      $(".actual-page-view").css('margin-top', '-' + $('.actual-page-view').height() + 'px');
      $(".actual-page-view").css('bottom', '150px');
      $('.main-container').addClass('overflow-hidden');
      $('.single-user-detail-view').removeClass('d-none');
      setTimeout(function () {
        $('.main-container').removeClass('overflow-hidden');
      }, 300);
    }
    if ($(window).width() < 1200) {
      $('.actual-page-view').addClass('d-none');
      $('.single-user-detail-view').removeClass('d-none');
    }
  }

  // Hide Single User Detail View
  closeSingleUserDetailView() {
    // this.userProfileId=0;
    // Desktop
    if ($(window).width() > 1200) {
      $(".actual-page-view").css('margin-top', '0px');
      $(".actual-page-view").css('bottom', '0px');
      $('.main-container').addClass('overflow-hidden');
      setTimeout(function () {
        $('.single-user-detail-view').addClass('d-none');
        $('.main-container').removeClass('overflow-hidden');
      }, 300);
    }
    if ($(window).width() < 1200) {
      $('.actual-page-view').removeClass('d-none');
      $('.single-user-detail-view').addClass('d-none');
    }

  }

}
