import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileRouting } from './user-profile-routing.module';
import { MyDatePickerModule } from 'mydatepicker';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [UserProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    UserProfileRouting
  ],
  exports:[UserProfileComponent]
})
export class UserProfileModule { }
