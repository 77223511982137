import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EncryptDecryptService } from './encrypt-decrypt.service';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard implements CanActivate {
    constructor(public router: Router,public encryptDecryptService:EncryptDecryptService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let token = sessionStorage.getItem('token')
        var isPrincipal = this.encryptDecryptService.getLocalStorage('isPrincipal');
        if (token!=""&& token!=undefined && (isPrincipal == true)) {
            return true;
        }
        
        return false;
    }
}