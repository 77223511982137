import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RulesEngineComponent } from './rules-engine/rules-engine.component';

const routes: Routes = [
  {
    path:'rulesEngine',
    component:RulesEngineComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RulesEngineRoutingModule { }
