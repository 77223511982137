
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class UrlConstants {

    //sessionStorage keys......


    //circuitName
    // previousRaceId
    // raceBdClosingDateTime
    // raceBdStartingDateTime
    // teamName
    // token
    // upcomingRaceId
    // userId
    // userName
   // isPrincipal

    //localStorage keys......
  // todayDate
    // email
    // password
    // rememberMe
   // upcomingRaceRound


    //common..
    static loginUrl =
        "/user/login";
    static logoutUrl = "/user/logout";
    static forgetPasswordUrl = "/resetPassword";
    static setPasswordUrl = "/setPassword";
    static validateResetToken = '/validateResetPasswordToken';
    static latestRaceDetail = '/latestRaceDetails';
    //raceEntry...
    static raceEntry = '/raceEntry';
    static saveEntry = '/save/raceEntry';
    static previousRaceEntryAndResults = '/previousRace/user'
    //list...
    static driversList = '/drivers/list';
    static constructorsList = '/constructors/list';
    static playerList = '/user/list';
    static teamList = '/team/list';
    //userProfile...
    static getProfiledata = '/get/userProfile';
    static updateProfile = '/save/userProfile';
    static changePassword = '/changePassword';
    //rules and regulation...
    static saveRulesAndRegulation = '/save/rulesAndRegulation';
    static getRulesAndRegulation = '/get/rulesAndRegulation'
    //challenges...........
    static ladderHistoryUrl = '/get/ladderChallenge';
    static ladderChallengeForRace='/get/all/ladderChallenge/Race';
    static saveChallenge = '/save/challenge';
    static getPreChallengeInfo = '/get/challengeDetail';
    static raceInfo = '/getRaceInformation';
    static getSeletedDervierListUrl = '/sprint_entry/sprintEntry';
    static playerLadderUrl = '/challenge/userList';
    static pointDetails = '/pointDetails';
    static challengeByurl='/get/challengeBy';
    static revokeChallengeUrl='/revoke/challenge'
    //static previousRace='previousRace/list';
    static reportError = '/report/error';
    //race prediction
    static currentYearRaceInfo = '/races/currentYear';
    static racePredictions="/racePredictions"
    static getAllpreviousRaceList = '/previousRace/list';
    //admin
    static addUser='/save/user';
    static allUsers='/user/all';
    static deleteUser='/delete/user';
    static teamListAndPlayer='/team/all';
    static addTeam='/save/team';
    static addTeamWithuser='/save/team/with/users';
    static getCircuitList = "/circuit/list";
    static saveRace  = "/saveRace";
    static saveRaceSchedule ="/save/raceSchedule";
    static updateCircuit = '/updateCircuit';
    static getAllUsersWithPaymentInfo = '/all/usersWithPaymentInfo';

    //race result
    static getRaceResult='/get/raceResult/year';
    static raceResultById='/get/raceResult';
    static saveRaceResult = '/save/raceResult';
    static saveSprintEntryRaceResultUrl = '/sprint_entry/save/sprintEntry';
    
    static raceConstructors='/constructors/list';
    static saveConstructorTrx='/save/constructors/trx'
   
    static raceDrivers='/drivers/list';
    static saveDriverTrx='/save/driver/trx'
    //rules engine
    static allRulesUrl='/rulesEngine/list';
    static saveRuleUrl='/save/rules/engine';

    //race reult entry
    static racesUrl='/races/info/currentYear'

    //team admin

    static userswithOutTeamUrl='/getAll/users'

    //individual user result
    static userDetailUrl='/user/detail';
    
    //master data
    static saveConstructorMD='/save/constructor/master';
    static constructorMDList= '/constructor/master/list';
    static deleteConstructorMD='/delete/constructor/master';
    static driverMDList= '/driver/master/list';
    static saveDriverMD='/save/driver/master';
    static deleteDriverMD='/delete/driver/master';

    static circuitMDList= '/circuit/list';
    static savecircuitMD= '/save/circuit/trx';
    static deleteCircuitMD='/delete/circuit';

    //Razor Pay...
    static fetchOrderId="/generateOrderId";;
    static verySignature="/verifySignSaveTransaction";
    static getSubcriptionDetails = '/subscriptionDetail/get';
    static saveSubscriptionDetails ='/subscriptionDetail/save'

    static manulatPaymentUpdate = '/savePayment'
}

