<div id="race-entry-accordion" class="race-entry-accordion mt-4">
  <!-- accordion  -->
  <div class="card mb-4" *ngFor="let yourEntry of yourEntryArray;let i=index">
    <div class="card-header bg-black collapsed" data-toggle="collapse" [attr.data-target]="'#collapse' + yourEntry.f1RaceEntryId"
    [attr.aria-controls]="'#collapse' + yourEntry.f1RaceEntryId" 
    *ngIf="yourEntry.norecord==false;else norecord"  >
        <h4 class="d-inline-block mb-0">
            <div class="user-name-pic"><span class="user-pic"><img src={{yourEntry.race.circuits.circuitActive}}
              class="img-fluid" /></span>
              <span class="circuit-name">{{yourEntry.race.circuits.name}} - {{yourEntry.race.circuits.circuitRef}}, {{yourEntry.race.circuits.location}}, {{yourEntry.race.circuits.country}}</span>
        </div>
          </h4>
        <div class="points-info">
          <!-- For Desktop -->
          <div class="total-points d-none d-sm-block">Rank: <span>{{yourEntry.rank}}</span></div>
          <div class="total-points d-none d-sm-block">Total Points Gained/Lost : <span>{{yourEntry.totalPoints}}</span></div>
          <!-- For Mobile -->
          <div class="total-points d-block d-sm-none">Rank <span>{{yourEntry.rank}}</span></div>
          <div class="total-points d-block d-sm-none">Total Points Gained/Lost <span>{{yourEntry.totalPoints}}</span></div>
          <div class="points-details">Points Details</div>
          <!-- <div class="points-details" (click)="getPointsDetail(yourEntry.f1RaceEntryId,yourEntry.race.raceId)" data-toggle="modal" data-target="#pointsDetails">Points Details</div> -->
        </div>
        <i class="fa fa-caret-down"></i>
      </div>
      <ng-template #norecord>
        <div class="card-header bg-black collapsed" >
            <h4 class="d-inline-block mb-0">
                <div class="user-name-pic"><span class="user-pic"><img src={{yourEntry.race.circuits.circuitActive}}
                  class="img-fluid" /></span>
                  <span class="circuit-name">{{yourEntry.race.circuits.name}} - {{yourEntry.race.circuits.circuitRef}}, {{yourEntry.race.circuits.location}}, {{yourEntry.race.circuits.country}}</span>
            </div>
              </h4>
            <div class="points-info">
                <div class="total-points d-none d-sm-block">Rank: <span>{{yourEntry.rank}}</span></div>
                <div class="total-points"><span>Not given any entries</span></div>
              <!-- <div class="points-details" (click)="getPointsDetail(yourEntry.f1RaceEntryId,yourEntry.race.raceId)" data-toggle="modal" data-target="#pointsDetails">Points Details</div> -->
            </div>
          </div>
      </ng-template>
      <div [id]="'collapse' + yourEntry.f1RaceEntryId" class="collapse" aria-labelledby="headingTwo" data-parent="#race-entry-accordion" *ngIf="raceResultArray[i]!=undefined && yourEntry.norecord==false">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                  <table class="table table-dark">
                    <thead>
                       <tr>
                        <th class="head">Drivers</th>
                        <th>Your Entry</th>
                        <th>Race Result</th>
                        <th>Points</th>
                        <th>Against the Grain</th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let entryDriver of yourEntry.raceEntryDriverTRX;let j=index">
                        <td>{{entryDriver.position}}</td>
                        <td>
                          <div class="user-name-pic">
                            <span class="user-pic">
                                <img *ngIf="entryDriver.drivers.driverPic!=undefined || entryDriver.drivers.driverPic!=null"
                                src={{entryDriver.drivers.driverPic}} alt="driver" />
                            </span>{{entryDriver.drivers.foreName+" "+entryDriver.drivers.surName}}</div>
                        </td>
                        <td>
                          <div class="user-name-pic">
                            <span class="user-pic">
                                 <img *ngIf="raceResultArray[i].f1RaceResultDriverTrx[j].drivers.driverPic!=undefined || raceResultArray[i].f1RaceResultDriverTrx[j].drivers.driverPic!=null"
                                src={{raceResultArray[i].f1RaceResultDriverTrx[j].drivers.driverPic}} alt="driver" />
                              </span>{{raceResultArray[i].f1RaceResultDriverTrx[j].drivers.foreName+" "+raceResultArray[i].f1RaceResultDriverTrx[j].drivers.surName}}</div>
                        </td>
                        <td>{{entryDriver.points!=null?entryDriver.points:0}}</td>
                        <td>{{entryDriver.grainPoints!=null?entryDriver.grainPoints:0}}</td>
                      </tr>
                      <tr class="total-points">
                          <td colspan="4" class="text-left py-4">
                            <!-- No of Drivers in Top 6 predicted 5: -->
                            Random drivers prediction out of top 6 drivers:
                          </td>
                          <td>{{yourEntry.driversRandomPoints!=null?yourEntry.driversRandomPoints:'0'}}</td>
                        </tr>
                  </tbody>
                </table>
                </div>
              </div>
            </div>
            <!-- Pole Position -->
            <div class="card">
              <div class="card-body">
                  <div class="table-responsive">
                <table class="table table-dark">
                  <thead>
                    <tr>
                      <th class="head">Pole Position</th>
                      <th>Your Entry</th>
                      <th>Race Result</th>
                      <th>Points</th>
                      <th>Against the Grain</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td></td>
                          <td>
                            <div class="user-name-pic"><span class="user-pic">
                                <img *ngIf="yourEntry.raceEntryLapDetail.polePositionDriver.driverPic!=undefined || yourEntry.raceEntryLapDetail.polePositionDriver.driverPic!=null"
                              src={{yourEntry.raceEntryLapDetail.polePositionDriver.driverPic}} alt="driver" />
                                </span>{{yourEntry.raceEntryLapDetail.polePositionDriver.foreName +" "+yourEntry.raceEntryLapDetail.polePositionDriver.surName}}</div>
                          </td>
                          <td>
                            <div class="user-name-pic">
                              <span class="user-pic">
                                  <img *ngIf="raceResultArray[i].f1RaceResultLapDetail.polePositionDriver.driverPic!=undefined || raceResultArray[i].f1RaceResultLapDetail.polePositionDriver.driverPic!=null"
                                  src={{raceResultArray[i].f1RaceResultLapDetail.polePositionDriver.driverPic}} alt="driver" />    
                                </span>{{raceResultArray[i].f1RaceResultLapDetail.polePositionDriver.foreName +" "+raceResultArray[i].f1RaceResultLapDetail.polePositionDriver.surName}}</div>
                          </td>
                          <td>{{yourEntry.raceEntryLapDetail.polePositionPoints!=null?yourEntry.raceEntryLapDetail.polePositionPoints:0}}</td>
                          <td>{{yourEntry.raceEntryLapDetail.poleGrainPoints!=null?yourEntry.raceEntryLapDetail.poleGrainPoints:0}}</td>
                        </tr>
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12">
            <!-- Constructors -->
            <div class="card">
              <div class="card-body">
                  <div class="table-responsive">
                <table class="table table-dark">
                  <thead>
                    <tr>
                      <th class="head">Constructors</th>
                      <th>Your Entry</th>
                      <th>Race Result</th>
                      <th>Points</th>
                      <!-- <th>Against the Grain</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let entryConstructor of yourEntry.raceEntryConstructorTRX;let j=index">
                      <td>{{entryConstructor.position}}</td>
                      <td>
                        <div class="user-name-pic">
                          <span class="user-pic">
                              <img *ngIf="entryConstructor.constructors.constructorPic!=undefined || entryConstructor.constructors.constructorPic!=null"
                              src={{entryConstructor.constructors.constructorPic}} alt="driver" />
                          </span>{{entryConstructor.constructors.name}}</div>
                      </td>
                      <td>
                        <div class="user-name-pic">
                          <span class="user-pic">
                              <img *ngIf="raceResultArray[i].f1RaceResultConstructorTrx[j].constructors.constructorPic!=undefined || raceResultArray[i].f1RaceResultConstructorTrx[j].constructors.constructorPic!=null"
                              src={{raceResultArray[i].f1RaceResultConstructorTrx[j].constructors.constructorPic}} alt="driver" />
                            </span> {{raceResultArray[i].f1RaceResultConstructorTrx[j].constructors.name}}</div>
                      </td>
                      <td>{{entryConstructor.points!=null?entryConstructor.points:0}}</td>
                      <!-- <td>5</td> -->
                    </tr>
                    <tr class="total-points">
                        <td colspan="3" class="text-left py-4">
                          <!-- No of Constructors in Top 3 predicted 3: -->
                          Random constructors prediction out of top 3 constructors:
                        </td>
                        <td>
                            {{yourEntry.constructorsRandomPoints!=null?yourEntry.constructorsRandomPoints :'0'}}  </tr>
                  </tbody>

                </table>
                </div>
              </div>
            </div>
            <!-- Constraints -->
            <div class="card">
              <div class="card-body">
                  <div class="table-responsive">
                <table class="table table-dark">
                  <thead>
                    <tr>
                      <th class="head">Constraints</th>
                      <th>Your Entry</th>
                      <th>Race Result</th>
                      <th>Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Fastest Lap</td>
                      <td>
                        <div class="user-name-pic"><span class="user-pic">
                            <img *ngIf="yourEntry.raceEntryLapDetail.fastestLapDriver.driverPic!=undefined || yourEntry.raceEntryLapDetail.fastestLapDriver.driverPic!=null"
                            src={{yourEntry.raceEntryLapDetail.fastestLapDriver.driverPic}} alt="driver" />
                            </span>{{yourEntry.raceEntryLapDetail.fastestLapDriver.foreName +" "+yourEntry.raceEntryLapDetail.fastestLapDriver.surName}}</div>
                      </td>
                      <td>
                        <div class="user-name-pic"><span class="user-pic">
                            <img *ngIf="raceResultArray[i].f1RaceResultLapDetail.fastestLapDriver.driverPic!=undefined || raceResultArray[i].f1RaceResultLapDetail.fastestLapDriver.driverPic!=null"
                            src={{raceResultArray[i].f1RaceResultLapDetail.fastestLapDriver.driverPic}} alt="driver" />     
                            </span>{{raceResultArray[i].f1RaceResultLapDetail.fastestLapDriver.foreName +" "+raceResultArray[i].f1RaceResultLapDetail.fastestLapDriver.surName}}</div>
                      </td>
                      <td>{{yourEntry.raceEntryLapDetail.fastestLapPoints!=null?yourEntry.raceEntryLapDetail.fastestLapPoints:0}}</td>
                    </tr>
                    <tr>
                      <td>Most Places Gained</td>
                      <td>
                        <div class="user-name-pic"><span class="user-pic">
                            <img *ngIf="yourEntry.raceEntryLapDetail.mostPlacesGainedDriver.driverPic!=undefined || yourEntry.raceEntryLapDetail.mostPlacesGainedDriver.driverPic!=null"
                            src={{yourEntry.raceEntryLapDetail.mostPlacesGainedDriver.driverPic}} alt="driver" />                        
                            </span>{{yourEntry.raceEntryLapDetail.mostPlacesGainedDriver.foreName +" "+yourEntry.raceEntryLapDetail.mostPlacesGainedDriver.surName}}</div>
                      </td>
                      <td *ngIf="raceResultArray[i].f1RaceResultMpg.length>0;else noMPG">
                        <div *ngFor="let mpgResult of raceResultArray[i].f1RaceResultMpg"class="user-name-pic">
                          <span class="user-pic">   
                            <img *ngIf="mpgResult.mostPlacesGainedDriver.driverPic!=undefined || mpgResult.mostPlacesGainedDriver.driverPic!=null"
                            src={{mpgResult.mostPlacesGainedDriver.driverPic}} alt="driver" />     
                            </span>{{mpgResult.mostPlacesGainedDriver.foreName +" "+mpgResult.mostPlacesGainedDriver.surName}}
                          </div>
                      </td>
                      <ng-template #noMPG>
                          <td>
                          <div class="user-name-pic">-
                          </div>
                          </td>
                      </ng-template>
                      <td>{{yourEntry.raceEntryLapDetail.mostPlacesGainedPoints==null?0:yourEntry.raceEntryLapDetail.mostPlacesGainedPoints}}</td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
            </div>
            <!-- Challenged -->
            <div class="card">
              <div class="card-body">
                  <div class="table-responsive">
                <table class="table table-dark">
                  <thead>
                    <tr>
                      <th class="head">Ladder Challenge</th>
                      <th>Challenged</th>
                      <th>Result</th>
                      <th>Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="!!yourEntry.challenge">
                      <td></td>
                      <td>
                        <div class="user-name-pic">
                          <span *ngIf="yourEntry.challenge" class="user-pic">
                            <img *ngIf="yourEntry.challenge.challengedUserProfile!=undefined || yourEntry.challenge.challengedUserProfile!=null"
                            src="{{yourEntry.challenge.challengedUserProfile}}" class="img-fluid" />                        
                          </span>{{yourEntry.challenge.challengedUserName}}</div>
                      </td>
                      <td>
                        <span class="text-green">{{yourEntry.challenge.result}}</span>
                      </td>
                      <td>{{yourEntry.challenge.points}}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="row m-0" *ngIf="yourEntry.challenge==null">
                  <div class="col-sm-12 text-center">
                    <p>No Challenges Found</p>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
         
      
        </div>
        <!-- Grand Total -->
        <div class="grand-total text-right m-0">
            <!-- <h3>Total Points Gained/Lost : <span class="text-green">{{yourEntry.totalPoints}}</span></h3> -->
            <!-- <button class="btn "(click)="getReportError(yourEntry.race.raceId)">points detail</button> -->
            <a href="javascript:;" class="btn btn-link w-auto" data-toggle="modal" (click)="setErrorForRace(yourEntry.race.raceId)" data-target="#reportError">Report Error</a>

          </div>
      </div>
    </div>
  </div>
  <div class="box-loader" *ngIf="preLoader && individualUserDetail!=undefined">
    <img src="assets/img/progress.svg" alt="Progress">
  </div>
</div>
 
 
 
<!-- Points Details -->
<div class="modal fade points-details" id="pointsDetails" tabindex="-1" role="dialog" aria-labelledby="pointsDetailsTitle"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Australian Grand Prix - Melbourne Grand Prix Circuit</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <table class="table">
        <thead>
          <tr>
            <th>Criteria</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody *ngIf="!!pointsDetail">
          <tr>
            <td>No of Drivers in Top 6 predicted : </td>
            <td>{{pointsDetail.randomDriverCount}}</td>
          </tr>
          <tr>
            <td>No of Drivers in Top 6 predicted in exact position :</td>
            <td>{{pointsDetail.exactDriverCount}}</td>
          </tr>
          <tr>
            <td>Pole Position Driver</td>
            <td>{{pointsDetail.polePositionDriver}}</td>
          </tr>
          <tr>
            <td>Most Places Gained</td>
            <td>{{pointsDetail.mostPlacesGained}}</td>
          </tr>
          <tr>
            <td>No of Constructor's in Top 3 predicted:</td>
            <td>{{pointsDetail.randomConstructorCount}}</td>
          </tr>
          <tr>
            <td>No of Constructor's in Top 3 predicted in exact standing: </td>
            <td>{{pointsDetail.exactConstructorCount}}</td>
          </tr>
          <tr>
            <td>Ladder Challenge</td>
            <td>{{pointsDetail.ladderChallenge}}</td>
          </tr>
          <tr class="text-right">
            <td>Total</td>
            <td class="text-left">{{pointsDetail.totalPoints}}</td>
          </tr>
        </tbody>
      </table>
      <div class="row align-items-center  text-center" *ngIf="noPoints==true">
        <div class="col-lg-12">
          <p style="color:black">No records found</p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary"(click)="getReportError()">report error</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
    </div>
    <!-- <div class="box-loader" *ngIf="PreLoaderForSave">
      <img src="assets/img/progress.svg" alt="Progress">
    </div> -->
  </div>
</div>
</div>

<!--Report Detail -->
<div class="modal fade add-user delete-user" id="reportError" tabindex="-1" role="dialog" aria-labelledby="reportError">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Report Error</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
            <div class="col-lg-12">
            <div class="form-group row">
                <label for="description" class="col-sm-3 col-form-label">Description <span style="color:red;font-size: 26px;" >*</span></label>               
                <div class="col-sm-9">
                <textarea class="form-control" rows="5" [(ngModel)]="content"id="content"  required></textarea>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary" (click)="reset()">cancel</button>
          <button class="btn btn-primary"[disabled]="content==''||content==undefined"(click)="getReportError()">Report Error</button>         
      </div> 
    </div>
  </div>
</div>