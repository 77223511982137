import { Component, OnInit,Input, SimpleChanges } from '@angular/core';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { NotificationConstants } from 'src/app/constants/notification-constants';
declare var $: any;

@Component({
  selector: 'app-previous-race-result',
  templateUrl: './previous-race-result.component.html',
  styleUrls: ['./previous-race-result.component.scss']
})
export class PreviousRaceResultComponent implements OnInit {

  public pointDetailsUrl=UrlConstants.pointDetails;
  public previousRaceEntryAndResults = UrlConstants.previousRaceEntryAndResults;
  public reportErrorUrl=UrlConstants.reportError;
  public userId;
  public pointsDetail;
  public raceEntrySprintTrx:any=[];
  public yourEntryArray=[];
  public raceResultArray=[];
  public noPoints: boolean=false;
  public raceIdforReportError: any;
  @Input()individualUserDetail;
  public preLoader:boolean=false;
  public raceIdForReportError:number;
  public content:string;
  
  constructor(public _genericService:GenericAPIService,  public encryptDecryptService: EncryptDecryptService) {}
 
  ngOnInit() {
     this.getUserDetail().then (res => this.getPreviousRaceEntriesAndResults()); 
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.getUserDetail().then (res => this.getPreviousRaceEntriesAndResults()); 
  }
  getUserDetail(){
    return new Promise((resolve, reject) => {
      if(this.individualUserDetail==undefined){
        this.userId=this.encryptDecryptService.getLocalStorage('userId');
      }
      else{
        if(this.individualUserDetail.isindividual){
          this.userId=+this.individualUserDetail.userId;
        }
      }
      resolve(this.userId);
      error => {
        reject("No user")
      }
    })
  }
  getPointsDetail(f1raceEntryId,raceIdforReportError) {
    this.raceIdforReportError=raceIdforReportError;
    this._genericService.getAll(this.pointDetailsUrl+'?f1raceEntryId='+f1raceEntryId).subscribe(response => {
      if(response.data==null){
       this.noPoints=true;
      }
      else{
        this.pointsDetail=response.data;
      }
  });  
}
setErrorForRace(raceId){
this.raceIdForReportError=raceId;
}

getPreviousRaceEntriesAndResults() {
  this.yourEntryArray=[]
  this.preLoader=true;
  this._genericService.getAll(this.previousRaceEntryAndResults + '?userId=' + this.userId).subscribe(response => {
  this.raceResultArray = response.data.raceResults;
   var myEntryArray = response.data.raceEntry;

   //this.raceEntrySprintTrx
   //response.data.raceEntry[0].raceEntrySprintTrx
   var  tempArray=[]
   for(let i=0;i<myEntryArray.length;i++){
    tempArray[i]=myEntryArray[i];
    if(tempArray[i].raceEntryDriverTRX.length == 0 && 
      tempArray[i].raceEntryConstructorTRX.length == 0 &&
      tempArray[i].raceEntryLapDetail == null){
      tempArray[i]['norecord']=true;
    }
    else{
      tempArray[i]['norecord']=false;
    }
  }

  this.yourEntryArray = tempArray;
  // for(let i=0;i<this.raceResultArray.length;i++){
  //   var emptyEntryArray={
  //   'norecord':true,
  //   'race':this.raceResultArray[i].race
  // };
  // tempArray.push(emptyEntryArray);
  // }
  // for(let i=0;i<myEntryArray.length;i++){
  //   tempArray[(myEntryArray[i].race.round)-1]=myEntryArray[i];
  //   tempArray[(myEntryArray[i].race.round)-1]['norecord']=false;
  //    }
  //  this.yourEntryArray=tempArray;
   this.preLoader=false;
  }, error => {
    this.preLoader=false;
  })
}
reset(){
  $("#reportError").modal("hide");
  this.raceIdForReportError=undefined;
    this.content=undefined;
  }
getReportError(){
  var payload={
    'content':this.content
  }
  this._genericService.post(this.reportErrorUrl+'?userId='+this.userId+'&raceId='+this.raceIdForReportError,payload).subscribe(response=>{
    if(response.status.success=="Success"){
     this.raceIdForReportError=undefined;
     this.content=undefined;
    $("#reportError").modal("hide");
    }
  },error=>{
  }); 
} 
}
