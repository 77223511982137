import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router'
import { DataSharingService } from './data-sharing.service';
import { NotificationConstants } from '../constants/notification-constants';
import { throwError } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class GenericAPIService {


    public baseUrL = environment.apiUrl;
    constructor(public httpClient: HttpClient, public router: Router,public dataSharingService:DataSharingService) {
    }
    protected standardHeaders() {
        return {
            'Content-Type': 'application/json',
            'sessionToken': sessionStorage.token,
        };
    }
    protected standardHeaders1() {
        return {
            'Accept': "application/json",
            'sessionToken': sessionStorage.token,
        };
    }
    //Token is Invalid
    //Get All
    getAll(url): Observable<any> {
        return this.httpClient.get(this.baseUrL + url, {
            headers: this.standardHeaders()
        }).pipe(
            map((response: any) => {
               
                if (response.status.message == 'Token is Invalid') {
                    this.logout();
                }
                return response;
            }),
            catchError(error => {
                return throwError(error);
            })
        );
    }

    //Get with pagination

    //Get By id


    //Post and Edit
    post(url, payload,showNotification?): Observable<any> {
        return this.httpClient.post(this.baseUrL + url, payload, {
            headers: this.standardHeaders()
        }).pipe(
            map((response: any) => {
                if (response.status.message == 'Token is Invalid'||response.status.message=='Token is invalid') {
                    this.logout();
                }
                if(showNotification!=false){
                    this.notify(url,response);
                }
                
                return response;
            }),
            catchError(error => {
                return throwError(error);
            })
        );
    }


    //Image post.........
    imagePost(url, payload): Observable<any> {
        return this.httpClient.post(this.baseUrL + url, payload, {
            headers: this.standardHeaders1(),
            // params:{params},
        }).pipe(
            map((response: any) => {
                if (response.status.message == 'Token is Invalid') {
                    this.logout();
                }
                this.notify(url,response);

                return response;
            }),
            catchError(error => {
                this.dataSharingService.failureMessage(NotificationConstants.errormsg);
                return throwError(error);
            })
        );
    }

    //Delete

    delete(url): Observable<any> {
        return this.httpClient.delete(this.baseUrL + url, {
            headers: this.standardHeaders()
        }).pipe(
            map((response: any) => {
                if (response.status.message == 'Token is Invalid') {
                    this.logout();
                }
                this.notify(url,response);
                return response;
            }),
            catchError(error => {
                this.dataSharingService.failureMessage(NotificationConstants.errormsg);
                return throwError(error);
            })
        );
    }

    logout() {
       this.dataSharingService.updatenotification(NotificationConstants.sessionInvalidNotification);
        sessionStorage.clear();
        window.location.reload();
        this.router.navigate(['signIn']);
        setTimeout(() => {
            this.dataSharingService.updatenotification('');
          }, 2000);
    }

    notify(url,response){
        url.includes('/resetPassword')==true?url='/resetPassword':url;
        url.includes('/validateResetPasswordToken')==true?url='/validateResetPasswordToken':url;

        if (url == "/user/logout" || url == "/user/login"||url =='/resetPassword'||url=='/validateResetPasswordToken') {
        }
        else if(url == '/save/raceEntry'|| url == '/save/raceResult'){
            if (response.status.success == 'success' || response.status.success == 'Success') {
                this.dataSharingService.successFlagMessage(response.status.message);
            }
            if (response.status.success == 'Fail'||response.status.success == 'fail') {
                this.dataSharingService.failureMessage(response.status.message);
            }
        }
        else{
            if (response.status.success == 'success' || response.status.success == 'Success') {
                this.dataSharingService.successMessage(response.status.message);
            }
            if (response.status.success == 'Fail'||response.status.success == 'fail') {
                this.dataSharingService.failureMessage(response.status.message);
            }
        }

    }

}