import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ladder-board',
  templateUrl: './ladder-board.component.html',
  styleUrls: ['./ladder-board.component.scss']
})
export class LadderBoardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
