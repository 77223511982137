import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LadderComponent } from './ladder/ladder.component';
import { AuthGuard } from '../../services/auth-guard.service';

const routes: Routes = [
  {path:'playerladder',
   component:LadderComponent,
   canActivate:[AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LadderRoutingModule { }
