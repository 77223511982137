import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RulesEngineRoutingModule } from './rules-engine-routing.module';
import { RulesEngineComponent } from './rules-engine/rules-engine.component';
import { RulesEngineValidationDirective } from 'src/app/constants/validation.directive';

@NgModule({
  declarations: [RulesEngineComponent,RulesEngineValidationDirective],
  imports: [
    CommonModule,
    RulesEngineRoutingModule,
    FormsModule
  ]
})
export class RulesEngineModule { }
