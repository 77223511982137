import { Routes, RouterModule } from '@angular/router';
import { ViewPlayerLeaderBoardComponent } from './view-player-leader-board/view-player-leader-board.component';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../../services/auth-guard.service';
import { ViewTeamLeaderBoardComponent } from './view-team-leader-board/view-team-leader-board.component';
import { ViewRacePredictionComponent } from './view-race-prediction/view-race-prediction.component';

export const dashboardRoutes:Routes=[
    {
        path:'',
        component:DashboardComponent,
        canActivate:[AuthGuard],
    },
    {
        path:'playerBoard',
        component:ViewPlayerLeaderBoardComponent,
        canActivate:[AuthGuard],
    },
    {
        path:'teamBoard',
        component:ViewTeamLeaderBoardComponent,
        canActivate:[AuthGuard],
    },
    {
        path:'twitterFeed',
        component:ViewRacePredictionComponent,
        canActivate:[AuthGuard],
    }
]

@NgModule({
    imports: [RouterModule.forChild(dashboardRoutes)],
    exports: [RouterModule]
  })
  export class  DashboardRoutingModule { }
  