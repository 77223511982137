import { Component, OnInit } from '@angular/core';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
@Component({
  selector: 'app-rules-and-regulation',
  templateUrl: './rules-and-regulation.component.html',
  styleUrls: ['./rules-and-regulation.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService]
})
export class RulesAndRegulationComponent implements OnInit {
  public textForm;
  public edit: boolean = false;
  public getRulesResponse: any;
  public saveRulesAndRegulationUrl = UrlConstants.saveRulesAndRegulation;
  public getRulesAndRegulationUrl = UrlConstants.getRulesAndRegulation;
  public value: string;
  PreLoader = false;
  notFound=false;

  constructor(public _genericService: GenericAPIService) { }
  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'LowerCase', 'UpperCase', '|', 'Undo', 'Redo', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink', 'CreateTable',
      'Image', '|', 'ClearFormat', 'Print', 'SourceCode',]
  };
  ngOnInit() {
    this.createForm();
    this.getRulesAndRegulation();
  }
  createForm() {
    this.textForm = new FormGroup({
      text: new FormControl('', [Validators.required])
    })
  }
  getRulesAndRegulation() {
    this.PreLoader = true;
    this._genericService.getAll(this.getRulesAndRegulationUrl).subscribe(response => {
      if (response.status.success == "Success") {
        this.getRulesResponse = response.data;
        this.value = this.getRulesResponse.description;
        this.PreLoader = false;
        this.notFound=false
      }
      else{
        this.notFound=true
        this.PreLoader = false;
      }

    },error=>{
      this.PreLoader = false;
    }
    )
  }
  onSubmit() {
    let reqPayLoad = {
      "description": this.textForm.value.text,
      "year": new Date().getFullYear()
    }
    this._genericService.post(this.saveRulesAndRegulationUrl, reqPayLoad).subscribe(response => {
      if (response.status.success == "Success") {
        this.getRulesAndRegulation();
      }

    })

  }
}
