import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LadderComponent } from './ladder/ladder.component';
import { LadderRoutingModule } from './ladder-routing.module';
import { UserDetailsModule } from '../user-details/user-details.module';

@NgModule({
  declarations: [LadderComponent],
  imports: [
    CommonModule,
    LadderRoutingModule,
    UserDetailsModule
  ]
})
export class LadderModule { }
