import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { interval } from 'rxjs';
import { map, count } from 'rxjs/operators';
declare var $: any;
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { Router, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET, ActivatedRoute } from '@angular/router';
import { NotificationConstants } from 'src/app/constants/notification-constants';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localStorage.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-race-sprint-entry',
  templateUrl: './race-sprint-entry.component.html',
  styleUrls: ['./race-sprint-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RaceSprintEntryComponent implements OnInit {
   
  public latestRaceDetailsUrl = UrlConstants.latestRaceDetail;
  public driverListUrl = UrlConstants.driversList;
  public constructorsListUrl = UrlConstants.constructorsList;
  public saveRaceEntryUrl = UrlConstants.saveEntry;
  public saveSprintEntryRaceResultUrl = UrlConstants.saveSprintEntryRaceResultUrl;
  public saveRaceResultUrl = UrlConstants.saveRaceResult;
  public raceEntryByIdUrl = UrlConstants.raceEntry;
  public raceResultByIdUrl = UrlConstants.raceResultById;

  public raceInfoUrl = UrlConstants.raceInfo;
  public getSeletedDervierListUrl = UrlConstants.getSeletedDervierListUrl;
  public selectedDrivers = [];
  public selectedConstructors = [];
  public selectedFastestLap = {};
  public selectedPolePosition = {};
  public selectedMostPosition = {};
  public selectedMpgArray = [];
  public raceEntryFailureMsg;
  responseDriverList: any;
  responseconstructorList: any;
  public DriverListForFastestLap = [];
  public DriverListForPolePosition = [];
  public DriverListForMostPosition = [];

  public showConstructorList: boolean;
  public showDriverList: boolean;
  public showRaceInfo: boolean ;
  public showDriverListForFastestLap: boolean; 
  public showDriverListForPolePosition: boolean; 
  public showDriverListForMostPosition: boolean;
  public raceInfo;
  public targetPosition;
  public targetMPGPosition;
  public SourceDetail;
  public targetConstructorPosition;
  public SourceConstructorDetail;
  public sourceFastestLap;
  public sourcePolePosition;
  public sourceMostPosition;
  public enableSaveBtn: boolean = false;
  public raceEntryPayload: any = {};
  public raceEntryResultPayload: any = {};
  public existingRaceEntryDetail: any;
  public circuitDetail: any;
  public raceId = 0;
  public showEditButton = false;
  public showViewButton = false;
  public startDateFormat;
  public endDateFormat;
  resultNotYetUpdated: boolean = false;
  public raceInfoObj: any = {
    "Circuits": {
    }
  };
  PreLoader = false;
  PreLoaderForInfo = false;
  PreLoaderForSave = false;

  public startDiff: number;
  public startDays: number;
  public startHours: number;
  public startMinutes: number;
  public startSeconds: number;

  public endDiff: number;
  public endDays: number;
  public endHours: number;
  public endMinutes: number;
  public endSeconds: number;
  classList: any;
  startDate: any;
  endDate: any;
  public circuitSrc = "";
  tempSelectedConstructors: any[];
  tempSelectedDrivers: any[];
  public userId: number;
  upcomingRaceId: any;
  public raceDateFormat;
  public tempRaceDate;
  public adminRaceResult:boolean=false;
  public myRoute;
  public raceResultId;
  public raceResultDetail;
  public raceResultMode;
  public noRace:boolean=false;
  noDriver: string;
  noConstructor: string;
  noFastestLapDriver:string=NotificationConstants.noFastestLapDriverMsg;
  noMostPlaceGained:string=NotificationConstants.noMostplaceGainedDriverMsg;
  noPolePostionDriver:string=NotificationConstants.noPolePostionDriverMsg;
  DriverListForMPG: any=[];
  showAddMPGbtn: boolean=true;
  currentDateTime;
  
  public eventName:any= '';
  public f1SprintEntryId:any= '';
  public initSprintDeriver:any= [];
  
  constructor(public _genericService: GenericAPIService,public activatedRoute: ActivatedRoute,public encryptDecryptLocalStorageService:EncryptDecryptLocalStorageService,
    public encryptDecryptService: EncryptDecryptService,public router: Router,private dataSharingService:DataSharingService) {
    this.userId = + this.encryptDecryptService.getLocalStorage("userId");
    this.myRoute=this.router.url;
    //checking whether its race entry or result..
    if (this.myRoute.includes('admin/update/raceResult')) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.raceResultId = params['raceResultId'];
        this.raceId=params['raceId'];
        this.raceResultMode=params['mode']
      });
      this.adminRaceResult = true;
      this.showConstructorList = false;
      this.showDriverList = true;
      this.showRaceInfo = false;
      this.showDriverListForFastestLap = false;
      this.showDriverListForPolePosition = false;
      this.showDriverListForMostPosition = false;
      this.raceResultMode=='view'? this.showEditButton=false: this.showEditButton=true;
      this.getConstructorsList();
      this.getDriversList();
      this.getRaceInfo();
    }
    else {
      this.showConstructorList = false;
      this.showDriverList = false;
      this.showRaceInfo = true;
      this.showDriverListForFastestLap = false;
      this.showDriverListForPolePosition = false;
      this.showDriverListForMostPosition = false;
      var raceId1 = +this.encryptDecryptService.getLocalStorage("upcomingRaceId");
      this._genericService.getAll(this.latestRaceDetailsUrl + '?raceId=' + raceId1 + '&userId=' + this.userId).subscribe(response => {
        if (response.status.success == "Success") {
          var raceDetails = response.data;
          this.currentDateTime = new Date(raceDetails.todayDateTime);
          this.startDate = this.encryptDecryptService.getLocalStorage("raceBdStartingDateTime");
          this.endDate = this.encryptDecryptService.getLocalStorage("raceBdClosingDateTime");
          this.tempRaceDate = this.encryptDecryptService.getLocalStorage("raceDate");
          this.startDateFormat = new Date(this.startDate);
          this.endDateFormat = new Date(this.endDate);
          this.raceDateFormat = new Date(this.tempRaceDate)
        
          this.upcomingRaceId = +this.encryptDecryptService.getLocalStorage("upcomingRaceId")
          if (this.currentDateTime < this.startDateFormat) {
            //Need to show this previous result with only view button
            this.raceId = +this.encryptDecryptService.getLocalStorage("previousRaceId");
            this.showEditButton = false;
            this.showViewButton = true;
          }
          if ((this.currentDateTime >= this.startDateFormat) && (this.currentDateTime <= this.endDateFormat)) {
            //Need to show Add entry button for first time
            this.raceId = this.upcomingRaceId;
            if (this.currentDateTime > this.endDateFormat) {
              this.showViewButton = true;
              this.showEditButton = false;
            }
            else {
              this.showViewButton = false;
              this.showEditButton = true;
            }
    
          }
          if ((this.currentDateTime > this.endDateFormat) && (this.currentDateTime <= this.raceDateFormat)) {
            this.raceId = +this.encryptDecryptService.getLocalStorage("upcomingRaceId");
            this.showViewButton = true;
            this.showEditButton = false;
          }
          interval(1000).pipe(
            map((x) => {
              this.startDiff = Date.parse(this.startDateFormat) - Date.parse(new Date().toString());
            })).subscribe((x) => {
              this.startDays = this.getDays(this.startDiff);
              this.startHours = this.getHours(this.startDiff);
              this.startMinutes = this.getMinutes(this.startDiff);
              this.startSeconds = this.getSeconds(this.startDiff);
    
              if (this.startDays == 0 && this.startHours == 0 && this.startMinutes == 0 && this.startSeconds == 0) {
                //make screen empty 
                this.showEditButton = true;
                this.selectedDrivers = [];
                this.selectedConstructors = [];
                this.selectedFastestLap = {};
                this.selectedPolePosition = {};
                this.selectedMostPosition = {};
              }
            });
          interval(1000).pipe(
            map((x) => {
              this.endDiff = Date.parse(this.endDateFormat) - Date.parse(new Date().toString());
            })).subscribe((x) => {
              this.endDays = this.getDays(this.endDiff);
              this.endHours = this.getHours(this.endDiff);
              this.endMinutes = this.getMinutes(this.endDiff);
              this.endSeconds = this.getSeconds(this.endDiff);
              if (this.endDays == 0 && this.endHours == 0 && this.endMinutes == 0 && this.endSeconds == 0) {
    
                this.showEditButton = false;
                this.raceId = sessionStorage.upcomingRaceId;
                //show alert to the user and call get api
                this.getRaceEntryById().then(res => this.createArrayObj())
    
              }
              if (this.endDays == 0 && this.endHours == 0 && this.endMinutes == 2 && this.endSeconds == 0) {
                $("#addRaceEntryModal").modal("show");
              }
    
            });
        }
        else {
          this.upcomingRaceId = +this.encryptDecryptService.getLocalStorage("upcomingRaceId");
        }
        this.getConstructorsList();
        this.getDriversList();
        this.getRaceInfo();
      });
    }
    sessionStorage.selectedDriver = '';
  }
  ngOnInit() {
    this.PreLoader = true;
    this.formArrayObj();
  }
  getRaceInfo() {
    if(!this.adminRaceResult){
    if(this.upcomingRaceId!=0){
      this.PreLoaderForInfo = true;
    this._genericService.getAll(this.raceInfoUrl + "?raceId=" + this.upcomingRaceId).subscribe(response => {
      if (response.status.success == "Success") {
        this.raceInfoObj = response.data;
        this.circuitSrc = response.data.Circuits.circuitImage;
        this.PreLoaderForInfo = false;
      }
    },error => {
      this.PreLoaderForInfo = false;
    })
    setTimeout(() => {
      this.PreLoaderForInfo = false;
    }, 2000);
  }
  else{
    this.noRace=true;
  }
  }
  }

  getDays(t) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t) {
    return Math.floor((t / 1000) % 60);
  }

  addscss() {
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("race-entry-ranking");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName(" selected");
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" selected", "");
        }
        this.className += " selected";
      });
    }
  }
  formArrayObj() {
    //obj for driver
    for (let i = 1; i <= 3; i++) {
      let obj = this.emptyDriverObj();
      obj['position'] = i;
      this.selectedDrivers.push(obj);
    }
    // //obj for constructor
    // for (let i = 1; i <= 3; i++) {
    //   let obj = this.emptyConstructorObj();
    //   obj['position'] = i;
    //   this.selectedConstructors.push(obj);
    // }
    // this.selectedPolePosition = this.emptyPoleObject();
    // this.selectedFastestLap = this.emptyfastestLapObject();

    if(this.adminRaceResult==true){
      let obj = this.emptyMPGObj();
      this.selectedMpgArray.push(obj);
    }
    else{
      this.selectedMostPosition = this.emptymostPositionObject();
    }
    
  }
  getSelected(object) {
    // this.classList.add('selected');
    for (var Index = 0; Index < document.getElementsByClassName('ranking selected').length; Index++) {
      document.getElementsByClassName('ranking selected')[0].className = 'ranking';
    }
    if (this.selectedDrivers.length == 3) {
      for (var Index = 0; Index < document.getElementsByClassName('ranking selected').length; Index++) {
        document.getElementsByClassName('ranking selected')[0].className = 'ranking';
      }
    }
    object.currentTarget.className = 'ranking selected';
  }
 
  createArrayObjSave() {
    if(!this.adminRaceResult){
    if (this.existingRaceEntryDetail != null) {

      this.selectedDrivers = this.existingRaceEntryDetail.raceEntryDriverTRX;

      for (let obj of this.selectedDrivers) {
        obj.selected = true;
      }    
    }
    else {
       
      //obj for driver
      for (let i = 1; i <= 3; i++) {
        let obj = this.emptyDriverObj();
        obj['position'] = i;
        this.selectedDrivers.push(obj);
      }

    }
    this.reference(); 
  }
  //race result
  else{
    if (this.raceResultDetail != null) {

      this.selectedDrivers = this.raceResultDetail.f1RaceResultDriverTrx;

      for (let obj of this.selectedDrivers) {
        obj.selected = true;
      }      

    }
    else {
       
      //obj for driver
      for (let i = 1; i <= 3; i++) {
        let obj = this.emptyDriverObj();
        obj['position'] = i;
        this.selectedDrivers.push(obj);
      }
 
    }
  }
  }
  createArrayObj() {
    if (!this.adminRaceResult) {
      //if user race entry
      if (this.existingRaceEntryDetail != null) {
        if( this.existingRaceEntryDetail.sprintEntryDriverTrx.length>0)
        {
          this.selectedDrivers = this.existingRaceEntryDetail.sprintEntryDriverTrx;
          for (let obj of this.selectedDrivers) {
            obj.selected = true;
          }
        }
        this.filterFromList();
      }
    }
    else{
// if it is admin
if (this.raceResultDetail != null) {
  this.selectedDrivers = this.raceResultDetail.f1RaceResultDriverTrx;
  for (let obj of this.selectedDrivers) {
    obj.selected = true;
  }
  this.selectedConstructors = this.raceResultDetail.f1RaceResultConstructorTrx;

  for (let obj of this.selectedConstructors) {
    obj.selected = true;
  }
  if (this.raceResultDetail.f1RaceResultLapDetail != null || undefined) {
    var tempRaceLap = this.raceResultDetail.f1RaceResultLapDetail;
    if (tempRaceLap.fastestLapDriver != undefined) {
      this.selectedFastestLap = tempRaceLap.fastestLapDriver;
      this.selectedFastestLap['selected'] = true;
    }
    else {
      this.selectedFastestLap['selected'] = false;
    }
    this.selectedMpgArray=this.raceResultDetail.f1RaceResultMpg;
    var tem=11;
    for(let i of this.selectedMpgArray){
      i.selected=true;
      tem++;
    }
    if (tempRaceLap.polePositionDriver != undefined) {
      this.selectedPolePosition = tempRaceLap.polePositionDriver;
      this.selectedPolePosition['selected'] = true;
    }
    else {
      this.selectedPolePosition['selected'] = false;
    }
  }
  this.filterFromList();
}

    }
  }
  filterFromList() {
    for (let i of this.selectedDrivers) {
      if (this.responseDriverList != undefined) {
        const indexOfSelectedDriver = this.responseDriverList.
          findIndex(x => x.driverId == i.drivers.driverId);// get the postion of selected driver from responseDriverList 
        this.responseDriverList.splice(indexOfSelectedDriver, 1)
      }
    }

    for (let i of this.selectedConstructors) {
      if (this.responseconstructorList != undefined) {
        const indexOfSelectedConstructor = this.responseconstructorList.
          findIndex(x => x.constructorId === i.constructors.constructorId);// get the postion of selected driver 
        this.responseconstructorList.splice(indexOfSelectedConstructor, 1)
      }
    }
if(this.adminRaceResult==true){
    for (let i of this.selectedMpgArray) {
      if (this.DriverListForMPG != undefined) {
        const indexOfSelectedDriver = this.DriverListForMPG.
          findIndex(x => x.driverId == i.mostPlacesGainedDriver.driverId);// get the postion of selected driver from responseDriverList 
        this.DriverListForMPG.splice(indexOfSelectedDriver, 1)
      }
    }
  }
  else{
      if (this.DriverListForMPG != undefined) {
        const indexOfSelectedMostPlaced = this.DriverListForMPG.findIndex(x => x.driverId == this.selectedMostPosition['driverId']);
        this.DriverListForMPG.splice(indexOfSelectedMostPlaced, 1);
      }
  }
    if (this.DriverListForFastestLap != undefined) {
      const indexOfSelectedFastestLapDriver = this.DriverListForFastestLap.findIndex(x => x.driverId == this.selectedFastestLap['driverId']);
      this.DriverListForFastestLap.splice(indexOfSelectedFastestLapDriver, 1);
    }
    if (this.DriverListForPolePosition != undefined) {
      const indexOfSelectedPolePosition = this.DriverListForPolePosition.findIndex(x => x.driverId == this.selectedPolePosition['driverId']);
      this.DriverListForPolePosition.splice(indexOfSelectedPolePosition, 1);
    }
   
  }
  emptyConstructorObj() {
    let obj = {
      selected: false,
      constructors: {
        'constructorId': '',
        'name': '',
        'constructorPic': '',
        'f1RaceEntryConstructorTRXId': ''
      }
    }
    return obj;
  }
  emptyDriverObj() {
    var obj = {
      selected: false,
      drivers: {
        'driverId': ' ',
        'foreName': ' ',
        'surName': ' ',
        'driverPic': ' '
      }
    }
    return obj;
  }
  emptyMPGObj() {
    var obj = {
      selected: false,
      mostPlacesGainedDriver: {
        'driverId': ' ',
        'foreName': ' ',
        'surName': ' ',
        'driverPic': ' '
      }
    }
    return obj;
  }
  emptyPoleObject() {
    var tempselectedPolePosition = {
      selected: false,
      polePositionDriver: {
        'driverId': '',
        'foreName': ' ',
        'surName': ' ',
        'driverPic': ' '
      }
    }
    return tempselectedPolePosition;
  }
  emptymostPositionObject() {
    var tempselectedMostPositionGained = {
      selected: false,
      mostPlacesGainedDriver: {
        'driverId': '',
        'foreName': ' ',
        'surName': ' ',
        'driverPic': ' '
      }
    }
    return tempselectedMostPositionGained;
  }
  emptyfastestLapObject() {
    var tempselectedfastestLap = {
      selected: false,
      fastestLapDriver: {
        'driverId': '',
        'foreName': ' ',
        'surName': ' ',
        'driverPic': ' '
      }
    }
    return tempselectedfastestLap;
  }

  FastestlapDetail() {

    if (this.sourceFastestLap != undefined) {
      if (this.selectedFastestLap['selected'] == false) {
        this.selectedFastestLap = this.sourceFastestLap;
        this.selectedFastestLap['selected'] = true;
        const indexofFastestLapDriver = this.DriverListForFastestLap.indexOf(this.sourceFastestLap);// get the postion of selected driver from  DriverListForFastestLap
        this.DriverListForFastestLap.splice(indexofFastestLapDriver, 1);//splice particular position
        this.removeCss() ;   
        this.findToggleSector();
      }
    }
    
  }
  polePositionDetail() {
    if (this.sourcePolePosition != undefined) {
      if (this.selectedPolePosition['selected'] == false) {
        this.selectedPolePosition = this.sourcePolePosition;
        this.selectedPolePosition['selected'] = true;
        const indexofPolePosition = this.DriverListForPolePosition.indexOf(this.sourcePolePosition);// get the postion of selected driver from  DriverListForPolePostion
        this.DriverListForPolePosition.splice(indexofPolePosition, 1);//splice particular position
       this.removeCss() ;   
       this.findToggleSector();
      }
    }   
  }

  mostPositionGainedDetail() {
    if (this.sourceMostPosition != undefined) {
      if (this.selectedMostPosition['selected'] == false) {
        this.selectedMostPosition = this.sourceMostPosition;
        this.selectedMostPosition['selected'] = true;
        const indexofMostPosition = this.DriverListForMostPosition.indexOf(this.sourceMostPosition);// get the postion of selected driver from  DriverListForPolePostion
        this.DriverListForMostPosition.splice(indexofMostPosition, 1);//splice particular position
        this.removeCss() ;   
        this.findToggleSector();
      }
    }
  }
  interchangeSourceTargetMPG() {
    if (this.sourceMostPosition != undefined && this.targetMPGPosition != undefined) {
      const indexOfSelectedDriverList = this.selectedMpgArray.indexOf(this.targetMPGPosition);//getting the index or position of target(selected drivers)

      if (this.selectedMpgArray[indexOfSelectedDriverList].selected == false) {
        this.selectedMpgArray[indexOfSelectedDriverList].mostPlacesGainedDriver = this.sourceMostPosition; //appending source details[driverID,forname...]in selected driver obj
        this.selectedMpgArray[indexOfSelectedDriverList].selected = true;
        //removal of green line after adding driver 
        var header = document.getElementById("myDIV");
        //  var header = document.getElementById("mpg");
        var btns = header.getElementsByClassName("race-entry-ranking");
        for (var i = 0; i < btns.length; i++) {
          if (btns[i].className == 'race-entry-ranking selected') {
            document.getElementsByClassName('race-entry-ranking selected')[0].className = 'race-entry-ranking ';
          }
        }

        //remove from responseDriverList since it is added as selectedDriver list
        const indexresponseDriverList = this.DriverListForMPG.indexOf(this.sourceMostPosition);// get the postion of selected driver from responseDriverList 
        this.DriverListForMPG.splice(indexresponseDriverList, 1);//splice particular position
        this.showAddMPGbtn=true;
       // this.removeCss()
        this.findToggleSector();
      }
    }
  }
   
  interchangeSourceTargetDriver() {
    if (this.SourceDetail != undefined && this.targetPosition != undefined) {
      const indexOfSelectedDriverList = this.selectedDrivers.indexOf(this.targetPosition);//getting the index or position of target(selected drivers)
      if (this.selectedDrivers[indexOfSelectedDriverList].selected == false) {
        this.selectedDrivers[indexOfSelectedDriverList].drivers = this.SourceDetail; //appending source details[driverID,forname...]in selected driver obj
        this.selectedDrivers[indexOfSelectedDriverList].selected = true;
        //remove from responseDriverList since it is added as selectedDriver list
        const indexresponseDriverList = this.responseDriverList.indexOf(this.SourceDetail);// get the postion of selected driver from responseDriverList 
        this.responseDriverList.splice(indexresponseDriverList, 1);//splice particular position
        this.removeCss() ;   
        //when current driver list is completed with selected driver then switch on to next sector eg.constructor
        this.findToggleSector();
      }
    }
  }
  findToggleSector(){
    let header = document.getElementById("myDIV");
    let  btns =header.getElementsByClassName("race-entry-ranking");
    let isDriverselected=this.selectedDrivers.findIndex(x => x.selected == false)
    if(isDriverselected==-1){
      let nextConstructor=this.selectedConstructors.findIndex(x => x.selected == false)
      let constructorGlineIndex;

      if(nextConstructor!==-1){
        this.showConstructors();
      nextConstructor==0?constructorGlineIndex=6:constructorGlineIndex;
      nextConstructor==1?constructorGlineIndex=7:constructorGlineIndex;
      nextConstructor==2?constructorGlineIndex=8:constructorGlineIndex;
      this.targetConstructorPosition = this.selectedConstructors[nextConstructor];
      btns[constructorGlineIndex].setAttribute("class", "race-entry-ranking selected");//added greeen line to next index  
      }
      else{
      if(this.selectedFastestLap['selected']==false){
        btns[9].setAttribute("class", "race-entry-ranking lap selected");   
        this.showFastestLap(); 
      }
      else{
        if(this.selectedPolePosition['selected']==false){
          btns[10].setAttribute("class", "race-entry-ranking pole selected");   
          this.showPolePosition(); 
        }
        else{
        if( this.adminRaceResult==false){
          if(this.selectedMostPosition['selected']==false){
            this.showMostPlace();
            btns[11].setAttribute("class", "race-entry-ranking place selected");
         }
        }
         else {
          let nextmpg= this.selectedMpgArray.findIndex(x => x.selected == false) // if any incomplete drivers avail then it fills it before moving on constructor
            if(nextmpg!==-1){
          this.showMostPlace();
              this.targetMPGPosition = this.selectedMpgArray[nextmpg];
                btns[11+nextmpg].setAttribute("class", "race-entry-ranking place selected");
            }
         }   
        }
      }
      }
    }
    else{
      let nextDriver=this.selectedDrivers.findIndex(x => x.selected == false)
      if(nextDriver!==-1){
        this.showDrivers();
        this.targetPosition = this.selectedDrivers[nextDriver];
        btns[nextDriver].setAttribute("class", "race-entry-ranking selected");//added greeen line to next index  
      }
    }
  }
  removeCss(){
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("race-entry-ranking");
    for (var i = 0; i < btns.length; i++) {
      if (btns[i].className == 'race-entry-ranking selected'|| btns[i].className == 'race-entry-ranking  selected') {
         document.getElementsByClassName('race-entry-ranking selected')[0].className = 'race-entry-ranking';
       }
       if (btns[i].className == 'race-entry-ranking  selected') {
         document.getElementsByClassName('race-entry-ranking  selected')[0].className = 'race-entry-ranking';
       }
      if (btns[i].className == 'race-entry-ranking place selected') {
        document.getElementsByClassName('race-entry-ranking place selected')[0].className = 'race-entry-ranking place';
      }
      if (btns[i].className == 'race-entry-ranking lap selected') {
        document.getElementsByClassName('race-entry-ranking lap selected')[0].className = 'race-entry-ranking lap';
      }
      if (btns[i].className == 'race-entry-ranking pole selected') {
        document.getElementsByClassName('race-entry-ranking pole selected')[0].className = 'race-entry-ranking pole';
      }}
  }
  interchangeSourceTargetConstructor() {
    if (this.SourceConstructorDetail != undefined && this.targetConstructorPosition != undefined) {
      const indexOfSelectedConstructList = this.selectedConstructors.indexOf(this.targetConstructorPosition);//getting the index or position of target(selected constructor)
      if (this.selectedConstructors[indexOfSelectedConstructList].selected == false) {
        this.selectedConstructors[indexOfSelectedConstructList].constructors = this.SourceConstructorDetail; //appending source details[constructorId,name...]in selected constructor obj
        this.selectedConstructors[indexOfSelectedConstructList].selected = true;
        //remove from responseconstructorList since it is added as selectedConstructors list
        const indexresponseconstructorList = this.responseconstructorList.indexOf(this.SourceConstructorDetail);// get the postion of selected driver from responseConstructorList 
        this.responseconstructorList.splice(indexresponseconstructorList, 1);//splice particular position
        this.removeCss() ;   
        this.findToggleSector();
        }
    }
  }
  removeFromMPGList(obj) {
    this.DriverListForMPG.splice(obj.mostPlacesGainedDriver.driverId - 1, 0, obj.mostPlacesGainedDriver);
    const indexOfSelectedDriverList = this.selectedMpgArray.indexOf(obj);
    this.selectedMpgArray.splice(indexOfSelectedDriverList, 1);
    // if therer removed all mpg then no frams will b there at that time add empty array..
    if (this.selectedMpgArray.length == 0) {
      this.showAddMPGbtn = false;
      let obj = this.emptyMPGObj();
      this.selectedMpgArray.push(obj);
    }
    this.DriverListForMPG.sort(function (a, b) {
      var nameA = a.foreName.toLowerCase(), nameB = b.foreName.toLowerCase()
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0 //default return value (no sorting)
    })
  }

  removeFromDriverList(obj) {
    this.responseDriverList.splice(obj.drivers.driverId - 1, 0, obj.drivers);
    const indexOfSelectedDriverList = this.selectedDrivers.indexOf(obj);
    this.selectedDrivers[indexOfSelectedDriverList].drivers = this.emptyDriverObj().drivers; //appending source details[driverID,forname...]in selected driver obj
    this.selectedDrivers[indexOfSelectedDriverList].selected = false;
    this.responseDriverList.sort(function (a, b) {
      var nameA = a.foreName.toLowerCase(), nameB = b.foreName.toLowerCase()
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0 //default return value (no sorting)
    })
  }
  removeFromConstructorList(obj) {
    //adding back to responseDriverList
    this.responseconstructorList.splice(obj.constructors.constructorId - 1, 0, obj.constructors);
    const indexOfSelectedConstructList = this.selectedConstructors.indexOf(obj);
    this.selectedConstructors[indexOfSelectedConstructList].constructors = this.emptyConstructorObj().constructors; //appending source details[driverID,forname...]in selected driver obj
    this.selectedConstructors[indexOfSelectedConstructList].selected = false;
    this.responseconstructorList.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0 //default return value (no sorting)
    })
  }

  removeFromselectedFastestLap() {
    this.DriverListForFastestLap.splice(this.selectedFastestLap['driverId'] - 1, 0, this.selectedFastestLap);
    this.selectedFastestLap = this.emptyfastestLapObject().fastestLapDriver;
    this.selectedFastestLap['selected'] = false;
    this.DriverListForFastestLap.sort(function (a, b) {
      var nameA = a.foreName.toLowerCase(), nameB = b.foreName.toLowerCase()
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0 //default return value (no sorting)
    })
  }

  removeFromselectedpolePosition() {
    this.DriverListForPolePosition.splice(this.selectedPolePosition['driverId'] - 1, 0, this.selectedPolePosition);
    this.selectedPolePosition = this.emptyPoleObject().polePositionDriver; //appending source details[driverID,forname...]in selected driver obj
    this.selectedPolePosition['selected'] = false;
    this.DriverListForPolePosition.sort(function (a, b) {
      var nameA = a.foreName.toLowerCase(), nameB = b.foreName.toLowerCase()
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0 //default return value (no sorting)
    })
  }

  removeFromselectedMostPosition() {

    this.DriverListForMostPosition.splice(this.selectedMostPosition['driverId'] - 1, 0, this.selectedMostPosition);
    this.selectedMostPosition = this.emptymostPositionObject().mostPlacesGainedDriver; //appending source details[driverID,forname...]in selected driver obj
    this.selectedMostPosition['selected'] = false;
    this.DriverListForMostPosition.sort(function (a, b) {
      var nameA = a.foreName.toLowerCase(), nameB = b.foreName.toLowerCase()
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0 //default return value (no sorting)
    })
  }

  //append with position like st,nd,rd,th
  getposition(position) {
    if (position == 1) {
      return position + "st";
    }
    else if (position == 2) {
      return position + "nd";
    }
    else if (position == 3) {
      return position + "rd";
    }
    return position + "th";
  }
  getRaceResultById() {
    return new Promise((resolve, reject) => {
    this._genericService.getAll(this.raceResultByIdUrl + "?raceResultId=" + this.raceResultId).subscribe(response => {
      if (response.status.success == 'Success') {
        this.raceResultDetail=response.data;
        this.circuitDetail = response.data.race;        
      }
      this.PreLoader = false;
      resolve(response);
    },error =>{
      this.PreLoader = false;
      reject("No result")
    })
  })
  }

  reference() {     
      this._genericService.getAll(this.getSeletedDervierListUrl + "?userId=" + this.userId + "&raceId=" + this.raceId).subscribe(
        response => {
          if (response.status.success == "Success") {
            this.existingRaceEntryDetail = response.data;
            this.circuitDetail = response.data.race;
            this.initSprintDeriver = response.data;
            this.eventName = 'edit';
            this.f1SprintEntryId = response.data.f1SprintEntryId;            
            this.PreLoader = false;
            sessionStorage.selectedDriver = JSON.stringify(response.data);
          }
          else {
            if (response.status.message == "Race Entry Not Found") {
              var currentDateTime = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
              var raceDate = sessionStorage.upcommingMatchDate;
              if ((currentDateTime >= this.startDateFormat) && (currentDateTime <= this.endDateFormat)) {
                this.showEditButton = true;
              }

              if (raceDate < currentDateTime) {
                //Need to show msg result not yet updated.........
                this.showEditButton = false;
                this.showViewButton = false;
                // this.showAddTemplate = false;
                this.resultNotYetUpdated = true;
              }
            }
            this.PreLoaderForSave = false;
          }        
        },
        error => {
          this.PreLoaderForSave = false;           
        }
      );
    
  }
  getRaceEntryById() {
    return new Promise((resolve, reject) => {
    if(this.raceId!=0){
      this._genericService.getAll(this.getSeletedDervierListUrl + "?userId=" + this.userId + "&raceId=" + this.raceId).subscribe(
        response => {
          if (response.status.success == "Success") {
            this.existingRaceEntryDetail = response.data;
            this.circuitDetail = response.data.race;
            this.initSprintDeriver = response.data;
            this.eventName = 'edit';
            this.f1SprintEntryId = response.data.f1SprintEntryId;            
            this.PreLoader = false;
            sessionStorage.selectedDriver = JSON.stringify(response.data);
          }
          else {
            if (response.status.message == "Race Entry Not Found") {
              var currentDateTime = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
              var raceDate = sessionStorage.upcommingMatchDate;
              if ((currentDateTime >= this.startDateFormat) && (currentDateTime <= this.endDateFormat)) {
                this.showEditButton = true;
              }

              if (raceDate < currentDateTime) {
                //Need to show msg result not yet updated.........
                this.showEditButton = false;
                this.showViewButton = false;
                // this.showAddTemplate = false;
                this.resultNotYetUpdated = true;
              }
            }
            this.PreLoader = false;
          }
          resolve(response);
        },
        error => {
          this.PreLoader = false;
          reject("No Data")
        }
      );}
      else{
        this.noRace=true;
      }
    });
  }

  //get all driver list
  getDriversList() {
    this._genericService.getAll(this.driverListUrl + "?raceId=" + this.raceId).subscribe(response => {
      if(response.status.success=="Fail"){
        this.noDriver=NotificationConstants.noDriverMsg;
      }
      else{
        var driverList = response.data;
        this.responseDriverList = driverList;
        this.DriverListForFastestLap = (this.keepCloning(driverList));
        this.DriverListForPolePosition = (this.keepCloning(driverList));
        this.DriverListForMostPosition = (this.keepCloning(driverList));
        this.DriverListForMPG = (this.keepCloning(driverList));

      }
      
     //get existing entry or result
      if(this.adminRaceResult==false){
        this.getRaceEntryById().then(res => this.createArrayObj())
      }
      else{
        if(this.raceResultId!=null || this.raceResultId!=undefined){
          this.getRaceResultById().then(res =>this.createArrayObj());
        }
        else{this.PreLoader = false;}
      }
    },
    error => {
    //  this.raceEntryFailureMsg=NotificationConstants.errormsg;
    })
  }

  //get all constructor list
  getConstructorsList() {
    this._genericService.getAll(this.constructorsListUrl + "?raceId=" + this.raceId).subscribe(response => {
      if(response.status.success=="Fail"){
        this.noConstructor=NotificationConstants.noConstructorMsg;
      }
      else{
        this.responseconstructorList = response.data;
      }
    },error => {
     // this.raceEntryFailureMsg=NotificationConstants.errormsg;
    })
  }

  //deep clone 
  keepCloning(objectpassed) {
    if (objectpassed === null || typeof objectpassed !== 'object') {
      return objectpassed;
    }
    // give temporary-storage the original obj's constructor
    var temporary = objectpassed.constructor();
    for (var key in objectpassed) {
      temporary[key] = this.keepCloning(objectpassed[key]);
    }
    return temporary;
  }

  showInfoOnly() {
    if(!this.adminRaceResult){
    this.showDriverList = false;
    this.showConstructorList = false;
    this.showDriverListForFastestLap = false;
    this.showDriverListForMostPosition = false;
    this.showDriverListForPolePosition = false;
    this.showRaceInfo = true;
    }
  }
  showDrivers() {
    this.showDriverList = true;
    this.showConstructorList = false;
    this.showDriverListForFastestLap = false;
    this.showDriverListForMostPosition = false;
    this.showDriverListForPolePosition = false;
    this.showRaceInfo = false;
  }
  showConstructors() {
    this.showDriverList = false;
    this.showConstructorList = true;
    this.showDriverListForFastestLap = false;
    this.showDriverListForMostPosition = false;
    this.showDriverListForPolePosition = false;
    this.showRaceInfo = false;
  }
  showFastestLap() {
    this.showDriverList = false;
    this.showConstructorList = false;
    this.showDriverListForFastestLap = true;
    this.showDriverListForMostPosition = false;
    this.showDriverListForPolePosition = false;
    this.showRaceInfo = false;
  }
  showMostPlace() {
    this.showDriverList = false;
    this.showConstructorList = false;
    this.showDriverListForFastestLap = false;
    this.showDriverListForMostPosition = true;
    this.showDriverListForPolePosition = false;
    this.showRaceInfo = false;
    
  }
  showPolePosition() {
    this.showDriverList = false;
    this.showConstructorList = false;
    this.showDriverListForFastestLap = false;
    this.showDriverListForMostPosition = false ;
    this.showDriverListForPolePosition =true;
    this.showRaceInfo = false;
  }
  enableSaveButton() {
    // function isSelected(element, index, array) {
    //   if (element.selected === true) {
    //     return true;
    //   }
    // }
    // var All6DriversSelected = this.selectedDrivers.every(isSelected);
    // var All3ConstructorSelected = this.selectedConstructors.every(isSelected);
    // var fastestLapDriverSelected = this.selectedFastestLap['selected'];
    // var polePositionDriverSelected = this.selectedPolePosition['selected'];
    return true;

    // if(this.adminRaceResult==true){
    //   var AllMPGselected=this.selectedMpgArray.every(isSelected);
    //   if (All6DriversSelected) {
    //     return false
    //   }
    //   else {
    //     return true;
    //   }
    // }
    // else{
    //   var mostPlacesGainedDriverSelected = this.selectedMostPosition['selected'];
    //   if (All6DriversSelected) {
    //     return false
    //   }
    //   else {
    //     return true;
    //   }
    // }
   
  }

  setRaceEntryPayLoad() {
    // this.tempSelectedConstructors = this.keepCloning(this.selectedConstructors);
    this.tempSelectedDrivers = this.keepCloning(this.selectedDrivers);
    // //for constructor
    // for (let obj of this.tempSelectedConstructors) {
    //   let temp = {
    //     'constructorId': obj.constructors.constructorId
    //   }
    //   obj.constructors = temp;
    //   delete obj.selected;
    // }
    // this.raceEntryPayload.raceEntryConstructorTRX = this.tempSelectedConstructors;
    //for driver
    for (let obj of this.tempSelectedDrivers) {
      let temp = {
        'driverId': obj.drivers.driverId
      }
      obj.drivers = temp;
      delete obj.selected;
    }
    this.raceEntryPayload.sprintEntryDriverTRX = this.tempSelectedDrivers;
    
    //for fastest lap
    // let fastlap = {
    //   "fastestLapDriver": {
    //     "driverId": this.selectedFastestLap['driverId']
    //   }
    // }
    // this.raceEntryPayload.raceEntryLapDetail = fastlap;
    //for most postion gained
    // let mostPlace = {
    //   "driverId": this.selectedMostPosition['driverId']
    // }
    // this.raceEntryPayload.raceEntryLapDetail.mostPlacesGainedDriver = mostPlace;
    //for pole position
    // let poleposition = {
    //   "driverId": this.selectedPolePosition['driverId']
    // }
    // this.raceEntryPayload.raceEntryLapDetail.polePositionDriver = poleposition;
    // if (this.existingRaceEntryDetail != null || this.existingRaceEntryDetail != undefined) {
    //   this.existingRaceEntryDetail.raceEntryLapDetail.f1RaceEntryLapDetailId ? this.raceEntryPayload.raceEntryLapDetail.f1RaceEntryLapDetailId : ''
    //   this.raceEntryPayload['f1RaceEntryId'] = this.existingRaceEntryDetail.f1RaceEntryId;
    //   this.existingRaceEntryDetail.raceEntryLapDetail.f1RaceEntryLapDetailId != null || this.existingRaceEntryDetail.raceEntryLapDetail.f1RaceEntryLapDetailId != undefined ?
    //     this.raceEntryPayload.raceEntryLapDetail['f1RaceEntryLapDetailId'] = this.existingRaceEntryDetail.raceEntryLapDetail.f1RaceEntryLapDetailId : '';
    // }

  }
//race result
  setRaceResultPayLoad() {
    // this.tempSelectedConstructors = this.keepCloning(this.selectedConstructors);
    this.tempSelectedDrivers = this.keepCloning(this.selectedDrivers);
    // let tempSelectedMPG=this.keepCloning(this.selectedMpgArray);
    //for constructor
    // for (let obj of this.tempSelectedConstructors) {
    //   let temp = {
    //     'constructorId': obj.constructors.constructorId
    //   }
    //   obj.constructors = temp;
    //   delete obj.selected;

    // }
    // this.raceEntryResultPayload.f1RaceResultConstructorTrx = this.tempSelectedConstructors;
//for mpg
    // for (let obj of tempSelectedMPG) {
    //   let temp = {
    //     'driverId':  obj.mostPlacesGainedDriver.driverId
    //   }
    //   obj.mostPlacesGainedDriver = temp;
    //   delete obj.selected;

    // }
    // this.raceEntryResultPayload.f1RaceResultMpg = tempSelectedMPG;
    //for driver
    for (let obj of this.tempSelectedDrivers) {
      let temp = {
        'driverId': obj.drivers.driverId
      }
      obj.drivers = temp;
      delete obj.selected;
    }
    this.raceEntryResultPayload.f1RaceResultDriverTrx = this.tempSelectedDrivers;
    //for fastest lap
    // let fastlap = {
    //   "fastestLapDriver": {
    //     "driverId": this.selectedFastestLap['driverId']
    //   }
    // }
    // this.raceEntryResultPayload.f1RaceResultLapDetail = fastlap;
    //for most postion gained
    // let mostPlace = {
    //   "driverId": this.selectedMostPosition['driverId']
    // }
    //this.raceEntryResultPayload.f1RaceResultLapDetail.mostPlacesGainedDriver = mostPlace;
   
    //for pole position
    let poleposition = {
      "driverId": this.selectedPolePosition['driverId']
    }
    // this.raceEntryResultPayload.f1RaceResultLapDetail.polePositionDriver = poleposition;
    // if (this.raceResultDetail != null || this.raceResultDetail != undefined) {
    //   this.raceResultDetail.f1RaceResultLapDetail.f1RaceEntryLapDetailId ? this.raceEntryResultPayload.f1RaceResultLapDetail.f1RaceEntryLapDetailId : ''
    //   this.raceEntryResultPayload['f1RaceResultId'] = this.raceResultDetail.f1RaceResultId;
    //   this.raceEntryResultPayload.f1RaceResultLapDetail['f1RaceResultLapDetailId'] = this.raceResultDetail.f1RaceResultLapDetail.f1RaceResultLapDetailId;
    // }
  }
now;
  save() {
    this.dataSharingService.currentTimeObs.subscribe(data => {
      this.now = new Date(data);
      })
    this.PreLoaderForSave = true
    this.showInfoOnly();
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("race-entry-ranking");
    for (var i = 0; i < btns.length; i++) {
      if (btns[i].className == 'race-entry-ranking place selected') {
        document.getElementsByClassName('race-entry-ranking place selected')[0].className = 'race-entry-ranking place';
      }
      if (btns[i].className == 'race-entry-ranking lap selected') {
        document.getElementsByClassName('race-entry-ranking lap selected')[0].className = 'race-entry-ranking lap';
      }
      if (btns[i].className == 'race-entry-ranking pole selected') {
        document.getElementsByClassName('race-entry-ranking pole selected')[0].className = 'race-entry-ranking pole';
      }
    }
  this.PreLoaderForSave = true;
  this.raceEntryPayload = {
    "updatedOnUI":this.now,
    "createdOn":this.existingRaceEntryDetail!=null?this.existingRaceEntryDetail.createdOn:this.now,
    "user": [{ "f1ApplicationUserId": this.userId }],
    "race": { "raceId": this.upcomingRaceId },
    "isActive": true,
    "createdBy": this.userId,
    "updatedBy": this.userId,
    "sprintEntryDriverTRX": [],      
  }
  this.setRaceEntryPayLoad();
  //for race entry save
    if (this.eventName == 'edit') {   
      if (this.raceEntryPayload.sprintEntryDriverTRX) {
        let tempSprintEntryDriverTRX = this.raceEntryPayload.sprintEntryDriverTRX;
        let seletedDeriver = JSON.parse(sessionStorage.selectedDriver).sprintEntryDriverTrx;

        let resultArray = tempSprintEntryDriverTRX.map((elm,inx) => {
        let dId = elm.drivers.driverId;           
        let seletedIDs = seletedDeriver[inx].f1SprintEntryDriverTRXId;
        return {
          "f1SprintEntryDriverTRXId": seletedIDs,
          "position": elm.position,
          "drivers": {
            "driverId": elm.drivers.driverId
          },
          "points": elm.points,
          "grainPoints": elm.grainPoints
        }
          
        });
        this.raceEntryPayload.sprintEntryDriverTRX = resultArray;
      }
      this.raceEntryPayload = Object.assign({ f1SprintEntryId: this.f1SprintEntryId }, this.raceEntryPayload);
  } else {
    if (this.raceEntryPayload.sprintEntryDriverTRX) {
      let tempSprintEntryDriverTRX = this.raceEntryPayload.sprintEntryDriverTRX;
       let resultArray = tempSprintEntryDriverTRX.map((elm) => {
         return {
          "f1SprintEntryDriverTRXId": elm.f1RaceEntryDriverTRXId,
          "position": elm.position,
          "drivers": {
            "driverId": elm.drivers.driverId
          },
          "points": elm.points,
          "grainPoints": elm.grainPoints
          }         
       });
      this.raceEntryPayload.sprintEntryDriverTRX = resultArray;
    } 
  }
   
    console.log(this.raceEntryPayload);
    if (this.raceEntryPayload.sprintEntryDriverTRX.length === 3) {
        this._genericService.post(this.saveSprintEntryRaceResultUrl, this.raceEntryPayload).subscribe(response => {
        if (response.status.success == 'Success') {
          this.existingRaceEntryDetail = response.data;
          // this.PreLoaderForSave = false;       
          // this.PreLoaderForSave = false;
          this.selectedDrivers = [];
          this.selectedConstructors = [];
          this.selectedFastestLap = {};
          this.selectedPolePosition = {};
          this.selectedMostPosition = {};                
        }
        else {
          this.PreLoaderForSave = false;
        }   
        // this.reference();
        window.location.reload();
        // this.ngOnInit();
      }, error => {
        this.PreLoaderForSave = false;        
      });
    } else {
      this.raceEntryFailureMsg = "Only 3 only drivers."; 
    }    
  }

  // Jquery Function
  goTop() {
    if ($(window).width() < 1200) {
      $('.main-container').scrollTop(0);
    }
  }

  scrollRaceInfo() {
    if ($(window).width() < 1200) {
      $('#race-info')[0].scrollIntoView({
        block: "nearest",
        behavior: 'smooth'
      });
    }
  }
  scrollRaceEntry() {
    if ($(window).width() < 1200) {
      $('#race-entry')[0].scrollIntoView({
        block: "start",
        behavior: 'smooth'
      });
    }
  }

  scrollRaceEntrySave() {
    if ($(window).width() < 1200) {      
        $('#race-entry .card-body').animate({
          scrollTop: $(".race-entry-details").offset().top+500
        }, 200);
     
    }
  }

addMpg(){
  function isSelected(element, index, array) {
    if (element.selected === true) {
      return true;
    }
  }
  
  var isAllMPGfilled= this.selectedMpgArray.every(isSelected);
  if(isAllMPGfilled==true){
    let obj = this.emptyMPGObj();
    this.selectedMpgArray.push(obj);
    this.showAddMPGbtn=false;
    this.showMostPlace();
    this.targetMPGPosition=this.selectedMpgArray[this.selectedMpgArray.length-1];
  
    setTimeout(() => {
      var header = document.getElementById("mpg");
      var btns = header.getElementsByClassName("race-entry-ranking");
      setTimeout(() => {
        btns[btns.length-1].setAttribute("class", "race-entry-ranking selected");
  
      }, 100); 
    }, 50); 
    
  }
}
}




