import { Component, OnInit, Input } from '@angular/core';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { Router } from '@angular/router';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';

@Component({
  selector: 'app-view-team-leader-board',
  templateUrl: './view-team-leader-board.component.html',
  styleUrls: ['./view-team-leader-board.component.scss']
})
export class ViewTeamLeaderBoardComponent implements OnInit {
  teamListUrl = UrlConstants.teamList;
  public noTeam = false;
  public isTeamList = false;
  public teamResultList = [];
  public teams = []
  PreLoader = false;
  PreLoader1 = false;
  public currentYearRaceInfo = UrlConstants.racesUrl;
  races: any = [];
  raceId;
  showDropdown=false;
  currentRaceId;
  all:any=[];
  @Input()expand:boolean;
  noTeamForAll:boolean=false;
  constructor(public _genericService: GenericAPIService, public encryptDecryptService: EncryptDecryptService, public router: Router) {
    this.raceId = +this.encryptDecryptService.getLocalStorage("previousRaceId");
    this.currentRaceId = +this.encryptDecryptService.getLocalStorage("upcomingRaceId");
    this.getTeamListforCurrentRace();
    this.getRacesInfo();
  }
  ngOnInit() { }
  getTeamListforCurrentRace(){
      this._genericService.getAll(this.teamListUrl + "?raceId=" + this.raceId).subscribe(response => {
      if (response.status.success == "Success") {
        this.isTeamList = response.data.isTeamList;
        if(response.data.responseList.length>0){
          this.noTeamForAll = false;
        }
        else{
          this.noTeamForAll = true;
        }
        if (this.isTeamList == true) {
          this.teams = response.data.responseList;
        }
        else {
          this.all = response.data.responseList;
        }
        this.PreLoader = false;

      }
      else {
        this.PreLoader = false;
        this.noTeamForAll = true;
      }
    }, error => {
      this.PreLoader = false;
      this.noTeamForAll = true;
    })
  
  }
  load(){
    this.PreLoader=true;
    setTimeout(()=>{
      this.PreLoader=false;
    },800)
  }
  getTeamList(raceId) {
    this.PreLoader1 = true;
    if(raceId==this.currentRaceId){
      this.PreLoader1 = false;
      this.noTeam = true;
      this.teams =[];
      this.teamResultList=[];
    }
    else{
      this._genericService.getAll(this.teamListUrl + "?raceId=" + raceId).subscribe(response => {
      if (response.status.success == "Success") {
        this.PreLoader1 = false;
        this.isTeamList = response.data.isTeamList;
        if(response.data.responseList.length>0){
          this.noTeam = false;
        }
        else{
          this.noTeam = true;
        }
        if (this.isTeamList == true) {
          this.teams = response.data.responseList;
        }
        else {
          this.teamResultList = response.data.responseList;
        }
      }
      else {
        this.PreLoader1 = false;
        this.noTeam = true;
      }
    }, error => {
      this.PreLoader1 = false;
      this.noTeam = true;
    })
  }
  }

  getRacesInfo() {
    this._genericService.getAll(this.currentYearRaceInfo).subscribe(response => {
      if (response.status.success == 'Success') {
        this.races = response.data;
        if(this.isTeamList==false){
          this.getTeamList(this.races[0].RaceResult.raceId);
        }
      }
    })
  }


}
