import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    token = sessionStorage.getItem('token')
    canDeactivate(component: CanComponentDeactivate) {
        if (window.location.pathname == '/user/dashboard' && this.token!="" && this.token!=undefined ) {
            history.pushState(null, null, window.location.href);
            window.onpopstate = function (event) {
                history.go(0);
            };
            return false;
        }
        else {
            return true;
        }
    }

}