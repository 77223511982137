<!-- User Details -->
<div class="card shadow-sm rounded view-player user-detail h-auto">
  <div class="card-body px-4 py-3" *ngIf="!!userDetail">   
    <ul>
      <li>
        <div class="user-name-pic"> <span class="user-pic">
          <img src={{userDetail.userImage}} alt="user" />
      </span>
          <div>
            <h6>User Name</h6><span>{{userDetail.userName}}</span>
          </div>
        </div>
      </li>
      <li>
        <h6>Rank</h6>
        <span>{{userDetail.rank}}</span>
      </li>
      <li>
        <h6>Total Points</h6>
        <span>{{userDetail.totalPoints}}</span>
      </li>
      <li>
        <h6>Team name</h6>
        <span>{{userDetail.teamName}}</span>
      </li>      
      <li>
        <h6>Email ID</h6>
        <span>{{userDetail.email}}</span>
      </li>
    </ul>
  </div>
</div>
<app-previous-race-result [individualUserDetail]="{'userId':userProfileId,'isindividual':true}">
</app-previous-race-result>