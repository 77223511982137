import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LadderBoardRoutingModule } from './ladder-board-routing.module';
import { PlayerLadderBoardComponent } from './player-ladder-board/player-ladder-board.component';
import { TeamLadderBoardComponent } from './team-ladder-board/team-ladder-board.component';
import { LadderBoardComponent } from './ladder-board/ladder-board.component';

@NgModule({
  declarations: [PlayerLadderBoardComponent, TeamLadderBoardComponent, LadderBoardComponent],
  imports: [
    CommonModule,
    LadderBoardRoutingModule
  ]
})
export class LadderBoardModule { }
