import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RaceSprintEntryComponent } from './race-sprint-entry/race-sprint-entry.component';
import { RaceSprintEntryRoutingModule } from './race-sprint-entry-routing.module';
import { PreviousRaceResultComponent } from './previous-race-result/previous-race-result.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [RaceSprintEntryComponent, PreviousRaceResultComponent],
  imports: [
    CommonModule,
    RaceSprintEntryRoutingModule,
    FormsModule ,
    ReactiveFormsModule ,
    NgbModule,  
  ],
  exports: [RaceSprintEntryComponent, PreviousRaceResultComponent],

})
export class RaceSprintEntryModule { }
