import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IMyDpOptions } from 'mydatepicker';
import 'jquery';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { UrlConstants } from 'src/app/constants/url-constants';
import { commonService } from 'src/app/services/commonMethodService';
import { Router } from '@angular/router';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { NotificationConstants } from 'src/app/constants/notification-constants';
import { ValidationService } from 'src/app/constants/validation.directive';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localStorage.service';
import { environment } from 'src/environments/environment';
declare var $: any;
declare var Razorpay;
//import { DomSanitizationService } from '@angular/platform-browser';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public changePasswordUrl = UrlConstants.changePassword;
  public getProfileUrl = UrlConstants.getProfiledata;
  public updateProfileUrl = UrlConstants.updateProfile;
  public fetchOrderIdUrl = UrlConstants.fetchOrderId;
  public verySignatureUrl = UrlConstants.verySignature;
  getSubcriptionURL = UrlConstants.getSubcriptionDetails;

  public changePswdForm;
  public profileForm:FormGroup;
  public myDatePickerOptions: IMyDpOptions;
  public profileSrc;
  public teamSrc;
  public uploadedProfilePic = false;
  public uploadTeamPic = false;
  public userObj: any = {};
  public status = "";
  public endsOn = "";
  userImage: File;
  teamImage: File;
  validPswd: boolean = false;
  public reqPayLoad: any;
  public PreLoaderForProfile = false;
  public PreLoaderForChangePswd = false;
  public PreLoaderForProfileSave = false;
  public PreLoaderForRazorPay = false;
  public errorMsgForProfile: any;
  public errorMsgForTeam: any;
  public formatMsg: any;
  public switchAdmin: boolean;
  isTeamNameChange: boolean = false;
  isTeamImgChange: boolean = false;
  show: boolean = false;
  editProfile: boolean = false;
  public isPrincipal: boolean = false;
  public showOldPswd: boolean = false;
  public showNewPassword: boolean = false;
  public dateObj;
  failureUrl = "../../../../assets/img/failure.gif";
  failureGif;
  slideOutRight;
  slideInRight;
  //Razor pay started....
  amountToBePaid = 0;
  orderId;
  subscriptionDetails = new SubscriptionDetails();
  constructor(public genericService: GenericAPIService, public encryptDecryptLocalStorageService: EncryptDecryptLocalStorageService, public encryptDecryptService: EncryptDecryptService, public commonService: commonService, public router: Router,
    public dataSharingService: DataSharingService) {
    var date = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
    var isPrincipal = this.encryptDecryptService.getLocalStorage('isPrincipal');
    if (isPrincipal == true) {
      this.isPrincipal = true
    }
    date.setFullYear(date.getFullYear() - 18);
    this.myDatePickerOptions = {
      // other options...
      dateFormat: 'mm-dd-yyyy',
      disableSince: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() + 1 },
      disableUntil: { year: date.getFullYear() - 100, month: date.getMonth() + 1, day: date.getDate() + 1 },
      showClearDateBtn: false,
      editableDateField: false,
      openSelectorOnInputClick: true
    };
    this.PreLoaderForProfile = true;

    this.createForm();
    this.router.url == '/admin/profile' ? this.switchAdmin = false : this.switchAdmin = true;
  }

  ngOnInit() {
    this.goTop();
    this.loadScripts();
  }
  changeProfile() {
    if (this.router.url == '/admin/profile') {
      this.router.navigate(['/user/dashboard'])
    }
    else {
      this.router.navigate(['/admin/dashboard'])
    }
  }
  goTop() {
    if ($(window).width() < 1200) {
      $('.main-container').scrollTop(0);
    }
  }
  createForm() {
    this.profileForm = new FormGroup({
      userName: new FormControl('', [Validators.required, Validators.pattern(/^[^\s].+[^\s]$/)]),
      racingName: new FormControl(''),
      teamName: new FormControl('', [Validators.required, Validators.pattern(/^[^\s].+[^\s]$/)]),
      dob: new FormControl(''),
      phone: new FormControl('', [Validators.minLength(10),Validators.pattern(/^[+-]?\d*\.\d+$|^[+-]?\d+(\.\d*)?$/)]),
      email: new FormControl('', [Validators.email, Validators.required]),
      status: new FormControl(''),
    });
    this.changePswdForm = new FormGroup({
      oldPswd: new FormControl('', [Validators.required]),
      newPswd: new FormControl('', [Validators.required]),
      confirmPswd: new FormControl('', [Validators.required])
    })
    this.getUserProfile();
    this.profileForm.valueChanges.subscribe(res=>{
      // console.log(res);
      // console.log(this.profileForm);
    });
  }

  getUserProfile() {
    this.genericService.getAll(this.getProfileUrl + "?userId=" + this.encryptDecryptService.getLocalStorage("userId")).subscribe(response => {
      this.PreLoaderForProfile = true;
      if (response.status.success == "Success") {
        this.PreLoaderForProfile = false;
        this.userObj = response.data;
        if (this.userObj.profileImage != null) {
          this.uploadedProfilePic = true;
          this.profileSrc = this.userObj.profileImage;
        }
        if (this.userObj.team != null) {
          this.profileForm.controls['teamName'].setValue(this.userObj.team.name);
          if (this.userObj.team.profileImage != null) {
            this.uploadTeamPic = true;
            this.teamSrc = this.userObj.team.profileImage;
          }
        }

        // if (this.userObj.subscriptionEndDate != null) {
        //   var dob = new Date(this.userObj.subscriptionEndDate);
        //   var month = (+dob.getMonth() + 1).toString().length == 1 ? ("0" + (+dob.getMonth() + 1)) : (+dob.getMonth() + 1);
        //   var date = dob.getDate().toString().length == 1 ? ("0" + dob.getDate()) : dob.getDate();
        //   this.userObj.subscriptionEndDate =   date +"-"+ month + "-"+dob.getFullYear();
        // }
        var dateArray = this.userObj.dobString.split('-')
        //this is for datepicker purpose
        var month = dateArray[0];
        if (month) {
          if (month.charAt(0) == 0) {
            month = month.charAt(1);
          }
          var day = dateArray[1];
          if (day.charAt(0) == 0) {
            day = day.charAt(1);
          }
        }
        var dateDob =dateArray[0]?{ date: { month: month, day: day, year: dateArray[2] } }:'';
        // this.profileForm.controls['userName'].setValue(this.userObj.userName);
        // this.profileForm.controls['racingName'].setValue(this.userObj.racingName);
        // this.profileForm.controls['phone'].setValue(this.userObj.phone);
        // this.profileForm.controls['dob'].setValue(dateDob);
        // this.profileForm.controls['email'].setValue(this.userObj.email);
        // this.profileForm.controls['status'].setValue(this.status);
        this.profileForm.patchValue({
          userName:this.userObj.userName,
          racingName:this.userObj.racingName,
          phone:this.userObj.phone,
          dob:dateDob,
          email:this.userObj.email,
          status:this.status,
        })
        this.status = this.userObj.subscriptionStatus;
        this.endsOn = this.userObj.subscriptionEndDate;

      }
    })

  }
  checkValidOrNotForProfile(file) {
    var valid: boolean = true;
    this.failureGif = NotificationConstants.getLinkPicture(this.failureUrl);
    if (file.size > 300000) {
      valid = false;
      this.errorMsgForProfile = 'File size should not Exceeds 300KB';
    }
    var fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length);
    if (fileExtension != "jpeg" && fileExtension != "png" && fileExtension != "jpg") {
      valid = false;
      // var temp=this.errorMsgForProfile
      this.errorMsgForProfile = NotificationConstants.correctFileTypeMsg;
    }
    if (valid == true) {
      this.errorMsgForProfile = undefined
      this.errorMsgForTeam = undefined
    }
    setTimeout(() => {
      this.slideOutRight = true;
      this.slideInRight = false;
    }, 3000)
    setTimeout(() => {
      this.slideOutRight = false;
      this.slideInRight = true;
      this.errorMsgForProfile = undefined,
        this.errorMsgForTeam = undefined
    }, 6000);
    return valid;

  }
  checkValidOrNotForTeam(file) {
    var valid: boolean = true;
    this.failureGif = NotificationConstants.getLinkPicture(this.failureUrl);
    if (file.size > 300000) {
      valid = false;
      this.errorMsgForTeam = 'File size should not Exceeds 300KB';
    }
    var fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length);
    if (fileExtension != "jpeg" && fileExtension != "png" && fileExtension != "jpg") {
      valid = false;
      // var temp=this.errorMsgForTeam
      this.errorMsgForTeam = NotificationConstants.correctFileTypeMsg;
    }
    if (valid == true) {
      this.errorMsgForTeam = undefined
      this.errorMsgForProfile = undefined
    }
    setTimeout(() => {
      this.slideOutRight = true;
      this.slideInRight = false;
    }, 3000)
    setTimeout(() => {
      this.slideOutRight = false;
      this.slideInRight = true;
      this.errorMsgForProfile = undefined,
        this.errorMsgForTeam = undefined
    }, 6000);
    return valid;

  }

  readURL(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (this.checkValidOrNotForProfile(file) == true) {
        this.userImage = file;
        this.uploadedProfilePic = true;
        const reader = new FileReader();
        reader.onload = e => this.profileSrc = reader.result;

        reader.readAsDataURL(file);
      }

    }
  }

  readURLForTeam(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (this.checkValidOrNotForTeam(file) == true) {
        this.teamImage = file;
        this.uploadTeamPic = true;
        const reader = new FileReader();
        reader.onload = e => this.teamSrc = reader.result;
        reader.readAsDataURL(file);
      }
    }
  }

  setPayLoad() {
    this.reqPayLoad = {
      "f1ApplicationUserId": this.userObj.f1ApplicationUserId,
      "updatedBy": +this.encryptDecryptService.getLocalStorage('userId'),
      "team": {
        "f1TeamId": this.userObj.team.f1TeamId,
        "name": this.profileForm.controls['teamName'].value,
        "updatedBy": +this.encryptDecryptService.getLocalStorage('userId'),
      },
      "userName": this.profileForm.controls['userName'].value,
      "phone": this.profileForm.controls['phone'].value
    }
    this.validatingChanges();

  }
  validatingChanges() {
    if (this.userObj.team.name == this.profileForm.controls['teamName'].value) {
      this.isTeamNameChange = false;
    }
    else {
      this.isTeamNameChange = true;
    }
    this.teamImage != null && this.teamImage != undefined ? this.isTeamImgChange = true : this.isTeamImgChange = false;
  }
  updateProfile() {
    this.PreLoaderForProfileSave = true;
    this.setPayLoad();
    var dob = this.profileForm.controls['dob'].value.formatted;
    if (dob != undefined) {
      // var dateArray = dob.split(".");
      // this.reqPayLoad.dobString = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
      this.reqPayLoad.dobString = dob
    }
    let eee = JSON.stringify(this.reqPayLoad);
    let formdata: FormData = new FormData();
    formdata.append('user', eee);
    this.userImage != null || this.userImage != undefined ? formdata.append('userProfileImage', this.userImage) : formdata.append('userProfileImage', null);
    this.teamImage != null || this.teamImage != undefined ? formdata.append('teamProfileImage', this.teamImage) : formdata.append('teamProfileImage', null);
    this.genericService.imagePost(this.updateProfileUrl + '?isTeamNameChange=' + this.isTeamNameChange + '&isTeamImgChange=' + this.isTeamImgChange, formdata).subscribe(response => {
      this.PreLoaderForProfileSave = false;
      if (response.status.success == 'Success') {
        this.dataSharingService.updateUserName(this.reqPayLoad.userName);
        this.dataSharingService.updateTeamName(this.reqPayLoad.team.name);
        this.dataSharingService.updateUserImage(response.data.profileImage);


        //for localstorage......
        this.encryptDecryptService.setToLocalStorage('userName', this.reqPayLoad.userName);
        this.encryptDecryptService.setToLocalStorage('teamName', this.reqPayLoad.team.name);
        this.encryptDecryptService.setToLocalStorage('userImage', response.data.profileImage);
        this.getUserProfile();
        this.editProfile = false;
      }

    }, error => {
      this.PreLoaderForProfileSave = false;

    })
  }
  changePswd() {
    if (this.changePswdForm.valid == true && this.changePswdForm.value.newPswd == this.changePswdForm.value.confirmPswd) {
      this.PreLoaderForChangePswd = true;
      var reqPayload;
      var encryptedNewPassword = this.commonService.reverseString(this.changePswdForm.value.confirmPswd);
      var encryptedOldPassword = this.commonService.reverseString(this.changePswdForm.value.oldPswd);
      reqPayload = {
        "email": this.userObj.email,
        "oldPassword": encryptedOldPassword,
        "password": encryptedNewPassword
      }
      this.genericService.post(this.changePasswordUrl, reqPayload).subscribe(response => {
        if (response.status.success == 'Success') {
          sessionStorage.clear();
          $("#changePwd").modal('hide');
          setTimeout(() => {
            $("#changePasswordAlert").modal('show');
          }, 500);

        }
        this.PreLoaderForChangePswd = false;
      })
    }

  }


  getSubcriptionDetails() {
    this.genericService.getAll(this.getSubcriptionURL).subscribe(response => {
      console.log(response);
      if (response.status.success == 'Success') {
        var date: any = response.data.paymentDueDate;
        response.data.paymentDueDate = date.split('-')[2].split('T')[0] + '-' +
          date.split('-')[1] + '-' + date.split('-')[0];
        response.data.membershipFee = '₹ ' + response.data.membershipFee;
        response.data.penalityFee = '₹ ' + response.data.penalityFee;
        response.data.durationForPenality = (response.data.durationForPenality ? response.data.durationForPenality : 0) + ' days';
        this.subscriptionDetails = response.data;
      }
    })
  }



  loadScripts() {
    const dynamicScripts = [
      'https://checkout.razorpay.com/v1/checkout.js',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  getPayableAmount() {
    var deadLineDate = new Date('2020-03-11');
    const date = new Date();
    const penalty = date.getTime() >= deadLineDate.getTime();

    const amount = 1;
    //  penalty ? environment.amount + environment.penalty : environment.amount;

    return this.amountToBePaid = amount;

  }

  invokeRazorPay(e) {

    var tempThis = this;

    this.amountToBePaid = this.getPayableAmount() * 100;
    let requestObject = {
      "amount": this.amountToBePaid,
      "currency": "INR",
      "receipt": "receipt-123",
      "payment_capture": true
    }
    tempThis.genericService.post(this.fetchOrderIdUrl, requestObject, false).subscribe(res => {
      console.log(res);
      tempThis.orderId = res.data;
      var options = {
        "key": "rzp_live_erBFM6o0o5TO5V",// testModekey =>   rzp_test_3tMaChjQy0fFTo
        "amount": tempThis.amountToBePaid, // 2000 paise = INR 20
        "name": "CloudNow Technologies",
        "description": "Payment Page",
        "image": "../../../../assets/img/1.jpg",
        "handler": function (response) {
          console.log(response);
          tempThis.verifySign(response);
        },
        "prefill": {
          "name": tempThis.userObj.userName,
          "email": tempThis.userObj.email,
        },
        "modal": {
          "ondismiss": function (response) {
          }
        },
        "notes": {
          "address": "Hello World"
        },
        "theme": {
          "color": "#528FF0"
        },
        "order_id": tempThis.orderId
      };

      var rzp1 = new Razorpay(options);
      rzp1.open();
      e.preventDefault();

    });
  }

  verifySign(response) {
    let signatureRequestObject = {
      "razorpay_order_id": response.razorpay_order_id,
      "razorpay_payment_id": response.razorpay_payment_id,
      "razorpay_signature": response.razorpay_signature,
      "grossAmount": this.amountToBePaid,
    };
    this.PreLoaderForRazorPay = true;
    return this.genericService.post(this.verySignatureUrl + "?userId=" + this.userObj.f1ApplicationUserId, signatureRequestObject).subscribe(res => {
      this.PreLoaderForRazorPay = false;
      if (res.status.success == 'Success') {
        this.getUserProfile();
        this.amountToBePaid = 1;
      }
    }, error => {

    });

  }

}
export class SubscriptionDetails {
  subscriptionDetailId: any;
  membershipFee: any;
  paymentDueDate: any;
  penalityFee: any;
  durationForPenality: any;
}