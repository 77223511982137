import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RaceResultRoutingModule } from './race-result-routing.module';
import { RaceResultComponent } from './race-result/race-result.component';
import { UpdateRaceEntryResultComponent } from './update-race-entry-result/update-race-entry-result.component';
import { RaceEntryModule } from 'src/app/user-module/race-entry-module/race-entry.module';
import { MyDatePickerModule } from 'mydatepicker';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [RaceResultComponent, UpdateRaceEntryResultComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    MyDatePickerModule,
    ReactiveFormsModule,
    RaceResultRoutingModule,
    RaceEntryModule
  ]
})
export class RaceResultModule { }
