<div *ngIf="failureMsg!=undefined||failureMsg!=null" [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"class="alert alert-icon alert-danger fade show animated faster"
  role="alert">
  <div class="gif-icon">
      <img src={{failureGif}} alt="success-icon"  />
    </div>
  {{failureMsg}}
</div>


<div class="row h-100 m-0">
  <div class="col-lg-12 h-100">
    <div class="card shadow-sm rounded view-player">
      <div class="card-title bg-blue">
        <h4>Rules Engine</h4>
        <ul class="list-unstyled card-title-details">
          <!-- <li><a href="javascript:;" class="btn btn-outline-primary" data-toggle="modal" data-target="#addUser">Add
          Team</a></li> -->
        </ul>
      </div>
      <div class="card-body rules-engine">
        <div class="table-responsive">
          <table class="table table-dark table-hover">
            <thead>
              <tr>
                <th class="criteria">Criteria</th>
                <th class="desc">Description</th>
                <th class="points">Points</th>
                <th class="action edit text-center">Action</th>
              </tr>
            </thead>
            <tbody>
             
              <tr *ngFor="let rule of rules">
                <td>{{rule.orderType}}</td>
                <td>{{rule.description}}</td>
                <td class="points" [hidden]="rule.ruleCondition==null">
                  <div class="points-details">
                    <div class="points-value" *ngIf="rule.ruleCondition[0]!=undefined">
                      <span *ngIf="rule.orderType!='Ladder Match'">1st</span>
                      <span *ngIf="rule.orderType=='Ladder Match'">Won</span>
                      <input type="text" pointsValidation [(ngModel)]="rule.ruleCondition[0].points" [disabled]="rule.isDisable" />
                    </div>
                    <div class="points-value" *ngIf="rule.ruleCondition[1]!=undefined">
                      <span *ngIf="rule.orderType!='Ladder Match'">2nd</span>
                      <span *ngIf="rule.orderType=='Ladder Match'">Lost</span>
                      <input type="text" pointsValidation [(ngModel)]="rule.ruleCondition[1].points" [disabled]="rule.isDisable" />
                    </div>
                    <div class="points-value" *ngIf="rule.ruleCondition[2]!=undefined">
                      <span *ngIf="rule.orderType!='Ladder Match'">3rd</span>
                      <span *ngIf="rule.orderType=='Ladder Match'">Tie</span>
                      <input type="text" pointsValidation [(ngModel)]="rule.ruleCondition[2].points" [disabled]="rule.isDisable" />
                    </div>
                    <div class="points-value" *ngIf="rule.ruleCondition[3]!=undefined">
                      <span>4th</span>
                      <input type="text"pointsValidation [(ngModel)]="rule.ruleCondition[3].points" [disabled]="rule.isDisable" />
                    </div>
                    <div class="points-value" *ngIf="rule.ruleCondition[4]!=undefined">
                      <span>5th</span>
                      <input type="text" pointsValidation [(ngModel)]="rule.ruleCondition[4].points" [disabled]="rule.isDisable" />
                    </div>
                    <div class="points-value" *ngIf="rule.ruleCondition[5]!=undefined">
                      <span>6th</span>
                      <input type="text" pointsValidation  [(ngModel)]="rule.ruleCondition[5].points" [disabled]="rule.isDisable" />
                    </div>
                  </div>
                </td>
                <td class="text-center action edit">
                  <div>
                    <a href="javascript:;" class="btn btn-outline-secondary" *ngIf="rule.showEdit==true" (click)="onEditOfRule(rule)"><i class="fa fa-edit"></i></a>
                    <a href="javascript:;" class="btn btn-outline-secondary" *ngIf="rule.showSave==true" (click)="onSave(rule)"><i class="fa fa-save"></i></a>
                    <a href="javascript:;" class="btn btn-outline-secondary btn-save d-none">Save</a>
                  </div>
                </td>
              </tr>
              <tr *ngIf="lapDetailObj!=undefined">
                <td>{{lapDetailObj.orderType}}</td>
                <td>{{lapDetailObj.description}}</td>
                 <td class="points" >
                  <div class="points-details">
                    <div class="points-value">
                      <span>FL</span>
                      <input type="text" pointsValidation [(ngModel)]="lapDetailObj.rulePoleCondition.fastestLap" [disabled]="lapDetailObj.isDisable" />
                    </div>
                    <div class="points-value">
                      <span>MPG</span>
                      <input type="text" pointsValidation [(ngModel)]="lapDetailObj.rulePoleCondition.mostPlacesGained" [disabled]="lapDetailObj.isDisable" />
                    </div>
                    <div class="points-value">
                      <span>PP</span>
                      <input type="text" pointsValidation [(ngModel)]="lapDetailObj.rulePoleCondition.position" [disabled]="lapDetailObj.isDisable" />
                    </div>
                    
                  </div>
                </td>
                <td class="text-center action edit">
                  <div>
                    <a href="javascript:;" class="btn btn-outline-secondary" *ngIf="lapDetailObj.showEdit==true" (click)="onEditOfRule(lapDetailObj)"><i class="fa fa-edit"></i></a>
                    <a href="javascript:;" class="btn btn-outline-secondary" *ngIf="lapDetailObj.showSave==true" (click)="onSave(lapDetailObj)"><i class="fa fa-save"></i></a>
                    <a href="javascript:;" class="btn btn-outline-secondary btn-save d-none">Save</a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="box-loader" *ngIf="preLoader">
          <img src="assets/img/progress.svg" alt="Progress">
        </div>
      </div>
    </div>
  </div>
</div>