import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RaceConstructorComponent } from './race-constructor/race-constructor.component';
const routes: Routes = [
  {
    path:'raceConstructor',
    component:RaceConstructorComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RaceConstructorRoutingModule { }
