import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { ActivatedRoute, Router, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment } from '@angular/router';
import { NotificationConstants } from 'src/app/constants/notification-constants';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  public forgetpasswordForm;
  public forgetPasswordUrl = UrlConstants.forgetPasswordUrl + '?email=';
  public showerrorMessage: any;
  public expired = false;
  public message: any;
  PreLoader = false; 
  successUrl="../../../../assets/img/success.gif";
  successGif;
  slideOutRight;
  slideInRight=true;
  failureUrl="../../../../assets/img/failure.gif";
  failureGif;
  constructor(public _genericService: GenericAPIService,
    public activatedRoute: ActivatedRoute, public router: Router) {
    this.createForm();
  }
  ngOnInit() {
    this.getRouterParamValue();
  }
  getRouterParamValue() {
    let location = this.router.url;
    const tree: UrlTree = this.router.parseUrl(location);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;
    s[0].parameters.expired == 'true' ? this.expired = true : this.expired = false;
    this.failureGif=NotificationConstants.getLinkPicture(this.failureUrl);
    setTimeout(() => {
      this.slideOutRight=true;
      this.slideInRight=false;
    },3000)
    setTimeout(() => {
      this.slideOutRight=false;
      this.slideInRight=true;
      this.expired = false;
    }, 6000);
  
  }
  createForm() {
    this.forgetpasswordForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required])
    })
  }

  send() {
    this.message = undefined;
    this.showerrorMessage = undefined;
    this.PreLoader=true
    this._genericService.post(this.forgetPasswordUrl + this.forgetpasswordForm.value.email, '').subscribe(response => {
     if(response.status.success=="Success"){
      this.successGif=NotificationConstants.getLinkPicture(this.successUrl);
      this.message = NotificationConstants.forgetPasswordSuccess;
     }
     else{
      this.failureGif=NotificationConstants.getLinkPicture(this.failureUrl);
      this.showerrorMessage = NotificationConstants.forgetPasswordFailure ;
     }
     this.PreLoader = false;
    this.animationMsg();
     },error =>{
      this.PreLoader = false;
     });
  }
  animationMsg(){
    setTimeout(() => {
      this.slideOutRight=true;
      this.slideInRight=false;
    },3000)
    setTimeout(() => {
      this.slideOutRight=false;
      this.slideInRight=true;
      this.showerrorMessage = undefined;
      this.message = undefined;
    }, 6000);
  }
}
