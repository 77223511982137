import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { UrlConstants } from 'src/app/constants/url-constants';
import { commonService } from 'src/app/services/commonMethodService';
import { NotificationConstants } from 'src/app/constants/notification-constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public resetForm: FormGroup;
  paramEmail: any;
  paramToken: any;
  public message: any;
  public showerrorMessage: any;
  public validateResetTokenoUrl=UrlConstants.validateResetToken;
  setPasswordUrl=UrlConstants.setPasswordUrl;
  public showConfirmPswd:boolean=false;
  public show:boolean=false;

  public valid=false;
  public PreLoader=false;
  constructor(
    public activatedRoute:ActivatedRoute,
    public route:Router,
    public _genericService:GenericAPIService,
    public commonService:commonService) {
    this.createForm();
    sessionStorage.setItem("token","");
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
     this.paramEmail=params.email;
     this.paramToken=params.token;
  });
  this.validateToken();
  }
  createForm() {
    this.resetForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required,Validators.minLength(8)])
    })
  }
  validateToken(){
    this._genericService.post(this.validateResetTokenoUrl+'?email='+this.paramEmail+'&token='+this.paramToken,'').subscribe(response=>{
      let validToken=response.status.success;
      if(validToken=="Fail"){
        this.route.navigate(['/forgetPassword', { expired: 'true' }]);
      } 
    });
  
  }
  getBytes(str) {
    var bytes = [];
    for (var i = 0; i < str.length; ++i) {
      bytes.push(str.charCodeAt(i));
    }
    var a = (new String(str.length)).charCodeAt(0);
    bytes=[a,...bytes,a]
    return bytes;
  }

   reverseString(str) {
    var splitString = str.split(""); 
    var reverseArray = splitString.reverse();
    var joinArray = reverseArray.join(""); 
    return joinArray;
}
  reset() {
    this.PreLoader=true;
    this.message = undefined;
    this.showerrorMessage = undefined;
    if(this.resetForm.value.password==this.resetForm.value.confirmPassword){
     //encoding password
     var encryptedPassword = this.commonService.reverseString(this.resetForm.value.password);
      let reqPayload={
        "email":this.paramEmail,
        "password":encryptedPassword
      }
      this._genericService.post(this.setPasswordUrl,reqPayload).subscribe(response=>{
        if(response.status.success=="Success"){
          this.PreLoader=false;
       //   this.message = NotificationConstants.resetPasswordSuccess;
          setTimeout(() => {
          //  this.message= undefined;
            this.route.navigate(['/signIn']);
          }, 2000);
        }
        // else{
        //   this.showerrorMessage = response.status.message ;
        //   setTimeout(() => {
        //     this.showerrorMessage  =undefined
        //   }, 2000);
        // }
      })
    } 
}
}
