import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { UrlConstants } from 'src/app/constants/url-constants';
import { addClass } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-race-driver',
  templateUrl: './race-driver.component.html',
  styleUrls: ['./race-driver.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RaceDriverComponent implements OnInit {

  public raceDriverUrl = UrlConstants.raceDrivers;
  public masterDriverurl = UrlConstants.driverMDList;
  public saveDriverToRaceUrl = UrlConstants.saveDriverTrx;


  public raceId = 0;
  public raceMode;
  public raceDrivers = [];
  public masterDrivers = [];
  public preLoader;
  public showEditButton = false;
  public selectedIndex = -1;
  public raceInfoUrl = UrlConstants.currentYearRaceInfo;
 // public raceInfoUrl = UrlConstants.raceInfo;
  public circuitDetail = undefined;
  incompletedDriver: number;
  raceList: any=[];
  constructor(public activatedRoute: ActivatedRoute, public genericAPIService: GenericAPIService) {
    this.activatedRoute.queryParams.subscribe((data) => {
      this.raceId = data.raceId;
      this.raceMode = data.mode;
      if (this.raceMode == 'edit') {
        this.showEditButton = true;
      }
      else {
        this.showEditButton = false;
      }
    })
  }

  ngOnInit() {
    this.getRaceInfo();
    this.getDriverOfRace();
  }

  getDriverOfRace() {
    //default 20 drivers with emplty frame
    if (this.raceDrivers.length < 20) {
    for (let i = 1; i <= 20; i++) {
      var driver = this.creatDriverObj();
      this.raceDrivers.push(driver);
    }
    }
    this.preLoader = true;
    this.genericAPIService.getAll(this.raceDriverUrl + '?raceId=' + this.raceId).subscribe(response => {
      if (response.status.success == "Success") {
        this.raceDrivers = response.data;
        this.preLoader = false;
      }
      else {
        this.preLoader = false;
      }
      this.getMasterDriver();

    }, error => {
      this.preLoader = false;
    })

  }
  creatDriverObj() {
    var obj = {
      driverId: 0,
      driverPic: '',
      name: '',
      selected:false
    }
    return obj;
  }

  getMasterDriver() {
    this.preLoader = true;
    this.genericAPIService.getAll(this.masterDriverurl).subscribe(response => {
      if (response.status.success == "Success") {
        this.masterDrivers = response.data;
        var drivers = this.raceDrivers;
        for (let driver of drivers) {
          if (driver.driverMaster != null) {
            var index = this.masterDrivers.findIndex(c => c.driverMasterId == driver.driverMaster.driverMasterId);
            this.masterDrivers.splice(index, 1);
          }

        }

        this.preLoader = false;
      }
      else {
        this.preLoader = false;
        this.masterDrivers = [];
      }
    }, error => {
      this.masterDrivers = [];
      this.preLoader = false;
    })
  }

  removeFromDriverList(driverObj) {
    this.masterDrivers.splice(driverObj.driverId - 1, 0,driverObj);
    const indexOfSelectedDriverList = this.raceDrivers.indexOf(driverObj);
    this.raceDrivers[indexOfSelectedDriverList] = this.creatDriverObj();
    this.addDriver(indexOfSelectedDriverList);
    this.masterDrivers.sort(function (a, b) {
      if(a.name!=undefined){
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0
      } //default return value (no sorting)
    })
  setTimeout(() => {
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("race-entry-ranking");
    for (var i = 0; i < btns.length; i++) {
      var current = document.getElementsByClassName(" selected");
      if (current.length > 0) {
        current[0].className = current[0].className.replace(" selected", "");
      }
    }
    btns[indexOfSelectedDriverList].className += ' selected';
  }, 100);
  }

  addDriver(index) {
    this.selectedIndex = index;
  }
  addMaster(driverObj) {
    var index;
    index = this.masterDrivers.findIndex(c => (c.hasOwnProperty('driverMaster')==true?
    c.driverMaster.driverMasterId == driverObj.driverMaster.driverMasterId 
    : c.driverMasterId == driverObj.driverMasterId));//master data selected driver postion..
    if (this.selectedIndex != -1) {;//racedriver data ==>selected driver postion..
     if(this.raceDrivers[this.selectedIndex].selected==false){
      this.raceDrivers[this.selectedIndex] = this.masterDrivers[index];
      this.raceDrivers[this.selectedIndex].selected=true;
      this.masterDrivers.splice(index, 1);
      this.autoToggle();
    }}
  }

  saveDrivers() {
    var drivers = [];
    for (let cons of this.raceDrivers) {
      if (cons.hasOwnProperty('driverMasterId')) {
        var driver = {
          "driverMaster": {
            "driverMasterId": cons.driverMasterId,
          }
        }
        drivers.push(driver)
      }
    }

    for (let cons of this.masterDrivers) {
      if (cons.hasOwnProperty('driverMaster')) {
        var driver1 = {
          "driverId": cons.driverId,
          "driverMaster": {
            "driverMasterId": cons.driverMaster.driverMasterId
          }
        }
        drivers.push(driver1)
      }
    }
     
      this.preLoader = true;
      this.genericAPIService.post(this.saveDriverToRaceUrl + '?raceId=' + this.raceId, drivers).subscribe(response => {
        if (response.status.success == "Success") {
          this.getDriverOfRace();
        }
        else {
          this.preLoader = false;

        }
      }, error => {
        this.preLoader = false;
      })
    

  }

  enableSaveButton() {
    function isSelected(element, index, array) {
      if (element.driverId != 0) {
        return true;
      }
    }
    var isAllDriverSelected = this.raceDrivers.every(isSelected);
    if (isAllDriverSelected == true) {
      return false
    }
    else {
      return true;
    }
  }

  getRaceInfo() {
    this.genericAPIService.getAll(this.raceInfoUrl).subscribe(response => {
      if (response.status.success == "Success") {
        this.raceList = response.data;
        for (let race of this.raceList) {
          if (race.raceId == this.raceId) {
            this.circuitDetail = race.circuits;
          }
        }
      }
      else {
        this.raceList = undefined
      }
    }, error => {
      this.raceList = undefined
    })
  }
  addscss() {
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("race-entry-ranking");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName(" selected");
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" selected", "");
        }
        this.className += " selected";
      });
    }
  }
  autoToggle(){
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("race-entry-ranking");
    this.incompletedDriver =this.raceDrivers.findIndex(x => x.selected == false);
    if (this.incompletedDriver != -1) {
      this.selectedIndex=this.incompletedDriver;
      btns[this.incompletedDriver].setAttribute('class','race-entry-ranking selected');
    }
  }
}
