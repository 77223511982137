import { Component, OnInit } from '@angular/core';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { NotificationConstants } from 'src/app/constants/notification-constants';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { append } from '@syncfusion/ej2-base';
declare var $: any;
@Component({
  selector: 'app-team-management-list',
  templateUrl: './team-management-list.component.html',
  styleUrls: ['./team-management-list.component.scss']
})
export class TeamManagementListComponent implements OnInit {
  public teamListUrl = UrlConstants.teamListAndPlayer;
  public userswithOutTeamUrl = UrlConstants.userswithOutTeamUrl;
  public saveTeamUrl = UrlConstants.addTeamWithuser;
  public teamList: any;
  public PreLoader: boolean = false;
  public addTeamForm: FormGroup;
  public userList = [];
  public failureMsg;
  public userId;
  teamImage: File;
  public teamSrc;
  payload: {};
  isEdit: boolean = false;
  editUserDetail: any;
  public uploadTeamPic = false;
  // member1Edit = false;
  // member2Edit = false;
  // member3Edit = false;
  // member4Edit = false;
  memberEdit = false;

  // canChangeMember1 = false;
  // canChangeMember2 = false;
  // canChangeMember3 = false;
  // canChangeMember4 = false;

  // showMember1error = false;
  // showMember2error = false;
  // showMember3error = false;
  // showMember4error = false;

  // public member1;
  // public member2;
  // public member3;
  // public member4;


  public PreLoaderForSave = false;
  failureUrl = "../../../../assets/img/failure.gif";
  failureGif;
  slideOutRight;
  slideInRight;
  memberArray: FormArray;
  constructor(public _genericService: GenericAPIService,
    public encryptDecryptService: EncryptDecryptService, private fb: FormBuilder) {
    this.createForm();
    this.getAllTeam();
    this.getAllUsersWithoutTeam();
    this.userId = + this.encryptDecryptService.getLocalStorage("userId");
  }

  ngOnInit() {

  }
  getAllTeam() {
    this.PreLoader = true;
    this._genericService.getAll(this.teamListUrl).subscribe(response => {
      if (response.status.success = "Success") {
        this.teamList = response.data;
      }
      // else {
      //   this.failureMsg = NotificationConstants.errormsg;
      // }
      this.PreLoader = false;
    }, error => {
      //  this.failureMsg = NotificationConstants.errormsg;
      this.PreLoader = false;
    })
  }

  createForm() {
    this.addTeamForm = this.fb.group({
      teamName: ['', [Validators.required, Validators.pattern(/^[^\s].+[^\s]$/)]],
      members: this.fb.array([], [Validators.required,this.minLengthArray(4),this.maxLengthArray(5)]),
    

    });

    this.subscribeForm();

  }

  minLengthArray(min: number) {
    
    return (c: AbstractControl): {[key: string]: any} => {
      
        if (c.value.length >= min)
            return null;

        return { 'minLengthArray': {valid: false }};
    }
}

maxLengthArray(max: number){
  return (c: AbstractControl): {[key: string]: any} => {
    
      if (c.value.length <= max)
          return null;

      return { 'maxLengthArray': {valid: false }};
  }
}

  private subscribeForm() {
    this.addTeamForm.get('members').valueChanges.subscribe(value => {
      // console.log(value);
      // console.log(this.addTeamForm.controls);
    });

    console.log(this.addTeamForm.get('members').errors);
    
  }

  createMember(member?): FormGroup {
    return this.fb.group({
      member: [member ? member.f1ApplicationUserId : '', [Validators.required]],
      userName: [member ? member.userName : ''],
      isActive: [member ? member.isActive : ''],
      editMember: [member ? false : true]
    });
  }

  addMember(): void {
    this.memberArray = this.addTeamForm.get('members') as FormArray;
    if(this.memberArray.length<=4){
      this.memberArray.push(this.createMember());
    }
  }

  removeMember(index) {
    this.memberArray = this.addTeamForm.get('members') as FormArray;
    this.memberArray.removeAt(index);
  }


  getAllUsersWithoutTeam() {
    this._genericService.getAll(this.userswithOutTeamUrl).subscribe(response => {
      if (response.status.success = "Success") {
        this.userList = response.data;
      }
      // else {
      //   this.failureMsg = NotificationConstants.errormsg;
      // }
    }, error => {
    })
  }
  setEditPermisson(value: boolean) {
    this.isEdit = !value;
    this.createForm();
    if (value) {
      $("#addTeam").modal("show");
      for (let i=0; i <= 3; i++) {
        this.addMember();
      }
    }
  }

 


  getFormControles(mem) {
    var members = mem.getRawValue();
    return members;
  }

  changeMeber(memberId, index) {
    const id = parseInt(memberId.split(':')[1]);
    const user = this.findIdByUserName(id);
    this.memberArray = this.addTeamForm.get('members') as FormArray;
    this.memberArray.controls[index].patchValue({
      member: user.f1ApplicationUserId,
      userName: user.userName,
      isActive: user.isActive,
    })
    // get('member').setValue(user.f1ApplicationUserId);

  }

  editSelectedMember(type,index) {
    if(type=='edit'){ 
      this.memberArray.controls[index].get('editMember').setValue(true);
    }else if (type='close'){
      this.memberArray.controls[index].get('editMember').setValue(false);
    }

  }

  editTeam(updateteam) {
    this.editUserDetail = updateteam;
    this.setEditPermisson(false);
    $("#addTeam").modal("show");
    if (updateteam != null) {
      if (updateteam.users.length > 0) {

        this.memberArray = <FormArray>(
          this.addTeamForm.get("members")
        );

        updateteam.users.forEach(user => {
          this.memberArray.push(this.createMember(user))
        });
      }
      if (updateteam.team != null || undefined) {
        this.addTeamForm.controls['teamName'].setValue(updateteam.team.name);
        if (updateteam.team.profileImage != null) {
          this.uploadTeamPic = true;
          this.teamSrc = updateteam.team.profileImage;
        }
      }
    }

  }

  checkValidOrNotForTeam(file) {
    var valid: boolean = true;
    if (file.size > 300000) {
      valid = false;
      this.failureMsg = 'File size should not Exceeds 300KB';
    }
    var fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length);
    if (fileExtension != "jpeg" && fileExtension != "png" && fileExtension != "jpg") {
      valid = false;
      this.failureMsg = NotificationConstants.correctFileTypeMsg;
    }
    this.messageAnimation();
    if (valid == true) {
      this.failureMsg = undefined
    }
    return valid;
  }
  setUpdatePayLoad() {
    this.payload = {
      "f1TeamId": this.editUserDetail.team.f1TeamId,
      "name": this.addTeamForm.value.teamName,
      "updatedBy": this.userId,
      "isActive": true,
      "users": this.getSelectedMembers()
    }
    console.log(this.getSelectedMembers());


    let formdata: FormData = new FormData();
    let eee = JSON.stringify(this.payload);
    formdata.append('team', eee);
    this.teamImage != null || this.teamImage != undefined ? formdata.append('teamImage', this.teamImage) : formdata.append('teamImage', null);
    return formdata;
  }

  private getSelectedMembers() {
    var arr = [];

    this.memberArray = <FormArray>(
      this.addTeamForm.get("members")
    );
    this.memberArray.controls.forEach(element => {
      arr.push({
        "f1ApplicationUserId": this.getFormControles(element).member,
      })
    });
    return arr;
  }

  setSavePayload() {

    this.payload = {
      "name": this.addTeamForm.value.teamName,
      "createdBy": this.userId,
      "isActive": true,
      "users": this.getSelectedMembers()
    }

    console.log(this.payload);

    let formdata: FormData = new FormData();
    let eee = JSON.stringify(this.payload);
    formdata.append('team', eee);
    this.teamImage != null || this.teamImage != undefined ? formdata.append('teamImage', this.teamImage) : formdata.append('teamImage', null);
    return formdata;
  }



  readURLForTeam(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (this.checkValidOrNotForTeam(file) == true) {
        this.teamImage = file;
        this.uploadTeamPic = true;
        const reader = new FileReader();
        reader.onload = e => this.teamSrc = reader.result;
        reader.readAsDataURL(file);
      }
    }
  }

  messageAnimation() {
    this.failureGif = NotificationConstants.getLinkPicture(this.failureUrl);
    setTimeout(() => {
      this.slideOutRight = true;
      this.slideInRight = false;
    }, 3000)
    setTimeout(() => {
      this.slideOutRight = false;
      this.slideInRight = true;
      this.failureMsg = undefined;
    }, 6000);
  }
  addTeam() {

    if (this.addTeamForm.valid) {
      this.PreLoaderForSave = true;
      var arrValue: [] = this.addTeamForm.value.members.map(elm => {
        if (elm.member) {
          return elm.member
        }
      })
      var setArray: any = new Set(arrValue);
     

      if (setArray.size != arrValue.length) {
        this.failureMsg = NotificationConstants.duplicateMembers;
        this.messageAnimation();
        this.PreLoaderForSave = false;
      }
      else {
        var formdata:any;
        if (this.isEdit) {
          formdata = this.setUpdatePayLoad();
        }
        else {
          formdata = this.setSavePayload();
        }
        this._genericService.imagePost(this.saveTeamUrl, formdata).subscribe(response => {
          if (response.status.success == "Success") {
            $("#addTeam").modal("hide");
            this.teamList = []
            this.getAllTeam();
            this.getAllUsersWithoutTeam();
            this.addTeamForm.reset();
            this.teamSrc = undefined;
            this.PreLoaderForSave = false
            this.isEdit = false;
          }
          else {
            this.PreLoaderForSave = false
          }
          // this.PreLoader = false;

        }, error => {
          this.PreLoaderForSave = false
          this.PreLoader = false;
        })
      }
    }
    else {
      this.failureMsg = NotificationConstants.mandotoryMsg;
      this.messageAnimation();
    }

  }

  findIdByUserName(userId) {
    for (let user of this.userList) {
      if (user.f1ApplicationUserId == userId) {
        return user;
      }
    }
  }

}
