import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var twttr;
@Component({
  selector: 'app-view-race-prediction',
  templateUrl: './view-race-prediction.component.html',
  styleUrls: ['./view-race-prediction.component.scss']
})
export class ViewRacePredictionComponent implements OnInit {

  constructor(public router :Router) { }

  ngOnInit() {
    setTimeout(() => {
     // this.PreLoader=false;
      twttr.widgets.load(
        document.getElementById("container")
      );
      
    }, 1000);
  }

}
