import { NgModule } from '@angular/core';
import { DashboardModule } from './dashboard/dashboard.module';
import { LadderModule } from './ladder-module/ladder-module.module';
import { RaceEntryModule } from './race-entry-module/race-entry.module';
import { RaceSprintEntryModule } from './race-sprint-entry-module/race-sprint-entry.module';
import { RulesAndRegulationModule } from './rules-and-regulation/rules-and-regulation.module';
import { UserRoutingModule } from './user-routing';
import { UserProfileModule } from './user-profile/user-profile.module';
import { RacePredictionModule } from './race-prediction/race-prediction.module';

@NgModule({
  declarations: [],
  imports: [
    UserRoutingModule,
    DashboardModule,
    LadderModule,
    RaceEntryModule,
    RacePredictionModule,
    RulesAndRegulationModule,
    UserProfileModule,
    RaceSprintEntryModule
  ]
})
export class UserModule { }
