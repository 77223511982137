<!-- Alert Messages -->
<div *ngIf="failureMsg!=undefined||failureMsg!=null" [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"class="alert alert-icon alert-danger fade show animated faster"
  role="alert">
  <div class="gif-icon">
      <img src={{failureGif}} alt="success-icon"  />
    </div>
  {{failureMsg}}
</div>

<div class="card shadow-sm rounded view-player competitors">
    <div class="card-title bg-blue">
        <h4>Race Circuits</h4>
        <ul class="card-title-details float-right">
            <li><a class="btn btn-outline-primary" data-target="#addcircuit" (click)="resetFields();"
                    data-toggle="modal" href="javascript:;">Add
                    Circuit</a></li>
        </ul>
    </div>
    <div class="card-body race-circuits">
        <div class="table-responsive">
            <table class="table table-dark table-hover">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Circuit</th>
                        <th scope="col">Circuit Image</th>
                        <th scope="col">Circuit Icon</th>
                        <th class="action text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let circuit of circuitList;let i =index">
                        <td scope="row">{{i+1}}</td>
                        <td> {{circuit.name}}{{circuit.country!=undefined||circuit.country!=null?',' +circuit.country:' '}}
                        </td>
                        <td>
                            <div class="user-name-pic">
                                <span class="user-pic"  [ngStyle]="{backgroundImage: 'url('+circuit.circuitImage+')'}">
                                    <!-- <img src="{{circuit.circuitImage}}" alt="Circuit img"> -->
                                </span>
                                <span class="user-name"> {{circuit.name}} </span> </div>
                        </td>


                        <td class="user-name-pic">
                            <span class="user-pic" [ngStyle]="{backgroundImage: 'url('+circuit.circuitActive+')'}">
                                    <!-- <img src="{{circuit.circuitActive}}" alt="Circuit icon"> -->
                            </span>
                            <span class="user-name"> {{circuit.name}} </span></td>
                        <td class="text-center action">
                            <div>
                                <a class="btn btn-outline-secondary" href="javascript:;"
                                    (click)="getEditCircuitDetails(circuit);isEdit=true"><i
                                        class="fa fa-edit"></i></a><a class="btn btn-outline-secondary"
                                    data-target="#deletecircuit" (click)="deleteObj=circuit" data-toggle="modal"
                                    href="javascript:;"><i class="fa fa-trash-o"></i></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="noRecord" class="row align-items-center text-center">
            <div class="col-lg-12">
                <p>No records found</p>
            </div>
        </div>
    </div>
    <div class="box-loader" *ngIf="preloader">
        <img src="assets/img/progress.svg" alt="Progress">
      </div>
</div>



<!-- Add circuit -->
<div class="modal fade add-user" id="addcircuit" tabindex="-1" role="dialog" aria-labelledby="addUserTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{isEdit==false?'Add ':'Edit '}}Circuit</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-8">
                        <form [formGroup]="circuitForm">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Circuit Name<span
                                        class="star-icon">*</span></label>
                                <div class="col-sm-8">
                                    <input  maxlength="100" type="text" class="form-control" placeholder="Enter circuit name"
                                        formControlName="name" id="name" required>
                                    <small style="color:red"
                                        *ngIf="circuitForm.controls['name'].errors?.pattern;else invalidName">
                                        Enter valid circuit name without spaces at start and end
                                    </small>
                                    <ng-template #invalidName>
                                        <small
                                            *ngIf="circuitForm.controls['name'].errors?.required && !circuitForm.controls['name'].pristine"
                                            class="form-text" style="color:red">Enter circuit name</small>
                                    </ng-template>

                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Length<span
                                        class="star-icon">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" placeholder="Enter circuit length"
                                        formControlName="length" id="length" required>

                                    <small
                                        *ngIf="circuitForm.controls['length'].errors && !circuitForm.controls['length'].pristine"
                                        class="form-text" style="color:red">Enter circuit length</small>

                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Race Lap Record<span
                                        class="star-icon">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" placeholder="Enter Race Lap Record"
                                        formControlName="lapRecord" id="lapRecord" required>

                                    <small
                                        *ngIf="circuitForm.controls['lapRecord'].errors && !circuitForm.controls['lapRecord'].pristine"
                                        class="form-text" style="color:red">Enter race lap record</small>

                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Number of Turns<span
                                        class="star-icon">*</span></label>
                                <div class="col-sm-8">
                                    <input type="number" class="form-control" placeholder="Enter number of turns"
                                        formControlName="turns" id="turns" required>

                                    <small
                                        *ngIf="circuitForm.controls['turns'].errors && !circuitForm.controls['turns'].pristine"
                                        class="form-text" style="color:red">Enter
                                        number of turns</small>

                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Number of DRS Zones<span
                                        class="star-icon">*</span></label>
                                <div class="col-sm-8">
                                    <input type="number" class="form-control" placeholder="Enter number of DRS zones"
                                        formControlName="drsZone" id="drsZone" required>

                                    <small
                                        *ngIf="circuitForm.controls['drsZone'].errors && !circuitForm.controls['drsZone'].pristine"
                                        class="form-text" style="color:red">Enter number of DRS zones</small>

                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="col-lg-4">
                        <div class="user-profile-pic">
                            <div class="pic">
                                <div class="pic" *ngIf="uploadcircuitImg==true"
                                    [ngStyle]="{backgroundImage: 'url(' + circuitImgSrc+ ')'}">
                                </div>
                                <img *ngIf="uploadcircuitImg==false" src="../../../../assets/img/default.jpg"
                                    class="img-fluid" />
                                <div class="upload">
                                    <i class="fa fa-camera"> </i>
                                    <span> Upload Image
                                        <!-- <small>160 x 160</small> -->
                                    </span>
                                    <input type='file' (change)="readURLForImg($event);" />
                                </div>
                            </div>
                            <label>Circuit Image<span class="star-icon">*</span></label>
                        </div>
                        <div class="user-profile-pic">
                            <div class="pic">
                                <div class="pic" *ngIf="uploadcircuitIcon==true"
                                    [ngStyle]="{backgroundImage: 'url(' + circuitIconSrc+ ')'}">
                                </div>
                                <img *ngIf="uploadcircuitIcon==false" src="../../../../assets/img/default.jpg"
                                    class="img-fluid" />
                                <div class="upload">
                                    <i class="fa fa-camera"> </i>
                                    <span> Upload Image
                                        <!-- <small>160 x 160</small> -->
                                    </span>
                                    <input type='file' (change)="readURLForIcon($event);" />
                                </div>
                            </div>
                            <label>Circuit Icon<span class="star-icon">*</span></label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="circuitForm.invalid"
                    (click)="payloadFormation()">save</button>
            </div>
            <div class="box-loader" *ngIf="preloader">
                <img src="assets/img/progress.svg" alt="Progress">
              </div>
        </div>
    </div>
</div>


<!-- delete circuit popup -->
<div class="modal fade add-user delete-user" id="deletecircuit" tabindex="-1" role="dialog"
    aria-labelledby="deletecircuit" aria-hidden="true">
    <div *ngIf="!!deleteObj" class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Delete Circuit</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure you want to delete {{deleteObj.name}} ?
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-primary" (click)="cancelDelete()">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="delete()">Confirm</button>
            </div>
            <div class="box-loader" *ngIf="preloader">
                <img src="assets/img/progress.svg" alt="Progress">
              </div>
        </div>
    </div>
</div>