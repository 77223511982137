
<div class="card shadow-sm rounded view-player">
    <h4 class="card-title bg-blue">User</h4>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-dark table-hover" *ngIf="isUserList==true">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Team</th>
                        <th scope="col">Points</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users;let i=index">
                        <td scope="row">{{i+1}}</td>
                        <td class="user-name-pic"><span class="user-pic" [ngStyle]="{backgroundImage: 'url(' +user.profileImage+ ')'}">
                            </span>{{user.userName}}</td>
                        <td>{{user.team.name}}</td>
                        <td>-</td>
                </tbody>
            </table>
            <table class="table table-dark table-hover" *ngIf="isUserList==false">
                <thead>
                    <tr>
                        <th scope="col">Rank</th>
                        <th scope="col">User Name</th>
                        <th scope="col">Points</th>
                        <th scope="col">Team</th>
                        <th scope="col">Email Id</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of userResultList;let i=index" [class.active]="user.userId == userId">
                        <td scope="row">{{user.rank}}</td>
                        <td class="user-name-pic" routerLink='/user/userDetails/individualUserDetail/{{user.userId}}'><span
                                class="user-pic" [ngStyle]="{backgroundImage: 'url(' +user.userImage+ ')'}">
                            </span>{{user.userName}}</td>
                        <td>{{user.totalPoints}}</td>
                        <td>{{user.teamName}}</td>
                        <td>{{user.email}}</td>

                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row align-items-center  text-center" *ngIf="noPlayer==true">
            <div class="col-lg-12">
                <p>No records found</p>
            </div>
        </div>
        <div class="box-loader" *ngIf="PreLoader">
            <img src="assets/img/progress.svg" alt="Progress">
        </div>
    </div>
</div>