import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RaceSprintEntryComponent } from './race-sprint-entry/race-sprint-entry.component';
import { AuthGuard } from '../../services/auth-guard.service';

const routes: Routes = [
  {path:'raceSprintEntry',
   component:RaceSprintEntryComponent,
   canActivate:[AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RaceSprintEntryRoutingModule { }
