<!-- Alert Messages -->
<div *ngIf="failureMsg!=undefined||failureMsg!=null"
  [ngClass]="{'slideInRight':slideInRight,'slideOutRight':slideOutRight}"
  class="alert alert-icon alert-danger fade show animated faster" role="alert">
  <div class="gif-icon">
    <img src={{failureGif}} alt="success-icon" />
  </div>
  {{failureMsg}}
</div>

<div class="row h-100 m-0">
  <div class="col-lg-12 h-100">
    <div class="card shadow-sm rounded view-player">
      <div class="card-title bg-blue">
        <h4>Team Management</h4>
        <ul class="list-unstyled card-title-details float-right">
          <li><a class="btn btn-outline-primary"
            (click)="setEditPermisson(true);teamSrc='';uploadTeamPic=false" href="javascript:;">Add
              Team</a></li>
        </ul>
      </div>
      <div class="box-loader" *ngIf="PreLoader==true">
        <img src="assets/img/progress.svg" alt="Progress">
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-dark table-hover">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Team</th>
                <th>Team Members</th>
                <th class="action text-center">Action</th>
                <!-- <th class="text-center">Team Points</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let i of teamList;let ind=index">
                <td>{{ind+1}}</td>
                <td>
                  <div class="user-name-pic">
                    <span class="user-pic" [ngStyle]="{backgroundImage: 'url(' +i.team?.profileImage+ ')'}">
                    </span>
                    <span class="user-name">{{i.team.name}}</span></div>
                  <ng-template #defaultImg>
                    <img src="../../../../assets/img/1.jpg" class="img-fluid" />
                  </ng-template>
                </td>



                <td>
                  <ng-container *ngIf="i.users.length>0">
                    <div class="dynamic-team-members">
                      <div class="user-name-pic" *ngFor="let user of i.users">
                        <span class="user-pic" [ngStyle]="{backgroundImage: 'url(' +user?.profileImage+ ')'}">
                        </span>
                        <span *ngIf="user!=null;else defaultName" class="user-name"> {{user?.userName}}</span>
                        <ng-template #defaultImg>
                          <img src="../../../../assets/img/1.jpg" class="img-fluid" />
                        </ng-template>
                        <ng-template #defaultName>
                          -
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </td>

                <!--  <td>
                  <div class="user-name-pic" *ngIf="i.users.length>0">
                    <span class="user-pic" [ngStyle]="{backgroundImage: 'url(' +i.users[0]?.profileImage+ ')'}">
                    </span>
                    <span *ngIf="i.users[0]!=null;else defaultName" class="user-name"> {{i.users[0].userName}}</span>
                    <ng-template #defaultImg>
                      <img src="../../../../assets/img/1.jpg" class="img-fluid" />
                    </ng-template>
                    <ng-template #defaultName>
                      -
                    </ng-template>

                  </div>
                </td>
                 <td>
                  <div class="user-name-pic" *ngIf="i.users.length>0">
                    <span class="user-pic"[ngStyle]="{backgroundImage: 'url(' +i.users[1]?.profileImage+ ')'}">
                    </span><span *ngIf="i.users[1]!=null;else defaultName" class="user-name"> {{ i.users[1].userName}}</span>
                    <ng-template #defaultImg>
                      <img src="../../../../assets/img/1.jpg" class="img-fluid" />
                    </ng-template>
                    <ng-template #defaultName>
                      -
                    </ng-template>
                  </div>
                </td>
                <td>
                  <div class="user-name-pic" *ngIf="i.users.length>0">
                    <span class="user-pic"[ngStyle]="{backgroundImage: 'url(' +i.users[2]?.profileImage+ ')'}">
                    </span><span *ngIf="i.users[2]!=null;else defaultName" class="user-name"> {{i.users[2].userName}}</span>
                    <ng-template #defaultImg>
                      <img src="../../../../assets/img/1.jpg" class="img-fluid" />
                    </ng-template>
                    <ng-template #defaultName>
                      -
                    </ng-template>
                  </div>
                </td>
                <td>
                  <div class="user-name-pic" *ngIf="i.users.length>0">
                    <span class="user-pic"[ngStyle]="{backgroundImage: 'url(' +i.users[3]?.profileImage+ ')'}">
                    </span><span *ngIf="i.users[3]!=null;else defaultName" class="user-name"> {{i.users[3].userName}}</span>
                    <ng-template #defaultImg>
                      <img src="../../../../assets/img/1.jpg" class="img-fluid" />
                    </ng-template>
                    <ng-template #defaultName>
                      -
                    </ng-template>
                  </div>
                </td> -->
                <td class="text-center action">
                  <div>
                    <a href="javascript:;" class="btn btn-outline-secondary"
                      (click)="editTeam(i);isEdit=true;member1Edit=false;member2Edit=false;member3Edit=false;"><i
                        class="fa fa-edit"></i></a>
                  </div>
                </td>
              </tr>
            </tbody>

          </table>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade add-user" id="addTeam" tabindex="-1" role="dialog" aria-labelledby="addUserTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{isEdit==false?'Add Team':'Edit Team'}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-8">
            <form [formGroup]="addTeamForm">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Team Name <span class="star-icon">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Enter name" formControlName="teamName"
                    id="userName" required maxlength="35">

                  <small style="color:red" *ngIf="addTeamForm.controls['teamName'].errors?.pattern;else invalidName">
                    Enter
                    valid team name without spaces at start and end
                  </small>
                  <ng-template #invalidName>
                    <small *ngIf="addTeamForm.controls['teamName'].errors && !addTeamForm.controls['teamName'].pristine"
                      class="form-text" style="color:red">Enter valid
                      team name</small>
                  </ng-template>

                </div>
              </div>
              <div formArrayName="members"
              *ngFor="let member of addTeamForm?.get('members')?.controls; let i = index;" class="form-group row">
              <ng-container  [formGroupName]="i">
                <label for="name" class="col-sm-4 col-form-label">Member {{(1+i)}}<span class="star-icon">*</span></label>
                <div class="col-sm-8" >
                  <select *ngIf="this.member.getRawValue().editMember==true"class="form-control"
                   formControlName="member"  (change)="changeMeber($event.target.value,i)">
                   <option disabled>Please select member</option>
                     <option *ngFor="let user of userList" [ngValue]="user.f1ApplicationUserId"> {{user.userName}}</option>
                  </select>
                  <input *ngIf="this.member.getRawValue().editMember==false || (this.edit && member.getRawValue().isActive)" type="text" class="form-control" readonly formControlName="userName" [(ngModel)]="member.getRawValue().userName"  >
                    <!-- Form Control Icons -->
                    <div class="form-control-icons">
                      <i *ngIf="this.member.getRawValue().editMember==false&&member.getRawValue().isActive==false" class="fa fa-edit"  (click)="editSelectedMember('edit',i)" ></i>
                      <i *ngIf="this.member.getRawValue().editMember==true&&member.getRawValue().isActive==false" class="fa fa-close" (click)="editSelectedMember('close',i)" ></i>
                    </div>
                    <i class="fa fa-trash delete-icon" (click)="removeMember(i)"></i>
                   <!-- <div *ngIf="addTeamForm.get('member')?.invalid">
                     <small style="color:red" *ngIf="addTeamForm.get('member')?.errors?.required">Team member is required.</small>
                     <small style="color:red" *ngIf="addTeamForm.get('member')?.errors?.minLengthArray==true">Minimum 4 members allowed per team.</small>
                     <small style="color:red" *ngIf="addTeamForm.get('member')?.errors?.maxLengthArray==true">Maximum 5 members allowed per team.</small>
                    </div> -->
                </div>
              </ng-container>
              </div>  
              <div class="form-group row mt-4">
                <div class="col-sm-12">
                    <button class="btn btn-outline-primary w-auto px-4 py-1" (click)="addMember()">Add Member </button>
                  </div>
              </div>
            </form>

          </div>

          <div class="col-lg-4">
            <div class="user-profile-pic">
              <div class="pic" [ngStyle]="{backgroundImage: 'url(' + teamSrc+ ')'}">
                <img *ngIf="uploadTeamPic==false" src="../../../../assets/img/default.jpg" class="img-fluid" />
                <div class="upload">
                  <i class="fa fa-camera"> </i>
                  <span> Upload Image
                    <!-- <small>160 x 160</small> -->
                  </span>
                  <input type="file" (change)="readURLForTeam($event);" />
                </div>
              </div>
              <label>Team Picture </label>
              <!-- {{addTeamForm?.get('members')?.value | json}} -->
            </div>
          </div>
          
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="addTeamForm.invalid"
          (click)="addTeam()">{{isEdit==false?'Save':'Update'}}</button>
      </div>
      <div class="box-loader" *ngIf="PreLoaderForSave">
        <img src="assets/img/progress.svg" alt="Progress">
      </div>
    </div>
  </div>
</div>