import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardModule } from './dashboard/dashboard.module';
import { LayoutModule } from '@angular/cdk/layout';
import { RaceEntryModule } from './race-entry-module/race-entry.module';
import { RaceSprintEntryModule } from './race-sprint-entry-module/race-sprint-entry.module';
import { RulesAndRegulationModule } from './rules-and-regulation/rules-and-regulation.module';
import { CanDeactivateGuard } from '../services/guard-deactivate';
import { AuthGuard } from '../services/auth-guard.service';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { UserProfileModule } from './user-profile/user-profile.module';
import { UserDetailsModule } from './user-details/user-details.module';


const routes: Routes = [
    {
      path: 'dashboard',
      loadChildren:'./dashboard/dashboard.module#DashboardModule'
     },
     {
      path: 'ladder',
      loadChildren:'@angular/cdk/layout#LayoutModule',
      canActivate:[AuthGuard],
     },
     {
      path: 'entry',
      loadChildren:'./race-entry-module/race-entry.module#RaceEntryModule',
      canActivate:[AuthGuard],
    },
    {
      path: 'sprintEntry',
      loadChildren:'./race-sprint-entry-module/race-sprint-entry.module#RaceSprintEntryModule',
      canActivate:[AuthGuard],
    },
     {
      path: 'rules',
      loadChildren:'./rules-and-regulation/rules-and-regulation.module#RulesAndRegulationModule',
      canActivate:[AuthGuard],
     },
     {
      path: 'profile',
      loadChildren:'./user-profile/user-profile.module#UserProfileModule',
      canActivate:[AuthGuard],
     },
     {
      path: 'userDetails',
      loadChildren:'./user-details/user-details.module#UserDetailsModule',
      canActivate:[AuthGuard],
     }
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class  UserRoutingModule { }
  