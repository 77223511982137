import { Directive, HostListener ,ElementRef} from "@angular/core";
import { AbstractControl } from '@angular/forms';



@Directive({
    selector: '[mobileValidation]'
})
export class mobileValidatorDirective {

    constructor(public el: ElementRef) { }

  regex='^[0-9]+$';
    @HostListener('keypress', ['$event']) 
    onKeyPress(event) {
        return( new RegExp(this.regex).test(event.key));
    }

  

}

@Directive({
    selector: '[pointsValidation]'
})
export class RulesEngineValidationDirective {

    constructor(public el: ElementRef) { }

  regex='^-?(0|[1-9]\d*)?$';
    @HostListener('keypress', ['$event']) 
    onKeyPress(event) {
        return( new RegExp(this.regex).test(event.key));
    }

}


export class ValidationService {
  

static numberValidator(control: AbstractControl) {
    const ControlValue = control.value ? control.value.trim() : null;
    const re = /^[+-]?\d*\.\d+$|^[+-]?\d+(\.\d*)?$/;
    if (re.test(ControlValue)) {
        return null;
    } else {
        return { 'invalidNumber': true };
    }
  }
}