import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { SubscriptionDetailRoutingModule } from './subscription-detail-routing.module';
import { SubscriptionDetailComponent } from './subscription-detail/subscription-detail.component';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
  declarations: [SubscriptionDetailComponent],
  imports: [
    CommonModule,
    SubscriptionDetailRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    

  ],
  providers:[DatePipe]
})
export class SubscriptionDetailModule { }
