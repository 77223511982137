import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConstants } from '../constants/url-constants';
import { GenericAPIService } from '../services/generic-api.service';
import { Router } from '@angular/router';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { DataSharingService } from '../services/data-sharing.service';
declare var $: any;
declare var Razorpay;

import { Location } from '@angular/common';
import { UserIdleService } from 'angular-user-idle';
import { NotificationConstants } from '../constants/notification-constants';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit {

  public logoutUrl = UrlConstants.logoutUrl;
  public latestRaceDetailsUrl = UrlConstants.latestRaceDetail;
  public fetchOrderIdUrl = UrlConstants.fetchOrderId;
  public verySignatureUrl = UrlConstants.verySignature;
  public getProfileUrl = UrlConstants.getProfiledata;
  getSubcriptionURL = UrlConstants.getSubcriptionDetails;


  public enddate: string;
  public _diff: number = 0;
  public _days: number = 0;
  public _hours: number = 0;
  public _minutes: number = 0;
  public _seconds: number = 0;

  public raceDetails;
  public raceName;
  public raceStartingTime;
  public raceEndingTime;
  public raceEndingTimeStr;
  public entryClosed: boolean = false;
  preLoader = false;
  public userName;
  public teamName: any;
  public previousRaceId = 0;
  public userImage: any;
  public date_now = new Date();
  public showMobileMenu: boolean = false;
  public isTimeRed = false;
  public currentTime: Date;
  public timedelayforgettingracetime: boolean = false;
  sessionExpireNotification;
  public timeup: boolean = false;
  noRace: boolean = false;

  public PreLoaderForRazorPay = false;
  amountToBePaid = 0;
  orderId;
  endsOn: any;
  status: any;
  userId: any;
  emailId: any;
  subscriptionDetails: any;


  constructor(private _location: Location, public _genericService: GenericAPIService, public router: Router, public encryptDecryptService: EncryptDecryptService,
    public dataSharingService: DataSharingService, private userIdle: UserIdleService) {
    //  this.enddate = "2019/03/13 23:59:59";
    this.userName = this.encryptDecryptService.getLocalStorage('userName');
    this.teamName = this.encryptDecryptService.getLocalStorage('teamName');
    this.userImage = this.encryptDecryptService.getLocalStorage('userImage');
    this.userId = this.encryptDecryptService.getLocalStorage("userId");
    this.emailId = this.encryptDecryptService.getLocalStorage("email");
    this.getLatestRaceDetail();
    setInterval(() => {
      this.date_now = new Date();
    }, 1000);
    this.dataSharingService.userNameObs.subscribe(data => {
      if (data != '') {
        this.userName = data;
      }
    })
    this.dataSharingService.teamNameObs.subscribe(data => {
      if (data != '') {
        this.teamName = data;
      }
    })

    this.dataSharingService.userImageObs.subscribe(data => {
      if (data != '') {
        this.userImage = data;
      }
    })

    //For session Management starts.......
    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.sessionExpireNotification = NotificationConstants.sessionExpiredNotification;
      this.dataSharingService.updatenotification(NotificationConstants.sessionInvalidNotification);
      this.timeup = true;
      this.logout();
    });
    //For session Management ends.......
  }
  //For session Management starts.......
  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }
  //For session Management ends.......

  ngOnInit() {
    //this.getLatestRaceDetail();
    this.getSubcriptionDetails();
    this.loadScripts();
  }
  navFromProfile() {
    if (this.router.url == '/user/profile') {
      this._location.back();
    }
  }
  getLatestRaceDetail() {
    var raceId = +this.encryptDecryptService.getLocalStorage("upcomingRaceId");
    if (raceId != 0) {
      var userId = this.userId;
      this._genericService.getAll(this.latestRaceDetailsUrl + '?raceId=' + raceId + '&userId=' + userId).subscribe(response => {
        if (response.status.success == "Success") {
          this.raceDetails = response.data;
          this.raceName = this.raceDetails.raceName;
          this.raceStartingTime = this.raceDetails.raceBdStartingDateTime;
          this.raceEndingTime = this.raceDetails.raceBdClosingDateTime;
          this.raceEndingTimeStr = this.raceDetails.raceBdClosingDateTimeString;
          this.currentTime = new Date(this.raceDetails.todayDateTime);
          this.status = this.raceDetails.subscriptionStatus;
          this.endsOn = this.raceDetails.subscriptionEndDate;
          //  this.dataSharingService.updateCurrentTime(this.currentTime) 
          if(this.raceDetails.raceName!=null){
            this.encryptDecryptService.setToLocalStorage("raceName", this.raceDetails.raceName)
          }
          this.encryptDecryptService.setToLocalStorage("isSprintEntry", this.raceDetails.isSprintEntry == null ? false : this.raceDetails.isSprintEntry);
          
          interval(1000).pipe(
            map((x) => {
              this.currentTime.setSeconds(this.currentTime.getSeconds() + 1);
              this.dataSharingService.updateCurrentTime(this.currentTime)
              this._diff = Date.parse(this.raceEndingTimeStr) - Date.parse(this.currentTime.toString());
            })).subscribe((x) => {
              this._days = this.getDays(this._diff);
              this._hours = this.getHours(this._diff);
              this._minutes = this.getMinutes(this._diff);
              this._seconds = this.getSeconds(this._diff);
              if (this._days <= 0 && this._hours <= 0 && this._minutes <= 0 && this._seconds <= 0) {
                this.entryClosed = true;
              }
              if (this._days == 0) {
                this.isTimeRed = true;
              }
              this.timedelayforgettingracetime = true;
            });
        }

      })
    }
    else {
      this.noRace = true;
    }
  }

  getDays(t) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t) {
    return Math.floor((t / 1000) % 60);
  }

  logout() {
    this.preLoader = true;
    this._genericService.post(this.logoutUrl, "").subscribe(response => {
      if (response.status.success == "Success") {
        this.preLoader = false;
        sessionStorage.clear();
        this.router.navigate(['signIn']);
        setTimeout(() => {
          this.timeup == true ? location.reload() : console.log("logout successfully");
        }, 400)
      }
      else {
        this.timeup == true ? location.reload() : console.log("logout successfully");
        this.preLoader = false;
        sessionStorage.clear();
        this.router.navigate(['signIn']);
      }
    },
      error => {
        this.preLoader = false;
      });
  }
  getActive(object, menuname) {
    // this.classList.add('selected');

    for (var Index = 0; Index < document.getElementsByClassName('active').length; Index++) {
      document.getElementsByClassName('active')[0].className = '';
    }
    object.currentTarget.className = 'active';

  }
  goTop() {
    if ($(window).width() > 1200) {
      $(".actual-page-view").css('margin-top', '0px');
      $(".actual-page-view").css('padding-bottom', '0px');
      $(".actual-page-view").css('bottom', '0px');
      $('.main-container').addClass('overflow-hidden');
      setTimeout(function () {
        $('.single-user-detail-view').addClass('d-none');
        $('.main-container').removeClass('overflow-hidden');
      }, 300);
    }
    if ($(window).width() < 1200) {
      $('.actual-page-view').removeClass('d-none');
      $('.single-user-detail-view').addClass('d-none');
    }
  }

  // RazorPay code starting ..........

  getSubcriptionDetails() {
    this._genericService.getAll(this.getSubcriptionURL).subscribe(response => {
      if (response.status.success == 'Success') {
        this.subscriptionDetails = response.data;
      }
      else {
        this.subscriptionDetails = null;
      }
    }, error => {
      this.subscriptionDetails = null;
    })
  }


  loadScripts() {
    const dynamicScripts = [
      'https://checkout.razorpay.com/v1/checkout.js',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  getPayableAmount() {
    if (this.subscriptionDetails != null) {
      var deadLineDate = new Date(this.subscriptionDetails.paymentDueDate);
      const date: any = this.currentTime.getTime();
      const penalty = date >= deadLineDate.getTime();

      const amount = penalty ? this.subscriptionDetails.membershipFee + this.subscriptionDetails.penalityFee : this.subscriptionDetails.membershipFee;
      this.amountToBePaid = amount;
    }


    return this.amountToBePaid;

  }

  // invokeRazorPay(e) {
  // }
  invokeRazorPay(e) {
    this._genericService.getAll(this.getSubcriptionURL).subscribe(response => {
      if (response.status.success == 'Success') {
        this.subscriptionDetails = response.data;

        var tempThis = this;

        this.amountToBePaid = this.getPayableAmount() * 100;
        let requestObject = {
          "amount": this.amountToBePaid,
          "currency": "INR",
          "receipt": "receipt-123",
          "payment_capture": true
        }
        tempThis._genericService.post(this.fetchOrderIdUrl, requestObject, false).subscribe(res => {
          tempThis.orderId = res.data;
          var options = {
            "key": environment.razorPay_key,// testModekey =>   rzp_test_3tMaChjQy0fFTo
            "amount": tempThis.amountToBePaid, // 2000 paise = INR 20
            "name": "CloudNow Technologies",
            "description": "Payment Page",
            "image": "../../../../assets/img/1.jpg",
            "handler": function (response) {
              tempThis.verifySign(response);
            },
            "prefill": {
              "name": tempThis.userName,
              "email": this.emailId,
            },
            "modal": {
              "ondismiss": function (response) {
              }
            },
            "notes": {
              "address": "Hello World"
            },
            "theme": {
              "color": "#528FF0"
            },
            "order_id": tempThis.orderId
          };

          var rzp1 = new Razorpay(options);
          rzp1.open();
          e.preventDefault();

        });

      }
      else {
        this.subscriptionDetails = null;
      }
    }, error => {
      this.subscriptionDetails = null;
    })
  }

  verifySign(response) {
    let signatureRequestObject = {
      "razorpay_order_id": response.razorpay_order_id,
      "razorpay_payment_id": response.razorpay_payment_id,
      "razorpay_signature": response.razorpay_signature,
      "grossAmount": this.amountToBePaid,
    };
    this.PreLoaderForRazorPay = true;
    return this._genericService.post(this.verySignatureUrl + "?userId="
      + this.userId, signatureRequestObject).subscribe(res => {
        this.PreLoaderForRazorPay = false;
        if (res.status.success == 'Success') {
          this.getLatestRaceDetail();
          this.amountToBePaid = 1;
        }
      }, error => {

      });

  }


}
