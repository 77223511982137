import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardModule } from './admin-dashboard/admin-dashboard.module';
import { TeamManagementModule } from './team-management/team-management.module';
import { RulesEngineModule } from './rules-engine/rules-engine.module';
import { RaceResultModule } from './race-result/race-result.module';
import { LadderBoardModule } from './ladder-board/ladder-board.module';
import { MasterDataRoutingModule } from './master-data/master-data-routing.module';
import { MasterDataModule } from './master-data/master-data.module';
import { SubscriptionDetailModule } from './subscription-detail/subscription-detail.module';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren:'./admin-dashboard/admin-dashboard.module#AdminDashboardModule',
   },
   {
    path: 'team',
    loadChildren:'./team-management/team-management.module#TeamManagementModule',
   },
   {
    path: 'rules',
    loadChildren:'./rules-engine/rules-engine.module#RulesEngineModule',
   },
   {
    path: 'raceresult',
    loadChildren:'./race-result/race-result.module#RaceResultModule',
   },
   {
    path: 'ladder',
    loadChildren:'./ladder-board/ladder-board.module#LadderBoardModule',
   },
   {
    path: 'subscribe',
    loadChildren:'./subscription-detail/subscription-detail.module#SubscriptionDetailModule',
   },
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class  AdminRoutingModule { }
  