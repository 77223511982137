<!-- Alert Messages -->
<div *ngIf="message!=undefined||message!=null"
  class="alert alert-icon alert-success fade show slideInRight animated faster" role="alert">
  <div class="gif-icon">
    <img src="../../../../assets/img/success.gif" alt="success-icon" />
  </div>
  {{message}}
</div>
<div *ngIf="showerrorMessage!=undefined||showerrorMessage!=null"
  class="alert alert-icon alert-danger fade show slideInRight animated faster" role="alert">
  <div class="gif-icon">
    <img src="../../../../assets/img/failure.gif" alt="success-icon" />
  </div>
  {{showerrorMessage}}
</div>
<section class="login-container h-100">
  <div class="row justify-content-start align-items-center h-100">
    <div class="card shadow-sm login-card reset-card">
      <h4 class="mb-4 pb-2 card-title">Reset Password</h4>
      <form [formGroup]="resetForm">
        <div class="form-group">
          <input [type]="show==true ? 'text' : 'password'" class="form-control" id="newPwd" formControlName="password"
            placeholder="New password" required>
          <div class="form-control-icons">
            <i *ngIf="show" class="fa fa-eye" aria-hidden="true" (click)="show=!show"></i>
            <i *ngIf="!show" class="fa fa-eye-slash" aria-hidden="true" (click)="show=!show"></i>
          </div>
          <small *ngIf="resetForm.controls['password'].errors && !resetForm.controls['password'].pristine"
            class="form-text text-muted">Password should contain minimum 8 characters</small>
        </div>
        <div class="form-group">
          <input [type]="showConfirmPswd==true ? 'text' : 'password'" class="form-control" id="confirmPwd"
            formControlName="confirmPassword" placeholder="Confirm Password" required>
          <div class="form-control-icons">
            <i *ngIf="showConfirmPswd" class="fa fa-eye" aria-hidden="true"
              (click)="showConfirmPswd=!showConfirmPswd"></i>
            <i *ngIf="!showConfirmPswd" class="fa fa-eye-slash" aria-hidden="true"
              (click)="showConfirmPswd=!showConfirmPswd"></i>
          </div>
          <div *ngIf="resetForm.value.confirmPassword!=''">
            <small class="form-text text-muted"
              *ngIf="resetForm.value.password != resetForm.value.confirmPassword">confirm password not matched</small>
          </div>

          <!-- <small *ngIf="resetForm.controls['confirmPassword'].errors && !resetForm.controls['confirmPassword'].pristine"
            class="form-text text-muted">Enter valid confirm password</small> -->
        </div>
        <div class="form-group mb-0">
          <button type="submit" class="btn btn-primary btn-login" (click)="reset()"
            [disabled]="resetForm.invalid==true || resetForm.value.password != resetForm.value.confirmPassword">reset</button>

        </div>
      </form>
      <!-- Box Loader -->
      <div class="box-loader" *ngIf="PreLoader">
        <img class="text-center image-responsive-height demo-mw-50" src="assets/img/progress.svg" alt="Progress">
      </div>
    </div>
  </div>
</section>