import { Component, OnInit } from '@angular/core';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-ladder-board',
  templateUrl: './team-ladder-board.component.html',
  styleUrls: ['./team-ladder-board.component.scss']
})
export class TeamLadderBoardComponent implements OnInit {

  teamListUrl = UrlConstants.teamList;

  public isTeamList = false;
  public teamResultList=[];
  public teams = []
  PreLoader = false;

  constructor(public _genericService: GenericAPIService,public router: Router) {
    this.getTeamList();
  }
  ngOnInit() { }
  getTeamList() {
    this.PreLoader = true;
    var raceId = +sessionStorage.previousRaceId;
    this._genericService.getAll(this.teamListUrl + "?raceId=" + raceId).subscribe(response => {
      if (response.status.success == "Success") {
        this.PreLoader = false;
        this.isTeamList = response.data.isTeamList;
        if (this.isTeamList == true) {
          this.teams = response.data.responseList;
        }
        else{
          this.teamResultList = response.data.responseList;
        }
      }
    })
  }
}
