<div class="row h-100 m-0">
  <div class="col-lg-12 h-100">
    <div class="card shadow-sm rounded rules">
      <h4 class="card-title bg-blue">Rules & Regulations</h4>
      <div class="card-body">
        <!-- <button class="pull-right" (click)="edit=true">Edit</button> -->
        <span *ngIf="!edit" [innerHTML]=value>
        </span>
        <div *ngIf="edit">
          <form [formGroup]="textForm">
            <ejs-richtexteditor [(value)]='value' required name="name" class="form-control" formControlName="text"
              [(ngModel)]="value" [toolbarSettings]='tools'></ejs-richtexteditor>
            <button type="submit" ejs-button (click)="onSubmit();edit=false">Submit</button>
          </form>
        </div>
        <div *ngIf="notFound==true">
          <div class="row add-entry align-items-center justify-content-center">
            <div class="col-lg-12">
              <p>Rules & Regulations not yet updated, stay tuned for updates...</p>
            </div>
          </div>
        </div>

        <div class="box-loader" *ngIf="PreLoader">
          <img src="assets/img/progress.svg" alt="Progress">
        </div>
      </div>
    </div>
  </div>
</div>