import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RulesAndRegulationComponent } from './rules-and-regulation/rules-and-regulation.component';
import { RulesAndRegulationRouting } from './rules-and-regulation-routing.module';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [RulesAndRegulationComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RulesAndRegulationRouting,
    RichTextEditorAllModule
  ]
})
export class RulesAndRegulationModule { }
