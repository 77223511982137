import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminProfileRoutingModule } from './admin-profile-routing.module';
import { AdminprofileComponent } from './adminprofile/adminprofile.component';
import { UserProfileModule } from 'src/app/user-module/user-profile/user-profile.module';

@NgModule({
  declarations: [AdminprofileComponent],
  imports: [
    CommonModule,
    AdminProfileRoutingModule,
    UserProfileModule
  ]
})
export class AdminProfileModule { }
