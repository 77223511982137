import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { UrlConstants } from 'src/app/constants/url-constants';

@Component({
  selector: 'app-race-constructor',
  templateUrl: './race-constructor.component.html',
  styleUrls: ['./race-constructor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RaceConstructorComponent implements OnInit {

  public raceConstructorUrl = UrlConstants.raceConstructors;
  public masterConstructorurl = UrlConstants.constructorMDList;
  public saveConstructorToRaceUrl = UrlConstants.saveConstructorTrx;


  public raceId = 0;
  public raceMode;
  public raceConstrctors = [];
  public masterConstructors = [];
  public preLoader;
  public showEditButton = false;
  public selectedIndex = -1;
  public raceInfoUrl = UrlConstants.currentYearRaceInfo;
  public circuitDetail = undefined;
  public incompletedConstructor;
  raceList: any;
  constructor(public activatedRoute: ActivatedRoute, public genericAPIService: GenericAPIService) {
    this.activatedRoute.queryParams.subscribe((data) => {
      this.raceId = data.raceId;
      this.raceMode = data.mode;
      if (this.raceMode == 'edit') {
        this.showEditButton = true;
      }
      else {
        this.showEditButton = false;
      }
    })
  }

  ngOnInit() {
    this.getRaceInfo();
    this.getConstructorOfRace();

  }

  getConstructorOfRace() {
    if (this.raceConstrctors.length < 10) {
      for (let i = 1; i <= 10; i++) {
        var constructor = this.creatConstructorObj();
        this.raceConstrctors.push(constructor);
      }
    }
    this.preLoader = true;
    this.genericAPIService.getAll(this.raceConstructorUrl + '?raceId=' + this.raceId).subscribe(response => {
      if (response.status.success == "Success") {
        this.raceConstrctors = response.data;
        this.getMasterConstructor();
        this.preLoader = false;
      }
      else {
        this.preLoader = false;
      }
      this.getMasterConstructor();
    }, error => {
      this.preLoader = false;
    })

  }
  creatConstructorObj() {
    var obj = {
      constructorId: 0,
      constructorPic: '',
      name: '',
      selected: false
    }
    return obj;
  }

  getMasterConstructor() {
    this.preLoader = true;
    this.genericAPIService.getAll(this.masterConstructorurl).subscribe(response => {
      if (response.status.success == "Success") {
        this.masterConstructors = response.data;
        var constructors = this.raceConstrctors;
        for (let constructor of constructors) {
          if (constructor.constructorMaster != null) {
            var index = this.masterConstructors.findIndex(c => c.constructorMasterId == constructor.constructorMaster.constructorMasterId);
            this.masterConstructors.splice(index, 1);
          }

        }

        this.preLoader = false;
      }
      else {
        this.preLoader = false;
        this.masterConstructors = [];
      }
    }, error => {
      this.masterConstructors = [];
      this.preLoader = false;
    })
  }

  removeFromConstructorList(constructorObj) {
    this.masterConstructors.splice(constructorObj.constructorMasterId - 1, 0, constructorObj);
    const indexOfSelectedConstructList = this.raceConstrctors.indexOf(constructorObj);
    this.raceConstrctors[indexOfSelectedConstructList] = this.creatConstructorObj();
    this.addConstructor(indexOfSelectedConstructList);
    this.masterConstructors.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0 //default return value (no sorting)
    })
    setTimeout(() => {
      var header = document.getElementById("myDIV");
      var btns = header.getElementsByClassName("race-entry-ranking");
      for (var i = 0; i < btns.length; i++) {
        var current = document.getElementsByClassName(" selected");
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" selected", "");
        }
      }
      btns[indexOfSelectedConstructList].className += ' selected';

    }, 100);
  }

  addConstructor(index) {
    this.selectedIndex = index;
  }
  addMaster(constructorObj) {
    var index;
    var constructorId;
    if (constructorObj.hasOwnProperty('constructorMaster') == true) {
      constructorId = constructorObj.constructorMaster.constructorMasterId
    }
    else {
      constructorId = constructorObj.constructorMasterId
    }
    index = this.masterConstructors.findIndex(c => (c.hasOwnProperty('constructorMaster') == true ?
      c.constructorMaster.constructorMasterId == constructorId
      : c.constructorMasterId == constructorId));
    if (this.selectedIndex != -1) {
      if (this.raceConstrctors[this.selectedIndex].selected == false) {
        this.raceConstrctors[this.selectedIndex] = this.masterConstructors[index];
        this.raceConstrctors[this.selectedIndex].selected = true;
        this.masterConstructors.splice(index, 1);
        this.autoToggle();
      }
    }
  }
  saveConstructors() {
    var constructors = [];
    for (let cons of this.raceConstrctors) {
      if (cons.hasOwnProperty('constructorMasterId')) {
        var constructor = {
          "constructorMaster": {
            "constructorMasterId": cons.constructorMasterId,
          }
        }
        constructors.push(constructor)
      }

    }

    for (let cons of this.masterConstructors) {
      if (cons.hasOwnProperty('constructorMaster')) {
        var constructor1 = {
          "constructorId": cons.constructorId,
          "constructorMaster": {
            "constructorMasterId": cons.constructorMaster.constructorMasterId
          }
        }
        constructors.push(constructor1)
      }
    }
    this.preLoader = true;
    this.genericAPIService.post(this.saveConstructorToRaceUrl + '?raceId=' + this.raceId, constructors).subscribe(response => {
      if (response.status.success == "Success") {
        this.getConstructorOfRace();
      }
      else {
        this.preLoader = false;

      }
    }, error => {
      this.preLoader = false;
    })
  }

  enableSaveButton() {
    // for (let cons of this.raceConstrctors) {
    //   if (cons.constructorId == 0) {
    //     return true;

    //   }
    //   else{
    //     return false;
    //   }
    // }

    function isSelected(element, index, array) {
      if (element.constructorId != 0) {
        return true;
      }
    }
    var isAllDriverSelected = this.raceConstrctors.every(isSelected);
    if (isAllDriverSelected == true) {
      return false
    }
    else {
      return true;
    }
  }

  getRaceInfo() {
    this.genericAPIService.getAll(this.raceInfoUrl).subscribe(response => {
      if (response.status.success == "Success") {
        this.raceList = response.data;
        for (let race of this.raceList) {
          if (race.raceId == this.raceId) {
            this.circuitDetail = race.circuits;
          }
        }
      }
      else {
        this.raceList = undefined
      }
    }, error => {
      this.raceList = undefined
    })
  }

  addscss() {
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("race-entry-ranking");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName(" selected");
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" selected", "");
        }
        this.className += " selected";
      });
    }
  }
  autoToggle() {
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("race-entry-ranking");
    this.incompletedConstructor = this.raceConstrctors.findIndex(x => x.selected == false);
    if (this.incompletedConstructor != -1) {
      this.selectedIndex = this.incompletedConstructor;
      btns[this.incompletedConstructor].setAttribute('class', 'race-entry-ranking selected');
    }
  }
}
