import { Component, OnInit } from '@angular/core';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-player-ladder-board',
  templateUrl: './player-ladder-board.component.html',
  styleUrls: ['./player-ladder-board.component.scss']
})
export class PlayerLadderBoardComponent implements OnInit {
  public playerLadderUrl = UrlConstants.playerList;
  public users = [];
  public isUserList = false;
  public userResultList=[];
  PreLoader = false;
  constructor(public _genericService: GenericAPIService,public router: Router) {
    this.getPlayerLeaderBoardList();
  }
  ngOnInit() {
  }
  getPlayerLeaderBoardList() {
    this.PreLoader = true;
    var raceId = +sessionStorage.previousRaceId;
    this._genericService.getAll(this.playerLadderUrl + "?raceId=" + raceId).subscribe(response => {
      if (response.status.success == "Success") {
        this.isUserList = response.data.isUserList;
        if (this.isUserList == true) {
          this.users = response.data.responseList;
        }
        else{
          this.userResultList = response.data.responseList;
        }
        this.PreLoader = false;
      }

    },error=>{
      this.PreLoader = false;
    })
  }
}
