import { Component, OnInit } from '@angular/core';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationConstants } from 'src/app/constants/notification-constants';
declare var $: any;
import { IMyDpOptions } from 'mydatepicker';

@Component({
  selector: 'app-constructor',
  templateUrl: './constructor.component.html',
  styleUrls: ['./constructor.component.scss']
})
export class ConstructorComponent implements OnInit {
  constructorMDlistUrl = UrlConstants.constructorMDList;
  updateConstructorMDUrl = UrlConstants.saveConstructorMD;
  deleteConstructorMDUrl = UrlConstants.deleteConstructorMD;
  constructorList: any = [];
  noRecord: boolean = false;
  constructorForm;
  deleteObj = {};
  reqPayLoad = {};
  deletePayload = {}
  editObj :any= {};
  isEdit: boolean = false;
  constructorImage: File;
  public constructorSrc;
  public uploadConstructorPic = false;
  failureMsg;
  failureUrl="../../../../assets/img/failure.gif";
  failureGif;
  preloader: boolean = true;
  slideOutRight;
  slideInRight;
  public myDatePickerOptions: IMyDpOptions;

  constructor(public genericService: GenericAPIService) { 
    this.myDatePickerOptions = {
      dateFormat: 'yyyy-mm-dd',
      showClearDateBtn: false,
      editableDateField: false,
      openSelectorOnInputClick: true
    };
  }

  ngOnInit() {
    this.getConstructorMDList();
    this.createForm();
  }

  createForm() {
    this.constructorForm = new FormGroup({
      constructorName: new FormControl('', [Validators.required, Validators.pattern(/^[^\s].+[^\s]$/)]),
      joinedDate: new FormControl('', [Validators.required]),
    });
  }

  resetFields() {
    this.uploadConstructorPic = false;
    this.constructorImage = null;
    this.constructorSrc = null;
    this.isEdit = false;
    this.constructorForm.reset();
  }
  readURLForUser(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (this.checkValidOrNotForUser(file) == true) {
        this.constructorImage = file;
        this.uploadConstructorPic = true;
        const reader = new FileReader();
        reader.onload = e => this.constructorSrc = reader.result;
        reader.readAsDataURL(file);
      }
    }
  }
  checkValidOrNotForUser(file) {
    var valid: boolean = true;
    this.failureGif=NotificationConstants.getLinkPicture(this.failureUrl);
    if (file.size > 300000) {
      valid = false;
      this.failureMsg = 'File size should not Exceeds 300KB';
    }
    var fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length);
    if (fileExtension != "jpeg" && fileExtension != "png" && fileExtension != "jpg") {
      valid = false;
      this.failureMsg = NotificationConstants.correctFileTypeMsg;
    }
    setTimeout(() => {
      this.slideOutRight=true;
      this.slideInRight=false;
    },3000)
    setTimeout(() => {
      this.slideOutRight=false;
      this.slideInRight=true;
      this.failureMsg = undefined;
    }, 6000);
    if (valid == true) {
      this.failureMsg = undefined
    }
    return valid;
  }

  getConstructorMDList() {
    this.genericService.getAll(this.constructorMDlistUrl).subscribe(response => {
      if (response.status.success = "Success") {
        this.constructorList = response.data;
        response.totalResult < 1 ? this.noRecord = true : this.noRecord = false;
      }
      else {
        this.noRecord = true;
      }
      this.preloader=false;
    }, error => {
      this.preloader=false;

    })

  }

  getEditConstructorDetails(constructor) {
    this.resetFields();
    this.editObj = constructor;
    var joinedDateObj;
    if (this.editObj.joinedDate != null) {
      var subscriptionDate = new Date(this.editObj.joinedDate);
      joinedDateObj = { date: {  month: subscriptionDate.getMonth() + 1,day: subscriptionDate.getDate(), year: subscriptionDate.getFullYear()} };
    }
    
    this.constructorForm.controls['constructorName'].setValue(this.editObj['name']);
    this.constructorForm.controls['joinedDate'].setValue(joinedDateObj);
    if (this.editObj['constructorPic'] != null) {
      this.uploadConstructorPic = false;
      this.constructorSrc = this.editObj['constructorPic'];
    }
    this.isEdit = true;
    $("#addConstructor").modal("show");
  }

  cancelDelete() {
    $("#deleteconstructor").modal("hide");
    this.deleteObj = {};
  }
  delete() {
    this.preloader = true;
    if (this.deleteObj != null) {
      this.deletePayload['constructorMasterId'] = this.deleteObj['constructorMasterId'];
      this.deletePayload['isActive'] = false;
      this.genericService.post(this.deleteConstructorMDUrl, this.deletePayload).subscribe(response => {
        this.deleteObj = {};
        this.deletePayload = {};
        if (response.status.success == 'Success') {
          $("#deleteconstructor").modal("hide");
          this.getConstructorMDList();
        }
        this.preloader = false;
      }, error => {
        this.preloader = false;
      })
    }
  }

  add() {
    this.reqPayLoad =
      { "name": this.constructorForm.controls['constructorName'].value,
      "joinedDate":this.constructorForm.controls['joinedDate'].value.formatted,
      "isActive": true }
    this.save();
  }


  update() {
    this.reqPayLoad = {
        "constructorMasterId": this.editObj['constructorMasterId'],
        "name": this.constructorForm.controls['constructorName'].value,
        "joinedDate":this.changeDateFormat(this.constructorForm.controls['joinedDate'].value.date),
        "isActive": true
      }
    this.save();
  }
  changeDateFormat(date){
    date.month = (date.month).toString().length == 1 ? ("0" + (date.month )) : date.month;
    date.day = (date.day).toString().length == 1 ? ("0" + (date.day )) : date.day;
   return date.year+"-"+date.month+'-'+date.day
  }
  save() {
    if (this.constructorSrc != null || this.constructorSrc != undefined) {
      this.preloader = true;
      let formdata: FormData = new FormData();
      let eee = JSON.stringify(this.reqPayLoad);
      formdata.append('constructorMaster', eee);
      this.constructorImage != null || this.constructorImage != undefined ? formdata.append('constructorImage', this.constructorImage) : formdata.append('constructorImage', null);
      this.genericService.imagePost(this.updateConstructorMDUrl, formdata).subscribe(response => {

        if (response.status.success == 'Success' || response.status.success == 'success') {
          this.getConstructorMDList();
          this.resetFields();
          $("#addConstructor").modal("hide");
        }
        this.preloader = false;
      }, error => {
        this.preloader = false;
        $("#addConstructor").modal("hide");
      });
    }
    else {
      this.failureGif=NotificationConstants.getLinkPicture(this.failureUrl);
      this.failureMsg = NotificationConstants.mandotoryMsg;
      setTimeout(() => {
        this.slideOutRight=true;
        this.slideInRight=false;
      },3000)
      setTimeout(() => {
        this.slideOutRight=false;
        this.slideInRight=true;
        this.failureMsg = undefined;
      }, 6000);
    }
  }
 
}
