import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataSharingService {

    public userNameBs = new BehaviorSubject<string>('');
    public userNameObs = this.userNameBs.asObservable();


    public teamNameBs = new BehaviorSubject<string>('');
    public teamNameObs = this.teamNameBs.asObservable();

    public userImageBs = new BehaviorSubject<string>('');
    public userImageObs = this.userImageBs.asObservable();

    public notificationBs = new BehaviorSubject<string>('');
    public notificationObs = this.notificationBs.asObservable();

    public successNotificationBs = new BehaviorSubject<string>('');
    public successNotificationObs = this.successNotificationBs.asObservable();

    public failureNotificationBs = new BehaviorSubject<string>('');
    public failureNotificationObs = this.failureNotificationBs.asObservable();

    public successFlagNotificationBs = new BehaviorSubject<string>('');
    public successFlagNotificationObs = this.successFlagNotificationBs.asObservable();

    public currentTimeBs = new BehaviorSubject<string>('');
    public currentTimeObs = this.currentTimeBs.asObservable();

    updateUserName(userName) {
        this.userNameBs.next(userName);
    }

    updateTeamName(teamName) {
        this.teamNameBs.next(teamName);
    }

    updateUserImage(image) {
        this.userImageBs.next(image);
    }

    updatenotification(notification) {
        this.notificationBs.next(notification);
    }

    successMessage(notification) {
        this.successNotificationBs.next(notification);
    }
    failureMessage(notification) {
        this.failureNotificationBs.next(notification);
    }
    successFlagMessage(notification) {
        this.successFlagNotificationBs.next(notification);
    }

    updateCurrentTime(time){
        this.currentTimeBs.next(time);
        }

}