import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { GenericAPIService } from '../../../services/generic-api.service';
import { UrlConstants } from '../../../constants/url-constants';
import { IMyDpOptions } from 'mydatepicker';
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localStorage.service';

@Component({
  selector: 'app-subscription-detail',
  templateUrl: './subscription-detail.component.html',
  styleUrls: ['./subscription-detail.component.scss']
})
export class SubscriptionDetailComponent implements OnInit {
  subscriptionForm: FormGroup;
  getSubcriptionURL = UrlConstants.getSubcriptionDetails;
  saveSubcriptionURL = UrlConstants.saveSubscriptionDetails;
  myDatePickerOptions: IMyDpOptions;
  dueDate: any;
  subsDetailId: any;
  constructor(private fb: FormBuilder, private genericApiService: GenericAPIService,
    private encryptDecryptLocalStorageService: EncryptDecryptLocalStorageService) {
    var date = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
    this.myDatePickerOptions = {
      // other options...
      dateFormat: 'dd-mm-yyyy',
      // disableSince: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() + 1 },
      // disableUntil: { year: date.getFullYear() - 100, month: date.getMonth() + 1, day: date.getDate() + 1 },
      showClearDateBtn: false,
      editableDateField: false,
      openSelectorOnInputClick: true
    };
  }

  ngOnInit() {
    this.creatSubcriptionForm();
    this.getSubcriptionDetails();
  }


  creatSubcriptionForm() {
    this.subscriptionForm = this.fb.group({
      subscriptionDetailId: [''],
      membershipFee: ['',[Validators.required]],
      paymentDueDate: ['',[Validators.required]],
      penalityFee: ['',[Validators.required]],
      durationForPenality: [0],
    })
  }

  getSubcriptionDetails() {
    this.genericApiService.getAll(this.getSubcriptionURL).subscribe(response => {
      console.log(response);
      if (response.status.success == 'Success') {
        const date = response.data.paymentDueDate;
        this.subsDetailId = response.data.subscriptionDetailId;
        console.log(date.split('-')[0] + '-' +
          date.split('-')[1] + '-' +
          date.split('-')[2].split('T')[0])

        var _day = date.split('-')[2].split('T')[0];
        if (_day.charAt(0) == 0) {
          _day = _day.charAt(1);
        }

        var _month = date.split('-')[1];
        if (_month.charAt(0) == 0) {
          _month = _month.charAt(1);
        }

        const selectedDate = { date: { day: _day, month: _month, year: date.split('-')[0] } }

        this.subscriptionForm.patchValue({
          subscriptionDetailId: response.data.subscriptionDetailId,
          membershipFee: response.data.membershipFee,
          paymentDueDate: selectedDate,
          penalityFee: response.data.penalityFee,
          durationForPenality: response.data.durationForPenality
        })
      } else {
        this.subsDetailId = undefined;
      }
    })
  }

  submitForm() {
    if(this.subscriptionForm.valid){
      const date: any = this.subscriptionForm.controls.paymentDueDate.value;
      const selectedDate = this.getFormattedDate(date);
      this.subscriptionForm.controls.paymentDueDate.setValue(selectedDate);
      const payLoad = this.subscriptionForm.value;
      console.log(payLoad);
      this.genericApiService.post(this.saveSubcriptionURL, payLoad, true).subscribe(response => {
        if (response.status.success == 'Success') {
          this.getSubcriptionDetails();
        }
      })
    }
  }

  getFormattedDate(value) {

    const month = value.date.month.toLocaleString().length == 1 ? ('0' + value.date.month) : value.date.month;
    const year = value.date.year;
    const day = value.date.day.toLocaleString().length == 1 ? ('0' + value.date.day) : value.date.day;
    const formatDate = year + '-' + month + '-' + day;

    return formatDate;
    // this.datePipe.transform(formatDate, 'yyyy-mm-dd')

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  get membershipFee():AbstractControl {
    return this.subscriptionForm.get('membershipFee');
  }

  get paymentDueDate():AbstractControl {
    return this.subscriptionForm.get('paymentDueDate');
  }

  get penalityFee():AbstractControl {
    return this.subscriptionForm.get('penalityFee');
  }

  get durationForPenality():AbstractControl {
    return this.subscriptionForm.get('durationForPenality');
  }

}
