<div class="row h-50 m-0">
  <div class="col-xl-6 pb-2">
    <app-driver></app-driver>
  </div>
  <div class="col-xl-6 pb-2">
    <app-constructor></app-constructor>
  </div>
</div>
<div class="row h-50 m-0 pt-2">
  <div class="col-xl-12">
    <app-circuit></app-circuit>
  </div>
</div>