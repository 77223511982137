<section class="login-container h-100">
  <div class="row justify-content-start align-items-center h-100">
    <!-- Login Card -->
    <div class="card login-card">
      <h4 class="mb-5 pb-2 card-title">ULTRA F1 FANTASY LEAGUE</h4>
      <form [formGroup]="loginForm">
        <div class="form-group">
          <input type="email" class="form-control" id="Email" formControlName="email" placeholder="Email Address"
            required>
          <small *ngIf="loginForm.controls['email'].errors && !loginForm.controls['email'].pristine" class="form-text text-muted"> Enter a valid email id</small>
        </div>
        <div class="form-group mb-2">
          <input [type]="show==true ? 'text' : 'password'"  class="form-control" id="Password" formControlName="password" placeholder="Password"
            required>
            <div class="form-control-icons">
                <i *ngIf="show"class="fa fa-eye" aria-hidden="true"(click)="show=!show"></i>
                <i *ngIf="!show" class="fa fa-eye-slash" aria-hidden="true"(click)="show=!show"></i>

              </div>
          <small *ngIf="loginForm.controls['password'].errors && !loginForm.controls['password'].pristine" class="form-text text-muted">Enter
            password</small>
        </div>
        <div class="form-group form-check pl-0">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="rememberMe" name="rememberMe" [checked]="rememberMe"
              (change)="rememberMeCheck($event)">
            <label class="custom-control-label" for="rememberMe">Remember Me</label>
          </div>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-login mb-2" [disabled]="loginForm.invalid" (click)="signin()">sign
            in</button>
          <small *ngIf="failSignIn" class="form-text text-muted">{{failSignIn}}</small>
        </div>
      </form>
      <div class="form-group forgot-pwd mb-0">
        <a routerLink="/forgetPassword">Forgot Password?</a>
      </div>
      <div class="box-loader" *ngIf="PreLoader">
        <img class="text-center image-responsive-height demo-mw-50" src="assets/img/progress.svg" alt="Progress">
      </div>
    </div>
  </div>
</section>