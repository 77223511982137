import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signin-with-twitter',
  templateUrl: './signin-with-twitter.component.html',
  styleUrls: ['./signin-with-twitter.component.scss']
})
export class SigninWithTwitterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
