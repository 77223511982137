import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GenericAPIService } from '../../services/generic-api.service';
import { UrlConstants } from '../../constants/url-constants';
import { commonService } from 'src/app/services/commonMethodService';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localStorage.service';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  public loginForm;
  public loginUrl = UrlConstants.loginUrl;
  public failSignIn = ""
  PreLoader = false;
  rememberMe = false;
  show:boolean=false;
  constructor(public router: Router,
    public _genericService: GenericAPIService,
    public commonService: commonService,
    public encryptDecryptService: EncryptDecryptService, public encryptDecryptLocalStorageService: EncryptDecryptLocalStorageService) {
    sessionStorage.setItem("token", "");

    this.createForm();
    if (this.encryptDecryptLocalStorageService.getLocalStorage('rememberMe') != "") {
      if (this.encryptDecryptLocalStorageService.getLocalStorage('rememberMe') == "true") {
        this.rememberMe = true;
        this.loginForm.controls['email'].setValue(this.encryptDecryptLocalStorageService.getLocalStorage('email'));
        this.loginForm.controls['password'].setValue(this.encryptDecryptLocalStorageService.getLocalStorage('password'));
      }
    }

  }
  ngOnInit() {
  }
  createForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', [Validators.required])
    })
  }
  signin() {
    this.PreLoader = true
    var encryptedPassword = this.commonService.reverseString(this.loginForm.value.password);
    let payloadReqObj = {
      "email": this.loginForm.value.email,
      "password": encryptedPassword
    }
    // sessionStorage.setItem("isadmin","true");

    this._genericService.post(this.loginUrl, payloadReqObj).subscribe(response => {
      if (response.status.success == "Success") {     
        //For Remember Me strats....
        var rememberMe = "false";
        if (this.rememberMe == true) {
          rememberMe = "true";
          this.encryptDecryptLocalStorageService.setToLocalStorage("email", this.loginForm.value.email);
          this.encryptDecryptLocalStorageService.setToLocalStorage("password", this.loginForm.value.password);
        }
        this.encryptDecryptLocalStorageService.setToLocalStorage("rememberMe", rememberMe);
        //For Remember Me end..............

        var str = response.data.raceDate;
        var raceDate;
        if(str!=null ||str!=undefined){
          var res = str.substring(0, str.indexOf(' '));
          raceDate=res+" 23:59:59"  
        }
       else{
         //when no racedate
         raceDate="no race";
       }
        //for today date
        var str2 = response.data.todayDate;
        var todaydate;
        if(response.data.raceIsAm){
          todaydate=str2+" 23:59:59";
        } else {
          todaydate=str2+" 11:59:59";
        }
        if(response.data.upcomingRaceRound!==null){
          this.encryptDecryptLocalStorageService.setToLocalStorage("upcomingRaceRound",response.data.upcomingRaceRound);
        }
        if(response.data.todayDate!=null){
          this.encryptDecryptLocalStorageService.setToLocalStorage("todayDate",todaydate);
        }
        if(response.data.previousRaceId!=null){
          this.encryptDecryptService.setToLocalStorage("previousRaceId", response.data.previousRaceId);
        }
        if(response.data.userId!=null){
          this.encryptDecryptService.setToLocalStorage("userId", response.data.userId);
        }
        if(response.data.email!=null){
          this.encryptDecryptService.setToLocalStorage("email", response.data.email);
        }
        if(response.data.userName!=null){
          this.encryptDecryptService.setToLocalStorage("userName", response.data.userName);
        }
        if(response.data.userImage!=null){
          this.encryptDecryptService.setToLocalStorage("userImage", response.data.userImage);
        }
        if(response.data.teamName!=null){
          this.encryptDecryptService.setToLocalStorage("teamName", response.data.teamName);
        }
        if(response.data.raceBdStartingDateTime!=null){
          this.encryptDecryptService.setToLocalStorage("raceBdStartingDateTime", response.data.raceBdStartingDateTime);
        }
        if(response.data.raceBdClosingDateTime!=null){
          this.encryptDecryptService.setToLocalStorage("raceBdClosingDateTime", response.data.raceBdClosingDateTime);
        }
        if(response.data.upcomingRaceId!=null){
          this.encryptDecryptService.setToLocalStorage("upcomingRaceId", response.data.upcomingRaceId);
        }
        if(raceDate!=null){
          this.encryptDecryptService.setToLocalStorage("raceDate", raceDate);
        }
        if(response.data.isPrincipal!=null){
          this.encryptDecryptService.setToLocalStorage("isPrincipal", response.data.isPrincipal);

        }
        else{
          this.encryptDecryptService.setToLocalStorage("isPrincipal", false);
        }
        sessionStorage.setItem("token", response.token);
        this.PreLoader = false;

        this.router.navigate(['user/dashboard']);
      }
      else {
        this.PreLoader = false
        sessionStorage.setItem("token", "");
        this.failSignIn = response.status.message;
      }
    },
      errorHandler => {
        this.PreLoader = false
        this.failSignIn = "Incorrect Username or Password"
        //  this.failSignIn = errorHandler.error.status.message;
      });

  }
  rememberMeCheck(event) {

    this.rememberMe = !this.rememberMe;
  }
}
